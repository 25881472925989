import React from "react";
import styled from "styled-components";
import { HR, AppText } from ".";

const Or = () => (
  <Div>
    <HR backgroundColor="#dfe0eb" width="179px" />
    <AppText>OR</AppText>
    <HR backgroundColor="#dfe0eb" width="179px" />
  </Div>
);

const Div = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export { Or };
