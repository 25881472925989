import * as React from "react";
import { connect } from "react-redux";
import { AppState } from "../../store/configureStore";
import { Team, Member } from "../../types";
import { Dispatch, bindActionCreators } from "redux";
import { AppActions } from "../../types/actions";
import { addTeam } from "../../actions/team";
import { Button, AppText, Card, Spinner } from "../UIElements";
import styled from "styled-components";
import { getTeam } from "../../selectors/getTeam";
import { Link } from "react-router-dom";
import { timeout } from "q";
import { fetchTeammatesThunk, editTeamThunk } from "../../api/teams";

interface GroupCardProps {
  team: Team;
  name: any;
  team_id: any;
}
interface GroupCardState {}

type Props = GroupCardProps & LinkStateProps & LinkDispatchProps;

class GroupCard extends React.Component<Props, GroupCardState> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }
  componentDidMount = () => {
    this.props.fetchAllTeamMembers(this.props.team.id);
  };
  render() {
    //TODO TEMPORY MEMBERS ARRAY
    const members = [];
    const { team, team_id } = this.props;
    console.log("TEAM ID", this.props.team.id);
    console.log("GROUP CARD PROPS", this.props);
    // console.log(
    //   "TEAMMATEs",
    //   this.props.teammates[`${this.props.team.id}`]["0"]
    // );
    // const teammates = this.props.teammates[`${this.props.team.id}`]["0"]
    //   .teammates;
    return this.props.teammates &&
      this.props.teammates[`${this.props.team_id}`] ? (
      // this.props.teammates[`${this.props.team.id}`] &&
      // this.props.teammates[`${this.props.team.id}`]["0"]
      <Div>
        <Header>
          <Vertical>
            <GroupTitle>{this.props.name}</GroupTitle>
            <GroupSubTitle>{`${
              this.props.teammates[`${this.props.team.id}`]["0"].teammates
                .length
            } members`}</GroupSubTitle>
          </Vertical>
          <Link to={`/group/${team.id}`}>
            <ManageButton>Manage</ManageButton>
          </Link>
        </Header>
        <Body>
          {this.props.teammates[`${this.props.team.id}`]["0"].teammates.map(
            (member: any) => (
              <MemberItem key={member.id}>
                <MemberText>{`${member.first_name} ${
                  member.last_name
                }`}</MemberText>
              </MemberItem>
            )
          )}
        </Body>
      </Div>
    ) : (
      <Spinner />
    );
  }
}

const Body = styled.div`
  background-color: #f4f5f5;
  /* display: flex;
  flex-direction: row;
  flex-wrap: wrap; */
  display: grid;
  grid-template-columns: 260px auto;
  padding: 16px 17px 16px 32px;
  height: 200px;
`;

const MemberItem = styled.div`
  padding: 8px 0 8px 0;
`;
const MemberText = styled(AppText)`
  font-size: 16px;
  color: #1f2125;
`;

const ManageButton = styled(Button)`
  width: 92px;
  height: 30px;
  border-radius: 1px;
  border: solid 1px rgba(143, 144, 146, 0.2);
  background-color: #f4f5f5;
  color: #09397f;
`;

const GroupTitle = styled(AppText)`
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.2px;
  color: #1f2125;
`;

const GroupSubTitle = styled(AppText)`
  font-size: 16px;
  letter-spacing: 0.2px;
  color: #8f9092;
  font-weight: normal;
`;

const Div = styled(Card)`
  display: flex;
  flex-direction: column;
  width: 526px;
  height: min-content;
  padding: 0;
`;

const Header = styled.div`
  height: 90px;
  padding: 0 17px 0 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Vertical = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

interface LinkDispatchProps {
  fetchTeammates: (team_id: number) => void;
  // addSwimmer: (swimmer_id: number, team_id: number) => void;
  // removeSwimmer: (swimmer_id: number, team_id: number) => void;
  // addCoach: (coach_id: number, team_id: number) => void;
  // removeCoach: (coach_id: number, team_id: number) => void;
  editTeamName: (team_id: number, team_name: string) => void;
  fetchAllTeamMembers: (team_id: any) => void;
}
const mapDispatchToProps = (
  dispatch: Dispatch<AppActions>
): LinkDispatchProps => ({
  fetchTeammates: bindActionCreators(fetchTeammatesThunk, dispatch),
  // addSwimmer: bindActionCreators(addSwimmerTeamThunk, dispatch),
  // removeSwimmer: bindActionCreators(removeSwimmerTeamThunk, dispatch),
  // addCoach: bindActionCreators(addCoachTeamThunk, dispatch),
  // removeCoach: bindActionCreators(removeCoachTeamThunk, dispatch),
  editTeamName: bindActionCreators(editTeamThunk, dispatch),
  fetchAllTeamMembers: bindActionCreators(fetchTeammatesThunk, dispatch)
});
interface LinkStateProps {
  // members: Member[];
  teams: any;
  teamMember: any;
  teammates: any;
}

// isAuthenticated is true if user_token exists in redux
const mapStateToProps = (
  state: AppState,
  ownProps: GroupCardProps
): LinkStateProps => ({
  // Get swimmers on team
  // members: getTeam(ownProps.team.id, state.teamMembers, state.members)
  teams: state.teams,
  teamMember: state.teamMembers,
  teammates: state.teamMates
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GroupCard);
