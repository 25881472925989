import * as React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { AppText, Card, AppTitle, Collapsible, Icon } from ".";
import { toggleUp, toggleDown } from "../../img";
// import { AppText, Card, AppTitle, Collapsible } from "../UIElements";
import { zones } from "../../static/zones";
import { timeout } from "q";
import { zoneColor } from "../../helperfunctions/zoneColor";
import { zoneCheck } from "../../helperfunctions/zoneCheck";

interface CollapsibleProps {
  title?: string;
  DPS: any;
  avg_hr: any;
  name: any;
  time: any;
  Zone: any;
  superlaps: any;
}
interface CollapsibleState {
  isOpen: boolean;
}

class WorkoutCollapsible extends React.Component<
  CollapsibleProps,
  CollapsibleState
> {
  constructor(props: CollapsibleProps) {
    super(props);
    this.state = {
      isOpen: false
    };
  }
  toggleCollapsible = () => this.setState(prev => ({ isOpen: !prev.isOpen }));
  render() {
    const { isOpen } = this.state;
    const { DPS, avg_hr, name, time, Zone, superlaps } = this.props;
    return (
      <Div>
        <TitleDiv onClick={this.toggleCollapsible}>
          <SetNameText>{name}</SetNameText>
          <SetTitleText style={{ textAlign: "right" }}>{time}</SetTitleText>
          <SetTitleText style={{ textAlign: "right" }}>{DPS}</SetTitleText>
          <SetZoneText style={{ textAlign: "center" }} color={zoneColor(Zone)}>
            {zoneCheck(Zone)}
          </SetZoneText>
          <SetTitleText style={{ textAlign: "center" }}>{avg_hr}</SetTitleText>
          {/* <Icon
            clickable
            src={isOpen ? toggleUp : toggleDown}
            height={24}
            width={24}
            // style={{ float: "right" }}
          /> */}
        </TitleDiv>
        {isOpen && <ChildDiv>{this.props.children}</ChildDiv>}
      </Div>
    );
  }
}

const Div = styled.div`
  transition: 0.25s;
  transition-timing-function: cubic-bezier(0.17, 0.67, 0.34, 0.86);
`;

const TitleDiv = styled.div`
  display: grid;
  grid-template-columns: 320px 40px 80px 200px auto;
  height: 60px;
  background-color: #fff;
  padding-left: 30px;
  align-items: center;
  align-items: center;
  :hover {
    cursor: pointer;
    background-color: #cccccc;
  }
  border-bottom: 1px solid #cccccc;
`;

const CollapsibleTitle = styled(AppText)`
  font-weight: 500;
  letter-spacing: 0.2px;
  color: #8f9092;
`;

const ChildDiv = styled.div`
  background-color: #fbfbfb;
  transition: height 0.25s linear;
`;

const SetTitleGrid = styled.div`
  display: grid;
  grid-template-columns: 320px 40px 80px 200px auto;
  height: 32px;
  background-color: #f4f5f5;
  padding-left: 30px;
  align-items: center;
`;
const SetItemGrid = styled(SetTitleGrid)`
  height: 60px;
  background-color: #fff;
`;

interface SetZoneTextProps {
  color?: string;
}

const SetZoneText = styled(AppText)<SetZoneTextProps>`
  opacity: 0.5;
  font-size: 13px;
  color: ${props => props.color};
  font-weight: 600;
`;

SetZoneText.defaultProps = {
  color: "#1f2125"
};

const SetNameText = styled(AppText)`
  font-size: 14px;
  letter-spacing: 0.2px;
  color: #1f2125;
`;

const SetTitleText = styled(AppText)`
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 1.2px;
  color: #8f9092;
`;
export { WorkoutCollapsible };
