import * as React from "react";
import { connect } from "react-redux";
import {
  AuthContainer,
  ErrorText,
  Input,
  Button,
  AppText,
  FormSegment,
  Or,
  Center,
  Spinner,
  AppTitle
} from "../UIElements";
import {
  Formik,
  FormikProps,
  Form,
  Field,
  FieldProps,
  ErrorMessage
} from "formik";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { setError } from "../../firebase/error";
import { login } from "../../actions/auth";
import styled from "styled-components";
import { User, Auth } from "../../types";
import { fetchUser } from "../../actions/user";
import { Dispatch, bindActionCreators } from "redux";
import { AppActions } from "../../types/actions";
import AuthError from "../Segments/AuthError";
import { LinkText, LightText, AuthTitle } from "../UIElements/Text";
import { loginThunk, getMeThunk } from "../../api/auth";
import { AppState } from "../../store/configureStore";

const signupSchema = Yup.object().shape({
  email: Yup.string()
    .email("* Invalid email")
    .required("* Email is required"),
  password: Yup.string()
    .required("* Please specify a password")
    .max(50, "* Too Long!")
});

interface SignInProps {}

interface SignInState {}

type Props = SignInProps & LinkDispatchProps & LinkStateProps;

interface MyFormikProps {
  email: string;
  password: string;
}

class SignIn extends React.Component<Props, SignInState> {
  render(): JSX.Element {
    console.log("SIGN IN PROPS", this.props);
    return (
      <AuthContainer>
        <Formik
          initialValues={{
            email: "",
            password: ""
          }}
          validationSchema={signupSchema}
          onSubmit={async (values, { setFieldError, setStatus }) => {
            const { email, password } = values;
            setStatus({ error: "", loading: true });
            try {
              this.props.loginUser(email, password);
              console.log(this.props);
            } catch (e) {
              console.log("login error: ", e);
              setStatus({ loading: false });
              return setError(e.code, setFieldError, setStatus);
            }
            console.log("no error");
            // If login was successful, send a request to the server to get
            // user token. Then dispatch user reducer and auth reducer
            // const token = auth!.currentUser!.uid;
            // const loginData = await loginAPI(token, email);

            // console.log("loginData: ", loginData);
            // if (loginData.error) {
            //   return setStatus({ error: loginData.error, loading: false });
            // }

            // this.props.fetchUser({
            //   ...loginData,
            //   user_token: loginData.user_token
            // });
            // setStatus({ loading: false });
            // this.props.login(loginData.user_token);
            // TODO: Replace this with the real token once the backend is hooked up.
            // this.props.login("supertoken");
          }}
        >
          {({ values, status, setStatus }: FormikProps<MyFormikProps>) => {
            return (
              <Form>
                <Grid>
                  {this.props.auth.error && (
                    <AuthError status={status} error={this.props.auth.error} />
                  )}
                  <AuthTitle style={{ gridRow: "1 / span 1" }}>
                    Phlex Coaching Beta
                  </AuthTitle>
                  <Field
                    name="email"
                    render={({
                      field,
                      form: { errors, touched }
                    }: FieldProps<MyFormikProps>) => {
                      const error = !!errors.email && !!touched.email;
                      return (
                        <FormSegment>
                          <Input
                            {...field}
                            error={error}
                            placeholder="Enter your email"
                          />
                          <ErrorMessage
                            name={field.name}
                            component={ErrorText}
                          />
                        </FormSegment>
                      );
                    }}
                  />
                  <Field
                    name="password"
                    render={({
                      field,
                      form: { errors, touched }
                    }: FieldProps<MyFormikProps>) => {
                      const error = !!errors.password && !!touched.password;
                      field.name;
                      return (
                        <FormSegment>
                          <Input
                            {...field}
                            error={error}
                            type="password"
                            placeholder="Enter your password"
                          />
                          <ErrorMessage
                            name={field.name}
                            component={ErrorText}
                          />
                        </FormSegment>
                      );
                    }}
                  />
                  <div>
                    {/* {this.props.auth.error && (
                      <AuthError
                        status={status}
                        error={this.props.auth.error}
                      />
                    )} */}
                    <Button type="submit">Log In</Button>
                    {/* {this.props.auth && status.loading ? (
                      <Spinner />
                    ) : (
                      <Button type="submit">Log In</Button>
                    )} */}
                  </div>
                  <div style={{}}>
                    {/* <AuthError status={status} error={this.props.auth.error} /> */}
                  </div>
                  <Center style={{ gridRow: "7 / span 1" }}>
                    <LightText>{`Don't have an account?`}&nbsp;</LightText>
                    <Link to="/signup" style={{ textDecoration: "none" }}>
                      <LinkText>Sign up now</LinkText>
                    </Link>
                  </Center>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </AuthContainer>
    );
  }
}

const Grid = styled.div`
  display: grid;
  grid-template-rows: 100px 70px 70px 50px auto 36px min-content;
  justify-content: center;
  justify-items: center;
`;

interface LinkDispatchProps {
  login: (token: string) => void;
  fetchUser: (user: User) => void;
  loginUser: (email: string, password: string) => void;
  getUserInfo: () => void;
}

const mapDispatchToProps = (
  dispatch: Dispatch<AppActions>
): LinkDispatchProps => ({
  login: bindActionCreators(login, dispatch),
  fetchUser: bindActionCreators(fetchUser, dispatch),
  loginUser: bindActionCreators(loginThunk, dispatch),
  getUserInfo: bindActionCreators(getMeThunk, dispatch)
});

//temp state to show
interface LinkStateProps {
  auth: Auth;
}

// TODO: Replace stub data with data from a redux store
// TODO: Switch isAuthorized back to real check
const mapStateToProps = (state: AppState): LinkStateProps => ({
  // isAuthorized: state.auth.role === "Head Coach"
  auth: state.auth
});
export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
