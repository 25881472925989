import {
  FETCH_ORGANIZATIONS,
  ADD_ORGANIZATION,
  EDIT_ORGANIZATION,
  FETCH_ORGANIZATION_USER
} from "../types/actions";
import { Organization } from "../types";

export const fetchOrganizations = (organizations: Organization[]) => ({
  type: FETCH_ORGANIZATIONS,
  organizations
});

export const addOrganization = (organization: Organization) => ({
  type: ADD_ORGANIZATION,
  organization
});

export const editOrganization = (organization: Organization) => ({
  type: EDIT_ORGANIZATION,
  organization
});

export const fetchOrganizationUser = (organization: Organization) => ({
  type: FETCH_ORGANIZATION_USER,
  organization
});
