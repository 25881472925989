import { create } from "apisauce";
import { ThunkAction } from "redux-thunk";
import { AppState } from "../store/configureStore";
import { ActionCreator } from "redux";
import { TeamActionTypes } from "../types/actions";
import { fetchTeams, editTeam, addTeam, removeTeam } from "../actions/team";
import { fetchTeammates } from "../actions/teamMates";
import { Team } from "../types";

import { api } from "../api/api";

//Thunk creators

//get all teams in logged in User's Organization
export const fetchTeamsThunk = () => async (dispatch, getState) => {
  const token = getState().auth.token;
  const organization_id = getState().auth.user.organization_id;
  try {
    console.log("ORG ID", organization_id);
    const res = await api.get(
      `/team/get-by-organization?organization_id=${organization_id}`,
      {},
      { headers: { Authorization: `Bearer ${token}` } }
    );
    const action = fetchTeams(res.data as Team[]);
    dispatch(action);
  } catch (err) {
    console.log(err);
  }
};

export const fetchTeammatesThunk = team_id => async (dispatch, getState) => {
  const token = getState().auth.token;
  try {
    const res = await api.get(
      `/team/get-teammates?team_id=${team_id}`,
      {},
      { headers: { Authorization: `Bearer ${token}` } }
    );
    const action = fetchTeammates(res.data as any);
    dispatch(action);
  } catch (err) {
    console.log(err);
  }
};

//update a team's name

export const editTeamThunk = (name, id) => async (dispatch, getState) => {
  const token = getState().auth.token;
  try {
    const res = await api.put<Team>(
      `/team/${id}?name=${name}`,
      {},
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    );
    const action = editTeam(res.data as any);
    console.log("EDIT TEAM THUNK HIT", res.data);
    dispatch(action);
  } catch (err) {
    console.log(err);
  }
};

//add team

export const addTeamThunk = name => async (dispatch, getState) => {
  const token = getState().auth.token;
  const organization_id = getState().auth.user.organization_id;

  try {
    const formData = new FormData();
    formData.append("name", name);
    formData.append("organization_id", organization_id);
    const res = await api.post("/team", formData, {
      headers: { Authorization: `Bearer ${token}` }
    });
    console.log("ADDTEAM THUINK HIT", res.data);
    const action = addTeam(res.data as Team);
    dispatch(action);
  } catch (err) {
    console.log(err);
  }
};

//remove team
export const removeTeamThunk = team_id => async (dispatch, getState) => {
  const token = getState().auth.token;
  try {
    const res = await api.delete(
      `/team/${team_id}`,
      {},
      { headers: { Authorization: `Bearer ${token}` } }
    );
    const action = removeTeam(res.data as any);
    dispatch(action);
  } catch (err) {
    console.log(err);
  }
};
