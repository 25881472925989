import { UserActionTypes } from "./../types/actions";
import { User } from "./../types/index";

const userReducerDefaultState: User = {
  user_id: null,
  user_uuid: "",
  user_email: "guest@test.com",
  user_first_name: "guest",
  user_last_name: "",
  user_password: "",
  user_is_active: 1,
  user_last_active_epoch: 1551653943,
  user_token: null,
  user_unread_notifications: 0,
  user_is_new: 0,
  user_type: "Head Coach",
  user_created_epoch: 1551653911740,
  user_updated_epoch: 1551653943,
  settings: {
    setting_id: null,
    user_id: null,
    notification_is_enabled: 0
  },
  organization_name: "",
  first_name: "",
  last_name: "",
  email: ""
};

// This reducer holds user information that is returned from login/signup
// post requests

const userReducer = (
  state = userReducerDefaultState,
  action: UserActionTypes
): User => {
  switch (action.type) {
    case "FETCH_USER":
      return { ...action.user };
    case "EDIT_USER":
      return {
        ...state,
        first_name: action.user.first_name,
        last_name: action.user.last_name,
        email: action.user.email
      };
    case "LOGOUT":
      return userReducerDefaultState;
    default:
      return state;
  }
};

export default userReducer;
