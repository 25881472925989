import * as React from "react";
import styled from "styled-components";
import { FaTrashAlt } from "react-icons/fa";

export interface TrashIconProps {
  onClick?: any;
}
const TrashIcon: React.SFC<TrashIconProps> = () => {
  return <FaTrashAlt />;
};

export default TrashIcon;
