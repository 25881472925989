import * as React from "react";
import { connect } from "react-redux";
import { Container, AppText, Spinner } from "../UIElements";
import styled from "styled-components";
import * as Sentry from "@sentry/browser";
import { AppState } from "../../store/configureStore";
import DateSelector from "../Segments/Dashboard/DateSelector";
import SwimmerSelector from "../Segments/Dashboard/SwimmerSelector";
import "react-day-picker/lib/style.css";
import MiniDashboardCard from "../Segments/cards/MiniDashboardCard";
import { swimIcon, heartIcon } from "../../img";
import { DoughnutGraph } from "../Segments/Graphs/Doughnut";
import {
  dummyDistance,
  dummyHRZone,
  colorDistance,
  colorHRZone
} from "../../static/doughnutData";
import { AreaGraph } from "../Segments/Graphs/Area";
import { dummyHRZoneLine, dummyAvgHR } from "../../static/lineData";
import { workout } from "../../static/workout";
import { WorkoutSets } from "../Segments/Dashboard/WorkoutSets";
import { Dispatch, bindActionCreators } from "redux";
import { AppActions } from "../../types/actions";
import { setSwimmerIdFilter } from "../../actions/filter";
import { fetchWorkouts } from "../../actions/workout";
import { Workout } from "../../types";
import WorkoutSelector from "../Segments/Dashboard/WorkoutSelector";
import { getMeThunk } from "../../api/auth";
import { fetchCoachesThunk, inviteCoachThunk } from "../../api/coach";
import { create } from "apisauce";
import { months } from "moment";
import { secondsToMin } from "../../helperfunctions/secondstominutes";
import { type } from "os";
import { averageHRData } from "../../helperfunctions/averageHRData";
import momentTimezone from "moment-timezone";

interface DashboardProps {}

interface DashboardState {
  workout_info: object;
  external_swimmer_id: number | null;
  current_workout: any;
  month: number | null;
  year: number | null;
  epoch: number | null;
  workout_id: number | null;
  workoutDatesAPI: any;
  workouts_at_selection: any;
  workout_hr_data: any;
}

const api = create({
  baseURL: "https://ap1.phlex.us/scripts",
  headers: {
    // "Access-Control-Allow-Origin": "*",
    // Authorization: "Bearer {token}",
    "Content-Type": "application/x-www-form-urlencoded",
    Accept: "application/json"
  }
});

type Props = DashboardProps & LinkStateProps & LinkDispatchProps;
class Dashboard extends React.Component<Props, DashboardState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      workout_info: {},
      external_swimmer_id: this.props.swimmerData.external_swimmer_id,
      workout_id: null,
      current_workout: {},
      workouts_at_selection: {},
      month: new Date().getMonth() + 1,
      year: new Date().getFullYear(),
      epoch: null,
      workoutDatesAPI: [], //DATES not rendering properly on calender
      workout_hr_data: {}
    };
  }
  componentDidMount = async () => {
    // TODO: Replace placeholder id with real user id
    // const { id } = this.props;
    const id = 1;
    Sentry.configureScope(scope => {
      scope.setUser({
        id: `${id}`
      });
    });
    this.props.fetchWorkouts(workout);
    this.props.getUserInfo();
    // this.props.fetchCoaches(this.props.user.organization_id);
  };

  shouldComponentUpdate(nextProps, nextState) {
    // if (this.props.swimmerData.external_swimmer_id !== null) {
    //   this.setState({
    //     external_swimmer_id: nextProps.swimmerData.external_swimmer_id
    //   });
    // }
    if (
      this.props.swimmerData.external_swimmer_id !==
      nextProps.swimmerData.external_swimmer_id
    ) {
      this.setState({
        external_swimmer_id: nextProps.swimmerData.external_swimmer_id
      });
    }
    return true;
  }

  componentDidUpdate = async (prevProps, prevState) => {
    const currentTime = momentTimezone.tz.guess();

    //fires fetch last workout on swimmer change, fetch all workouts as well.
    if (prevState.external_swimmer_id !== this.state.external_swimmer_id) {
      const formData = new FormData();
      formData.append(
        "requesting_user_id",
        `${this.state.external_swimmer_id}`
      );
      formData.append("user_token", "super_token");
      const res = await api.post(`/fetch-last-workout.php`, formData);
      console.log("LATEST WORKOUT API DATA", res.data);
      this.setState({
        current_workout: res.data
      });
    }
    if (prevState.month !== this.state.month) {
      const formData = new FormData();
      formData.append(
        "requesting_user_id",
        `${this.state.external_swimmer_id}`
      );
      formData.append("month", `${this.state.month}`);
      formData.append("year", `${this.state.year}`);
      formData.append("user_token", "super_token");
      formData.append("timezone", currentTime);
      const res = await api.post("/fetch-workout-dates.php", formData);
      console.log("WORKOUT DATE API DATA", res.data);
    }
  };

  //React info being passed up
  getDate = (month, year, epoch) => {
    this.setState({
      month: month,
      year: year,
      epoch: epoch
    });
  };

  getWorkoutDates = data => {
    this.setState({
      workoutDatesAPI: data
    });
  };

  fetchWorkoutOnDateClick = data => {
    this.setState({
      workouts_at_selection: data
    });
  };

  fetchCurrentWorkout = data => {
    this.setState({
      current_workout: data
    });
  };

  getHRData = data => {
    this.setState({
      workout_hr_data: data
    });
  };
  getInvidivialState = () => {};
  // static getDerivedStateFromProps(props) {
  //   if (props.user.organization_id) {
  //     props.fetchCoaches(props.user.organization_id);
  //   }
  // }
  //data for distance and type donut
  distanceArray = () => {
    let collection = this.state.current_workout.styles;
    return collection.map(obj => {
      return { x: obj.style_name, y: obj.style_distance };
    });
  };
  // data for HR zone
  hrZoneDataArray = () => {
    const allHRSets = this.state.workout_hr_data.sets.map(obj => obj.hr_data);
    const collection = ([] as any).concat.apply([], allHRSets);
    return collection;
    // .map(obj => ({
    //   y: obj.hr_bpm,
    //   x: obj.hr_time / 60
    //   // label: obj.hr_bpm
    // }));
  };

  // data for average HR zone
  avgHRZoneDataArray = () => {
    const allHRSets = this.state.workout_hr_data.sets.map(obj => obj.hr_data);
    const collection = [].concat.apply([], allHRSets);
    console.log("avg collection ----->", collection);
    return collection;
  };
  //data for donut zone types
  hrZoneArray = () => {
    const anaerobicTime = {
      type: "Aerobic",
      sec: this.state.current_workout.workout_hr_anaerobic_zone_sec,
      percent: this.state.current_workout.workout_hr_anaerobic_zone_percent
    };
    const fatBurnTime = {
      type: "Fat Burn",
      sec: this.state.current_workout.workout_hr_fat_burn_zone_sec,
      percent: this.state.current_workout.workout_hr_fat_burn_zone_percent
    };
    const maxZoneTime = {
      type: "Max",
      sec: this.state.current_workout.workout_hr_max_zone_sec,
      percent: this.state.current_workout.workout_hr_max_zone_percent
    };
    const recoveryZone = {
      type: "Recovery",
      sec: this.state.current_workout.workout_hr_recovery_zone_sec,
      percent: this.state.current_workout.workout_hr_recovery_zone_percent
    };
    const restingZone = {
      type: "Resting",
      sec: this.state.current_workout.workout_hr_resting_zone_sec,
      percent: this.state.current_workout.workout_hr_resting_zone_percent
    };
    let collection = [
      anaerobicTime,
      fatBurnTime,
      maxZoneTime,
      recoveryZone,
      restingZone
    ];
    return collection.map(obj => {
      return { x: obj.type, y: obj.sec, percent: obj.percent };
    });
  };

  render(): JSX.Element {
    const { user_name } = this.props;
    // console.log("DASH ORG ID", this.props.user.organization_id);
    console.log("DASHBOARD PROPS", this.props);
    console.log("DASHBOARD STATE -------->", this.state);
    // this.state.workout_hr_data.sets
    //   ? console.log("DASHBOARD ZONE DATA STATE")
    //   : console.log("NO DISTANCE STATE");
    if (!this.props.user.organization_id) {
      return <Spinner />;
    }
    return (
      <Grid>
        <LeftPane>
          <DateSelector
            external_swimmer_id={this.state.external_swimmer_id}
            onDateChange={this.getDate}
            onMonthChange={this.getWorkoutDates}
            fetchWorkoutOnDateClick={this.fetchWorkoutOnDateClick}
            dateState={this.state.workoutDatesAPI}
            getDate={this.getDate}
          />
          <WorkoutSelector
            external_id={this.state.external_swimmer_id}
            workout_data={this.state.workouts_at_selection}
            fetchCurrentWorkout={this.fetchCurrentWorkout}
            getHRData={this.getHRData}
          />
          <SwimmerSelector
            currentSwimmerID={this.state.current_workout.workout_user_id}
            month={this.state.month}
            year={this.state.year}
            onSelectSwimmer={this.getInvidivialState}
            onMonthChange={this.getWorkoutDates}
            fetchCurrentWorkout={this.fetchCurrentWorkout}
            getHRData={this.getHRData}
            fetchWorkoutOnDateClick={this.fetchWorkoutOnDateClick}
          />
        </LeftPane>
        <RightPane>
          <div style={{ gridRow: "1 / span 1", gridColumn: "1 / span 1" }}>
            <MiniDashboardCard
              leftDescription="DISTANCE"
              leftValue={`${
                this.state.current_workout.workout_distance
                  ? this.state.current_workout.workout_distance
                  : 0
              } M`}
              rightDescription="TIME"
              rightValue={`${secondsToMin(
                this.state.current_workout.workout_duration_sec
                  ? this.state.current_workout.workout_duration_sec
                  : 0
              )}`}
              icon={swimIcon}
            />
          </div>
          <div style={{ gridRow: "1 / span 1", gridColumn: "2 / span 1" }}>
            <MiniDashboardCard
              leftDescription="AVG. HR"
              leftValue={`${
                this.state.current_workout.workout_avg_heart_rate
                  ? this.state.current_workout.workout_avg_heart_rate
                  : 0
              } BPM`}
              rightDescription="MAX. HR"
              rightValue={`${
                this.state.current_workout.workout_max_heart_rate
                  ? this.state.current_workout.workout_max_heart_rate
                  : 0
              } BPM`}
              icon={heartIcon}
            />
          </div>
          <div style={{ gridRow: "2 / span 1", gridColumn: "1 / span 1" }}>
            {this.state.current_workout.styles ? (
              <DoughnutGraph
                title="Distance"
                data={
                  this.state.current_workout.styles ? this.distanceArray() : []
                }
                colorScale={colorDistance}
              />
            ) : null}
          </div>
          <div style={{ gridRow: "2 / span 1", gridColumn: "2 / span 1" }}>
            {this.state.current_workout.styles ? (
              <DoughnutGraph
                title="HR Zone analysis"
                data={this.state.current_workout ? this.hrZoneArray() : []}
                colorScale={colorHRZone}
                legendType="HRZone"
              />
            ) : null}
          </div>
          <div style={{ gridRow: "3 / span 1", gridColumn: "1 / span 1" }}>
            {this.state.workout_hr_data.sets ? (
              <AreaGraph
                title="HR Zone"
                data={
                  this.state.workout_hr_data.sets
                    ? averageHRData(this.hrZoneDataArray(), 1)
                    : []
                }
              />
            ) : null}
          </div>
          <div style={{ gridRow: "3 / span 1", gridColumn: "2 / span 1" }}>
            {this.state.workout_hr_data.sets ? (
              <AreaGraph
                title="Avg. HR"
                data={
                  this.state.workout_hr_data.sets
                    ? averageHRData(this.avgHRZoneDataArray(), 180)
                    : []
                }
              />
            ) : null}
          </div>
          <div style={{ gridRow: "4 / span 1", gridColumn: "1 / span 2" }}>
            <WorkoutSets data={this.state.current_workout} />
          </div>
        </RightPane>
      </Grid>
    );
  }
}

const Grid = styled.div`
  display: grid;
  grid-template-columns: 415px auto;
`;

const LeftPane = styled.div`
  width: 415px;
  /* padding: 10px 20px 10px 20px; */
  background-color: #fff;
  margin-top: 70px;
`;

const RightPane = styled.div`
  margin: 100px 45px 70px 75px;
  display: grid;
  grid-template-rows: min-content min-content min-content min-content min-content;
  grid-template-columns: min-content min-content;
  grid-row-gap: 24px;
  grid-column-gap: 15px;
`;

interface LinkDispatchProps {
  fetchWorkouts: (workouts: Workout[]) => void;
  getUserInfo: () => void;
  fetchCoaches: (organization_id) => void;
}

const mapDispatchToProps = (
  dispatch: Dispatch<AppActions>
): LinkDispatchProps => ({
  fetchWorkouts: bindActionCreators(fetchWorkouts, dispatch),
  getUserInfo: bindActionCreators(getMeThunk, dispatch),
  fetchCoaches: bindActionCreators(fetchCoachesThunk, dispatch)
});

interface LinkStateProps {
  user_name: string;
  user: any;
  swimmerData: any;
}

const mapStateToProps = (state: AppState): LinkStateProps => ({
  user_name: `${state.user.user_first_name} ${state.user.user_last_name}`,
  user: state.auth.user,
  swimmerData: state.selectedSwimmer
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Dashboard);
