import styled from "styled-components";

export const Container = styled.div`
  padding-left: 149px;
  padding-top: 121px;
  padding-right: 52px;
  padding-bottom: 127px;
  background-color: ${props => props.theme.BACKGROUND_COLOR};
  min-height: inherit;
  min-height: inherit;
`;
