import * as React from "react";
import { ErrorText } from "../UIElements";

export interface AuthErrorProps {
  status: any;
  error?: string;
}

type Props = AuthErrorProps;

class AuthError extends React.Component<Props, any> {
  render() {
    const { status, error } = this.props;
    return (
      <>
        {error && (
          <div style={{ margin: "10px", textAlign: "center" }}>
            <ErrorText>{error}</ErrorText>
          </div>
        )}
        {status && status.error && (
          <div style={{ margin: "10px", textAlign: "center" }}>
            <ErrorText>{status.error}</ErrorText>
          </div>
        )}
      </>
    );
  }
}

export default AuthError;
