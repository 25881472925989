import * as React from "react";
import DayPicker, { DayModifiers } from "react-day-picker";
import { connect } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";
import { AppActions } from "../../../types/actions";
import { setDateFilter } from "../../../actions/filter";
import { AppState } from "../../../store/configureStore";
import { getDates } from "../../../selectors/getDates";
import { Workout } from "../../../types";
import { PRIMARY_COLOR } from "../../../utils/variables";
import styled from "styled-components";
import { fetchCoachesThunk, inviteCoachThunk } from "../../../api/coach";
import { create } from "apisauce";
import { dashboardIcon } from "../../../img";
import momentTimezone from "moment-timezone";
// QUESTION: When a new swimmer is select, where do we put the new date? Latest date available?

export interface DateSelectorProps {
  onDateChange: any;
  external_swimmer_id: number | null;
  onMonthChange: any;
  fetchWorkoutOnDateClick: any;
  dateState: any;
  getDate: any;
}

export interface DateSelectorState {
  selectedDate: Workout;
  workoutDates: Workout[];
  selectedDays: any;
  month: any;
  year: any;
  epoch: any;
  workoutDatesAPI: any;
  dates: any;
}
const api = create({
  baseURL: "https://ap1.phlex.us/scripts",
  headers: {
    // "Access-Control-Allow-Origin": "*",
    // Authorization: "Bearer {token}",
    "Content-Type": "application/x-www-form-urlencoded",
    Accept: "application/json"
  }
});
type Props = DateSelectorProps & LinkDispatchProps & LinkStateProps;

class DateSelector extends React.Component<Props, DateSelectorState> {
  constructor(props: Props) {
    super(props);

    const { workoutDates } = this.props;

    this.state = {
      selectedDate: workoutDates[workoutDates.length - 1],
      workoutDates: this.props.workoutDates,
      selectedDays: null,
      month: null,
      year: null,
      epoch: null,
      workoutDatesAPI: this.props.dateState,
      dates: []
    };
  }

  modiferStyles = {
    highlighted: { color: "#00d1e1" }
  };

  componentDidMount = async () => {
    const currentTime = momentTimezone.tz.guess();

    this.props.fetchCoaches(this.props.user.organization_id);
    const currentDate = new Date();
    const month = Number(currentDate.getMonth()) + 1;
    // console.log("COMPONENT DID MOUNT MONTH", month);
    const year = Number(currentDate.getFullYear());
    this.setState({
      month: month,
      year: year
    });
    console.log("DATESELECTOR PROPS", this.props);
    if (this.props.dateState.objects && !this.props.dateState.objects.dates) {
      const formData = new FormData();
      formData.append(
        "requesting_user_id",
        `${this.props.external_swimmer_id}`
      );
      formData.append("month", `${this.state.month}`);
      formData.append("year", `${this.state.year}`);
      formData.append("user_token", "super_token");
      formData.append("timezone", currentTime);

      const res = await api.post("/fetch-workout-dates.php", formData);
      console.log("WORKOUT DATE API DATA", res.data as any);
      this.setState({
        workoutDatesAPI: res.data as any
      });
      this.props.onMonthChange(this.state.workoutDatesAPI);
    }
  };
  componentWillReceiveProps = (nextProps: Props) => {
    if (nextProps.workoutDates !== this.state.workoutDates) {
      this.setState({
        workoutDates: nextProps.workoutDates
      });
    }
  };

  handleMonthClick = async (month: Date) => {
    const currentTime = momentTimezone.tz.guess();
    const formData = new FormData();
    const newDate = new Date(month + "");
    this.setState({
      month: Number(newDate.getMonth())
    });
    formData.append("requesting_user_id", `${this.props.external_swimmer_id}`);
    formData.append("month", `${Number(newDate.getMonth()) + 1}`);
    formData.append("year", `${this.state.year}`);
    formData.append("user_token", "super_token");
    formData.append("timezone", currentTime);

    const res = await api.post("/fetch-workout-dates.php", formData);
    // console.log("WORKOUT DATE API DATA", res.data as any);
    this.setState({
      workoutDatesAPI: res.data as any
    });
    this.props.onMonthChange(this.state.workoutDatesAPI);
    this.props.getDate(this.state.month, this.state.year, this.state.epoch);
  };

  handleDayClick = async (day: Date, { selected }: DayModifiers) => {
    const currentTime = momentTimezone.tz.guess();
    console.log("test ", day.toLocaleDateString());
    const newDate = new Date(day + "");
    const epoch = newDate.getTime() / 1000;

    this.setState({
      selectedDays: selected
        ? undefined
        : new Date(newDate.toLocaleDateString()),
      month: selected ? undefined : Number(newDate.getMonth()),
      year: selected ? undefined : Number(newDate.getFullYear()),
      epoch: selected ? undefined : Number(newDate.getTime()) / 1000
    });
    this.props.onDateChange(newDate.getMonth(), newDate.getFullYear(), epoch);

    //on click get workouts for each date
    const formData = new FormData();
    formData.append("requesting_user_id", `${this.props.external_swimmer_id}`);
    formData.append("date_epoch", `${epoch}`);
    formData.append("user_token", "super_token");
    formData.append("timezone", currentTime);

    const res = await api.post("/fetch-user-workouts.php", formData);
    console.log("WORKOUTSELECTOR DATA", res.data);
    this.props.fetchWorkoutOnDateClick(res.data);
  };

  render() {
    return (
      <div>
        <StyledDayPicker
          modifiers={{
            highlighted:
              this.props.dateState.objects && this.props.dateState.objects.dates
                ? this.props.dateState.objects.dates.map(
                    workout => new Date(new Date(workout).getTime())
                  )
                : null
          }}
          modifiersStyles={this.modiferStyles}
          selectedDays={this.state.selectedDays}
          onDayClick={this.handleDayClick}
          onMonthChange={this.handleMonthClick}
        />
        {/* <p>
          {this.state.selectedDays
            ? this.state.month + " " + this.state.year
            : "EMPTY DATE"}
        </p> */}
      </div>
    );
  }
}

const StyledDayPicker = styled(DayPicker)`
  .DayPicker {
    display: inline-block;
    font-size: 30px;
  }
  .DayPicker-wrapper {
    background-color: white;
    /* border: 1px solid #e8e8e8;
    box-shadow: 0 3px 8px 0 "#6D7185"; */
  }

  .DayPicker-Month {
    margin: 0 2.5em;
    margin-top: 1.5em;
    margin-bottom: 0.5em;
    font-family: ${props => props.theme.PRIMARY_FONT};
    font-size: 14px;
    font-weight: 700;
    font-stretch: condensed;
    letter-spacing: 0.2px;
    color: #09397f;
  }

  .DayPicker-Weekday {
    opacity: 0.5;
    font-family: ${props => props.theme.PRIMARY_FONT};
    font-size: 15px;
    font-stretch: condensed;
    letter-spacing: 0.2px;
    color: rgba(31, 33, 37, 0.5);
  }

  .DayPicker-Day {
    padding: 0.5em 1em;
    font-size: 15px;
    color: #8f9092;
    font-family: ${props => props.theme.PRIMARY_FONT};
    font-stretch: condensed;
    letter-spacing: 0.2px;
  }

  .DayPicker-Caption > div {
    text-align: center;
  }
`;

interface LinkDispatchProps {
  setDateFilter: (date: number) => void;
  fetchCoaches: (organization_id) => void;
}

const mapDispatchToProps = (
  dispatch: Dispatch<AppActions>
): LinkDispatchProps => ({
  setDateFilter: bindActionCreators(setDateFilter, dispatch),
  fetchCoaches: bindActionCreators(fetchCoachesThunk, dispatch)
});

interface LinkStateProps {
  workoutDates: Workout[];
  user: any;
}

const mapStateToProps = (state: AppState): LinkStateProps => ({
  workoutDates: getDates(state.workouts, state.filters.swimmerId),
  user: state.auth.user
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DateSelector);
