// TODO: Use chart.js to make doughnut chart
import * as React from "react";
import {
  VictoryPie,
  VictoryPieProps,
  VictoryChart,
  VictoryArea,
  VictoryAreaProps,
  VictoryCursorContainer,
  VictoryAxis
} from "victory";
import styled from "styled-components";
import { Card, AppTitle } from "../../UIElements";
import { dummyHRZoneLine } from "../../../static/lineData";
import { number } from "prop-types";

export interface AreaProps {
  title?: string;
}

// const findClosestPointSorted = (data, value) => {
// 	// assumes 3 things:
//   // 1. data is sorted by x
//   // 2. data points are equally spaced
//   // 3. the search is 1-dimentional (x, not x and y)
//   if (value === null) return null;
// 	const start = first(data).x;
// 	const range = (last(data).x - start);
//   const index = Math.round((value - start)/range * (data.length - 1));
//   return data[index];
// };

const numberConvertor = time => {
  const sec_num = parseInt(time, 10); // don't forget the second param
  let hours = Math.floor(sec_num / 3600);
  let minutes = Math.floor((sec_num - hours * 3600) / 60);
  let seconds = sec_num - hours * 3600 - minutes * 60;

  if (hours < 10) {
    hours = hours;
  }
  if (minutes < 10) {
    minutes = minutes;
  }
  if (seconds < 10) {
    seconds = seconds;
  }
  return hours + ":" + minutes + ":" + seconds;
};

const AreaGraph: React.SFC<AreaProps & VictoryAreaProps> = ({
  title,
  ...props
}) => {
  // console.log("TITLE", title);
  // console.log("CHART PROPS", props);
  console.log("AREA CHART PROPS", props);
  return (
    <Div>
      <Heading>{title}</Heading>
      <div>
        <VictoryChart
          domain={{ y: [0, 200] }}
          padding={{ bottom: 30, top: 10, right: 20, left: 40 }}
        >
          <VictoryAxis
            dependentAxis
            tickFormat={t => `${t}`}
            style={{
              tickLabels: { stroke: "#cccccc" }
            }}
            standalone={false}
          />
          {/* X Axis */}
          <VictoryAxis
            standalone={false}
            tickFormat={t => `${numberConvertor(t)}`}
            style={{
              tickLabels: { stroke: "#cccccc" }
              // axis: {
              //   stroke: "transparent"
              // }
            }}
          />
          <VictoryArea
            {...props}
            animate={{ duration: 500, onLoad: { duration: 500 } }}
            style={{
              data: {
                fill: "#00d1e1",
                fillOpacity: 0.05,
                stroke: "#00d1e1",
                strokeWidth: 3
              },
              parent: {}
            }}
            padding={0}
          />
        </VictoryChart>
      </div>
    </Div>
  );
};

AreaGraph.defaultProps = {
  title: "HR Zone"
};

const Div = styled(Card)`
  height: 352px;
  width: 444.5px;
  padding: 0px 0 10px 0;
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const Heading = styled(AppTitle)`
  opacity: 0.5;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  color: #1f2125;
`;

export { AreaGraph };
