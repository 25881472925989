import { FormikErrors } from "formik";

// * List of errors
// * https://www.firebase.com/docs/web/guide/user-auth.html#section-full-error
// * https://firebase.google.com/docs/reference/js/firebase.auth.Auth#sendPasswordResetEmail

const DEFAULT_ERROR = "Unable to Authenticate";
const DEFAULT_PASSWORD_RESET_ERROR = "Unable to send password reset email";

export const setError = (
  errorCode: string,
  setFieldError: (field: string, errorMsg: string) => void,
  setFormError: ({ error }: any) => void
) => {
  console.log("errorCode: ", errorCode);
  switch (errorCode) {
    case "auth/invalid-email":
      setFieldError("email", getError(errorCode));
      return;
    case "auth/user-disabled":
      setFieldError("email", getError(errorCode));
      return;
    case "auth/user-not-found":
      setFieldError("email", getError(errorCode));
      return;
    case "auth/wrong-password":
      setFieldError("password", getError(errorCode));
      return;
    case "auth/email-already-in-use":
      setFieldError("email", getError(errorCode));
      return;
    case "auth/operation-not-allowed":
      setFormError({ error: getError(errorCode) });
      return;
    case "auth/weak-password":
      setFieldError("password", getError(errorCode));
      return;
    case "auth/missing-android-pkg-name":
      // Not used
      setFormError({ error: getError(errorCode) });
      return;
    case "auth/missing-continue-uri":
      setFormError({ error: getError(errorCode) });
      return;
    case "auth/missing-ios-bundle-id":
      setFormError({ error: getError(errorCode) });
      return;
    case "auth/unauthorized-continue-uri":
      setFormError({ error: getError(errorCode) });
      return;
    case "auth/invalid-dynamic-link-domain":
      setFormError({ error: getError(errorCode) });
      return;
    case "auth/weak-password":
      setFieldError("password", getError(errorCode));
      return;
    case "AUTHENTICATION_DISABLED":
      setFormError({ error: getError(errorCode) });
      return;
    case "EMAIL_TAKEN":
      setFieldError("email", getError(errorCode));
      return;
    case "INVALID_ARGUMENTS":
      setFormError({ error: getError(errorCode) });
      return;
    case "INVALID_CONFIGURATION":
      setFormError({ error: getError(errorCode) });
      return;
    case "INVALID_CREDENTIALS":
      setFormError({ error: getError(errorCode) });
      return;
    case "INVALID_EMAIL":
      setFieldError("email", getError(errorCode));
      return;
    case "INVALID_ORIGIN":
      setFormError({ error: getError(errorCode) });
      return;
    case "INVALID_PASSWORD":
      setFieldError("password", getError(errorCode));
      return;
    case "INVALID_PROVIDER":
      setFormError({ error: getError(errorCode) });
      return;
    case "INVALID_TOKEN":
      setFormError({ error: getError(errorCode) });
      return;
    case "INVALID_USER":
      setFieldError("email", getError(errorCode));
      return;
    case "NETWORK_ERROR":
      setFormError({
        error: getError(errorCode)
      });
      return;
    case "PROVIDER_ERROR":
      setFormError({ error: getError(errorCode) });
      return;
    case "TRANSPORT_UNAVAILABLE":
      setFormError({ error: getError(errorCode) });
      return;
    case "UNKNOWN_ERROR":
      setFormError({ error: getError(errorCode) });
      return;
    case "USER_CANCELLED":
      // Do nothing. User cancelled request
      return;
    case "USER_DENIED":
      setFormError({ error: getError(errorCode) });
      return;
    default:
      return DEFAULT_ERROR;
  }
};

export const getError = (error: string): string => {
  switch (error) {
    case "auth/account-exists-with-different-credential":
      return "Try a different login method";
    case "auth/popup-closed-by-user":
      return "";
    case "Invalid data.":
      return "Invalid email/password";
    case "auth/invalid-email":
      return "Invalid email address";
    case "auth/user-disabled":
      return "Your email has been disabled.";
    case "auth/user-not-found":
      return "User not found";
    case "auth/wrong-password":
      return "Invalid password";
    case "auth/email-already-in-use":
      return "Email address already in use";
    case "auth/operation-not-allowed":
      return DEFAULT_ERROR;
    case "auth/weak-password":
      return "Too weak. Try using a stronger password";
    case "auth/missing-android-pkg-name":
      // Not used
      return DEFAULT_PASSWORD_RESET_ERROR;
    case "auth/missing-continue-uri":
      return DEFAULT_PASSWORD_RESET_ERROR;
    case "auth/missing-ios-bundle-id":
      return DEFAULT_PASSWORD_RESET_ERROR;
    case "auth/unauthorized-continue-uri":
      return DEFAULT_PASSWORD_RESET_ERROR;
    case "auth/invalid-dynamic-link-domain":
      return DEFAULT_PASSWORD_RESET_ERROR;
    case "auth/weak-password":
      return "Too weak. Try using a stronger password";
    case "AUTHENTICATION_DISABLED":
      return DEFAULT_ERROR;
    case "EMAIL_TAKEN":
      return "Email already taken. Pleaase choose another.";
    case "EMAIL_IS_TAKEN":
      return "Email is already taken";
    case "INVALID_ARGUMENTS":
      return DEFAULT_ERROR;
    case "INVALID_CONFIGURATION":
      return DEFAULT_ERROR;
    case "INVALID_CREDENTIALS":
      return DEFAULT_ERROR;
    case "INVALID_EMAIL":
      return "Invalid email. Please specify a valid email";
    case "INVALID_ORIGIN":
      return DEFAULT_ERROR;
    case "INVALID_PASSWORD":
      return "Wrong password. Please try again or click on 'Forgot password'";
    case "INVALID_PROVIDER":
      return DEFAULT_ERROR;
    case "INVALID_TOKEN":
      return DEFAULT_ERROR;
    case "INVALID_USER":
      return "Account not found";
    case "NETWORK_ERROR":
      return "We were unable to connect to the network at this time. Please try again when you have a better connection";
    case "PROVIDER_ERROR":
      return DEFAULT_ERROR;
    case "TRANSPORT_UNAVAILABLE":
      return DEFAULT_ERROR;
    case "UNKNOWN_ERROR":
      return DEFAULT_ERROR;
    case "USER_CANCELLED":
      // Do nothing. User cancelled request
      return "";
    case "USER_DENIED":
      return DEFAULT_ERROR;
    default:
      return DEFAULT_ERROR;
  }
};
