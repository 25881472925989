import { create } from "apisauce";
import { ThunkAction } from "redux-thunk";
import { AppState } from "../store/configureStore";
import { ActionCreator } from "redux";
import { MemberActionTypes } from "../types/actions";
import { inviteCoach, fetchCoaches } from "../actions/coach";
import { Coach } from "../types";
import { api } from "../api/api";

// const token = 134;

//Thunk creators
export const fetchCoachesThunk = () => async (dispatch, getState) => {
  //   dispatch,
  //   getState
  // ) => {
  // const organization_id = getState().organization.organization_id;
  const token = getState().auth.token;
  const organization_id = getState().auth.user.organization_id;
  try {
    const res = await api.get(
      `/coach/get-by-organization?organization_id=${organization_id}`,
      {},
      { headers: { Authorization: `Bearer ${token}` } }
    );
    console.log("GET COACHES", res.data);

    const action = fetchCoaches(res.data as any);
    dispatch(action);
  } catch (err) {
    console.log(err);
  }
};

export const inviteCoachThunk = email => async (dispatch, getState) => {
  const organization_id = getState().auth.user.organization_id;
  const token = getState().auth.token;
  try {
    const res: any = await api.post(
      `/coach/invite-to-organization?email=${email}&organization_id=${organization_id}`,
      {},
      { headers: { Authorization: `Bearer ${token}` } }
    );
    if (res.ok) {
      const action = inviteCoach(res.data as Coach);
      dispatch(action);
      console.log("invite coach happened", action);
    }
    if (!res.ok && res.data.error) {
      return res.data.error;
    }
  } catch (err) {
    console.log(err);
  }
};
