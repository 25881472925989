import { SwimmerDataActionTypes } from "../types/actions";
import { SwimmerWorkoutData } from "../types";

const SwimmerDataDefaultState: SwimmerWorkoutData = {
  external_swimmer_id: null
};

const SwimmerDataReducer = (
  state = SwimmerDataDefaultState,
  action: SwimmerDataActionTypes
): SwimmerWorkoutData => {
  switch (action.type) {
    case "SET_SWIMMER_ID":
      return { external_swimmer_id: action.external_swimmer_id };
    case "LOGOUT":
      return SwimmerDataDefaultState;
    default:
      return state;
  }
};

export default SwimmerDataReducer;
