import * as React from "react";
import styled from "styled-components";
import { ReactNode, SFC } from "react";
import { PRIMARY_COLOR } from "../../utils/variables";

// * One stop shop for text in this app. Simply specify the required type
// TODO: I should refactor this to not need to manually pass text based props down

interface Props {
  color?: string | undefined;
  fontSize?: string;
  fontWeight?: string | undefined;
  lineHeight?: string | undefined;
  textAlign?: string | undefined;
  margin?: string | undefined;
  ellipsis?: boolean;
  clickable?: boolean;
}

export const AppText = styled.p<Props>`
  color: ${props => props.color};
  font-weight: ${(props: Props) => props.fontWeight};
  line-height: ${props => props.lineHeight};
  text-align: ${props => props.textAlign};
  font-family: ${props => props.theme.PRIMARY_FONT};
  font-size: ${(props: Props) => props.fontSize};
  margin: ${(props: Props) => props.margin};
  font-style: "";
  text-overflow: ${(props: Props) => (props.ellipsis ? "ellipsis" : "inherit")};
  white-space: ${(props: Props) => (props.ellipsis ? "nowrap" : "inherit")};
  overflow: ${(props: Props) => (props.ellipsis ? "hidden" : "inherit")};
  :hover {
    cursor: ${props => (props.clickable ? "pointer" : "normal")};
  }
`;

AppText.defaultProps = {
  margin: "0",
  color: "#000",
  fontSize: "14px",
  fontWeight: "500",
  lineHeight: "normal",
  textAlign: "left",
  ellipsis: false
};

interface TitleProps {
  fontSize?: string;
  clickable?: boolean;
}

export const ErrorText = styled(AppText)`
  color: red;
`;

export const SuccessText = styled(AppText)`
  color: green;
`;

export const LinkText = styled(AppText)`
  color: #09397f;
  text-decoration: underline;
  font-size: 12px;
`;

export const LightText = styled(AppText)`
  color: #8f9092;
  letter-spacing: 0.2px;
  font-size: 12px;
`;

export const HeaderText = styled(AppText)`
  color: #fff;
  letter-spacing: 0.2px;
  font-size: 16px;
  font-weight: 500;
`;

export const FormFieldLabel = styled(AppText)`
  color: rgba(9, 57, 127, 0.9);
  letter-spacing: 0.2px;
  font-size: 14px;
`;

export const GraphBigText = styled(AppText)`
  font-size: 26px;
  font-stretch: condensed;
  text-align: center;
  color: #1f2125;
`;

export const GraphLightText = styled(AppText)`
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.1px;
  text-align: center;
  color: #cccccc;
`;

export const AppTitle = styled.h1<TitleProps>`
  color: ${props => props.theme.TITLE_COLOR};
  font-family: ${props => props.theme.PRIMARY_FONT};
  font-size: ${(props: TitleProps) => props.fontSize};
  font-weight: 600;
  margin: 0;
  :hover {
    cursor: ${props => (props.clickable ? "pointer" : "normal")};
  }
`;

AppTitle.defaultProps = {
  fontSize: "24px"
};

export const AuthTitle = styled(AppTitle)`
  font-weight: bold;
  font-stretch: condensed;
  letter-spacing: 1.3px;
  color: #1a76bc;
`;

export const HeaderTitle = styled(AppTitle)`
  font-weight: 500;
  line-height: 2.05;
  letter-spacing: 0.2px;
  color: #fff;
`;

export const PageTitle = styled(AppTitle)`
  font-size: 34px;
  font-weight: bold;
  letter-spacing: 0.4px;
  color: #09397f;
  font-stretch: condensed;
`;

export const CardTitle = styled(AppTitle)`
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0.3px;
  color: #09397f;
  font-stretch: condensed;
`;

export const ModalTitle = styled(AppTitle)`
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0.3px;
  color: rgba(9, 57, 127, 0.9);
`;

export const TableTitle = styled(AppTitle)`
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0.2px;
  color: #8f9092;
`;
