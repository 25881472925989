import React from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import { AppState } from "../store/configureStore";
import { Background } from "../Components/UIElements/Background";

// Public Route
// See AppRouter for more information

interface PublicRouteProps {
  component: any;
  path: string;
  exact?: boolean;
}

type Props = PublicRouteProps & LinkStateProps;

export const PublicRoute = ({
  isAuthenticated,
  component: Component,
  ...rest // sets the rest of the parameters to the rest variable
}: Props) => {
  return (
    <Route
      {...rest}
      component={(props: any) =>
        !isAuthenticated ? (
          <Background>
            <Component {...props} />
          </Background>
        ) : (
          <Redirect to="/dashboard" />
        )
      }
    />
  );
};

interface LinkStateProps {
  isAuthenticated: boolean;
}

const mapStateToProps = (state: AppState): LinkStateProps => {
  return {
    isAuthenticated: !!state.auth.token
  };
};

export default connect(mapStateToProps)(PublicRoute);
