import { MemberActionTypes } from "../types/actions";
import { Member } from "../types";

// * Will add type property to account for swimmer / coach / Headcoach

const memberReducerDefaultState: Member[] = [];

// This reducer holds user information that is returned from login/signup
// post requests

const memberReducer = (
  state = memberReducerDefaultState,
  action: MemberActionTypes
): Member[] => {
  switch (action.type) {
    case "FETCH_MEMBERS":
      return [...action.members];
    case "ADD_MEMBER":
      return [...state, action.member];
    case "REMOVE_MEMBER":
      return state.filter(
        (Member: Member) => Member.member_id != action.member_id
      );
    case "EDIT_MEMBER":
      return state.map((member: Member) => {
        if (member.member_id === action.member_id) {
          return {
            ...member,
            ...action.updates
          };
        }
        return member;
      });
    case "LOGOUT":
      return memberReducerDefaultState;
    default:
      return state;
  }
};

export default memberReducer;
