import * as React from "react";
import { connect } from "react-redux";
import { Container, Content, AppText, PageTitle } from "../UIElements";
import styled from "styled-components";
import { fetchUser } from "../../actions/user";
import { User } from "../../types";
import { Dispatch, bindActionCreators } from "redux";
import { AppActions } from "../../types/actions";
import { AppState } from "../../store/configureStore";
import BasicInformation from "../Segments/cards/BasicInformation";
import MyOrganization from "../Segments/cards/MyOrganization";
import Coaches from "../Segments/cards/Coaches";

// * Also known as My Account page

// TODO: Add responsiveness

interface ProfileProps {}
interface ProfileState {}

type Props = ProfileProps & LinkStateProps & LinkDispatchProps;

class Profile extends React.Component<Props, ProfileState> {
  componentDidMount = async () => {
    // const { user_token, user_id } = this.props;
    // const user = await fetchUserAPI(user_token as string, user_id as number);
    // if (user.error) {
    //   return console.log("failure to load user: ", user.error);
    // }
    // this.props.fetchUser(user);
  };
  render(): JSX.Element {
    return (
      <Container>
        <Grid>
          <PageTitle
            style={{ gridRow: "1 / span 1", gridColumn: "1 / span 1" }}
          >
            My Profile
          </PageTitle>
          <div style={{ gridRow: "2 / span 1", gridColumn: "1 / span 1" }}>
            <BasicInformation />
          </div>
          <div style={{ gridRow: "2 / span 1", gridColumn: "2 / span 1" }}>
            <MyOrganization />
          </div>
          <div style={{ gridRow: "3 / span 1", gridColumn: "1 / span 2" }}>
            <Coaches />
          </div>
        </Grid>
      </Container>
    );
  }
}

const Grid = styled.div`
  display: grid;
  grid-template-rows: min-content min-content auto;
  grid-template-columns: min-content min-content;
  grid-gap: 17px;
  /* margin: 116px 161px 66px 161px; */
`;

interface LinkDispatchProps {
  fetchUser: (user: User) => void;
}

interface LinkStateProps {
  user_token: string | undefined;
  user_id: number | null;
}

const mapDispatchToProps = (
  dispatch: Dispatch<AppActions>
): LinkDispatchProps => ({
  fetchUser: bindActionCreators(fetchUser, dispatch)
});

// isAuthenticated is true if user_token exists in redux
const mapStateToProps = (state: AppState): LinkStateProps => ({
  user_token: state.auth.access_token,
  user_id: state.user.user_id
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Profile);
