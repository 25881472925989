import styled from "styled-components";

interface Props {
  center?: boolean;
}

export const FormSegment = styled.div<Props>`
  text-align: ${props => (props.center ? "center" : "left")};
`;

FormSegment.defaultProps = {
  center: false
};
