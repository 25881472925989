import styled from "styled-components";

interface Props {
  error?: boolean;
  inputWidth?: string;
}

const Dropdown = styled.select<Props>`
  width: ${props => props.inputWidth};
  height: 50px;
  background-color: #ffffff;
  border-radius: 25px;
  border: solid 1px #dfe0eb;
  padding-left: 25px;
  outline: ${props => (props.error ? "none" : "")};
  border-color: ${props => (props.error ? "red" : "")};
  box-shadow: ${props => (props.error ? "0 0 5px red" : "")};
  color: #252733;
  font-size: 16px;
  font-family: ${props => props.theme.PRIMARY_FONT};
  height: 50px;
  background-color: #ffffff;
  border-radius: 25px;
  border: solid 1px #dfe0eb;
  padding-left: 25px;
  outline: ${props => (props.error ? "none" : "")};
  border-color: ${props => (props.error ? "red" : "")};
  box-shadow: ${props => (props.error ? "0 0 5px red" : "")};
  color: #252733;
  font-size: 16px;
  &:disabled {
    background: lightgray;
  }
  font-family: ${props => props.theme.PRIMARY_FONT};
  :focus {
    outline: none;
    box-shadow: 0px 0px 2px blue;
  }
  ::placeholder {
    color: #9fa2b4;
    font-size: 16px;
    font-family: ${props => props.theme.PRIMARY_FONT};
  }
`;

export { Dropdown };
