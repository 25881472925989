import { SwimmerActionTypes } from "./../types/actions";
import { Swimmer } from "../types";

const defaultSwimmerReducerState: Swimmer[] = [];

// Swimmer = {
//   id: null,
//   external_swimmer_id: null,
//   first_name: "",
//   last_name: "",
//   email: ""
// }

const allSwimmerReducer = (
  state = defaultSwimmerReducerState,
  action: SwimmerActionTypes
): Swimmer[] => {
  switch (action.type) {
    case "FETCH_SWIMMERS":
      return action.swimmers.swimmers;
    case "DELETE_SWIMMER":
      console.log(action.swimmer);
      console.log("STATE", state);
      return state.filter(swimmer => {
        return swimmer.external_swimmer_id !== action.swimmer.id;
      });
    case "LOGOUT":
      return defaultSwimmerReducerState;
    default:
      return state;
  }
};

export default allSwimmerReducer;
