import * as React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { AppText, Icon } from ".";
import { toggleUp, toggleDown } from "../../img";

interface CollapsibleProps {
  title: string;
  index: any;
}
interface CollapsibleState {
  isOpen: boolean;
}

class Collapsible extends React.Component<CollapsibleProps, CollapsibleState> {
  constructor(props: CollapsibleProps) {
    super(props);
    this.state = {
      isOpen: false
    };
  }
  componentDidMount = () => {
    if (this.props.index === 0) {
      this.setState({
        isOpen: true
      });
    }
  };
  toggleCollapsible = () => this.setState(prev => ({ isOpen: !prev.isOpen }));
  render() {
    const { isOpen } = this.state;
    return (
      <Div>
        <TitleDiv onClick={this.toggleCollapsible}>
          <CollapsibleTitle>{this.props.title}</CollapsibleTitle>
          <Icon
            clickable
            src={isOpen ? toggleUp : toggleDown}
            height={24}
            width={24}
          />
        </TitleDiv>
        {isOpen && <ChildDiv>{this.props.children}</ChildDiv>}
      </Div>
    );
  }
}

const Div = styled.div`
  transition: 0.25s;
  transition-timing-function: cubic-bezier(0.17, 0.67, 0.34, 0.86);
`;

const TitleDiv = styled.div`
  background-color: #fbfbfb;
  height: 50px;
  padding-left: 36px;
  padding-right: 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  :hover {
    cursor: pointer;
  }
`;

const CollapsibleTitle = styled(AppText)`
  font-weight: 500;
  letter-spacing: 0.2px;
  color: #8f9092;
`;

const ChildDiv = styled.div`
  background-color: #fff;
  transition: height 0.25s linear;
`;

export { Collapsible };
