import * as React from "react";
import { connect } from "react-redux";
import {
  AuthContainer,
  ErrorText,
  Input,
  Button,
  AppText,
  FormSegment,
  Or,
  Center,
  Spinner,
  AppTitle,
  Card,
  Checkbox,
  Modal,
  TableHead,
  TableRow,
  TableRowName,
  TableRowEmail,
  TableRowTeam
} from "../../UIElements";
import {
  Formik,
  FormikProps,
  Form,
  Field,
  FieldProps,
  ErrorMessage
} from "formik";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { setError } from "../../../firebase/error";
import { login } from "../../../actions/auth";
import styled from "styled-components";
import { User, Member, Coach, Auth } from "../../../types";
import { fetchUser } from "../../../actions/user";
import { Dispatch, bindActionCreators } from "redux";
import { AppActions } from "../../../types/actions";
import AuthError from "../../Segments/AuthError";
import {
  LinkText,
  LightText,
  AuthTitle,
  CardTitle,
  FormFieldLabel,
  TableTitle,
  ModalTitle
} from "../../UIElements/Text";
import { AppState } from "../../../store/configureStore";
import { getCoaches } from "../../../selectors/coaches";
import { fetchMembers } from "../../../actions/member";
import { fakeMembers } from "../../../static/members";
import { fetchCoachesThunk, inviteCoachThunk } from "../../../api/coach";

// BLOCK: Cannot update backend update endpoint

const signupSchema = Yup.object().shape({
  coach: Yup.string()
    .email("* Not a valid email address")
    .max(100, "* Email cannot be more than 100 characters")
    .required("* Email is required")
});

interface SignInProps {}

interface SignInState {
  showModal: boolean;
  inviteError: string;
}

type Props = SignInProps & LinkDispatchProps & LinkStateProps;

interface MyFormikProps {
  coach: string;
}

class SignIn extends React.Component<Props, SignInState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      showModal: false,
      inviteError: ""
    };
  }
  componentDidMount = () => {
    this.props.fetchMembers(fakeMembers);
    //TODO load organization ID
    this.props.fetchCoaches(this.props.user.organization_id);
  };
  toggleModal = () => this.setState(prev => ({ showModal: !prev.showModal }));

  render(): JSX.Element | null {
    console.log("this.props: coaches", this.props);
    if (!this.props.isAuthorized) {
      return null;
    }
    // if (
    //   this.props.coachMembers.message &&
    //   this.props.coachMembers.message === "Too Many Attempts."
    // ) {
    //   return <div>TOO MANY ATTEMPTS</div>;
    // }
    return this.props.coachMembers ? (
      <>
        <Grid>
          <Spread style={{ marginBottom: "17px" }}>
            <CardTitle>Coaches</CardTitle>
            <Button width="152px" onClick={this.toggleModal}>
              Invite Coach
            </Button>
          </Spread>
          <TableHead>
            <Horizontal>
              {/* <Checkbox style={{ backgroundColor: "" }} /> */}
              <TableTitle>Name</TableTitle>
            </Horizontal>
            <TableTitle>Team</TableTitle>
          </TableHead>
          <div>
            {this.props.coachMembers["0"]
              ? this.props.coachMembers.map((coach: Coach) => {
                  return (
                    <TableRow key={coach.id}>
                      <div>
                        <TableRowName>{`${coach.first_name} ${coach.last_name}`}</TableRowName>
                        <TableRowEmail>{coach.email}</TableRowEmail>
                      </div>
                      <TableRowTeam>{coach.id}</TableRowTeam>
                    </TableRow>
                  );
                })
              : null}
          </div>
        </Grid>
        <Modal
          showModal={this.state.showModal}
          toggleModal={this.toggleModal}
          label="Invite Coach"
        >
          <Formik
            validateOnChange={true}
            validateOnBlur={false}
            initialValues={{
              coach: ""
            }}
            validationSchema={signupSchema}
            onSubmit={async (values, { setFieldError, setStatus }) => {
              const { coach } = values;
              const { inviteCoach /*,organization_id*/ } = this.props;
              // TODO will need to get organization_id from props
              const { organization_id } = this.props.auth.user;
              console.log("COACHES", organization_id);
              setStatus({ error: "", loading: true });
              try {
                const response = await inviteCoach(coach, organization_id);
                if (typeof response === "string") {
                  this.setState({
                    inviteError: response
                  });
                  return;
                }
              } catch (e) {
                console.log("login error: ", e);
                setStatus({ loading: false });
                return setError(e.code, setFieldError, setStatus);
              }
              console.log("no error");
              setStatus({ loading: false });
              this.toggleModal();
              // TODO: Replace this with the real token once the backend is hooked up.
            }}
          >
            {({
              status,
              touched,
              errors,
              setFieldTouched
            }: FormikProps<MyFormikProps>) => {
              return (
                <Form>
                  <ModalGrid>
                    <ModalTitle>Invite Coach</ModalTitle>
                    <Field
                      name="coach"
                      render={({
                        field,
                        form: { errors, touched }
                      }: FieldProps<MyFormikProps>) => {
                        // field.onChange(() => setStatus({ canChange: true }));
                        const error = !!errors.coach && !!touched.coach;
                        return (
                          <FormSegment>
                            <Input
                              {...field}
                              error={error}
                              inputWidth="407px"
                              placeholder="Invite by email address"
                              onInput={() => setFieldTouched("coach", true)}
                            />
                            <ErrorMessage
                              name={field.name}
                              component={ErrorText}
                            />
                          </FormSegment>
                        );
                      }}
                    />
                    <End>
                      {this.state.inviteError && (
                        <AuthError
                          status={status}
                          error={this.state.inviteError}
                        />
                      )}
                      <TableTitle clickable onClick={this.toggleModal}>
                        Cancel
                      </TableTitle>
                      <div
                        style={{ gridRow: "3 / span 1", marginLeft: "32px" }}
                      >
                        {/* {status && status.loading ? (
                          <Spinner />
                        ) : ( */}
                        <Button
                          width="152px"
                          disabled={
                            !(
                              Object.keys(touched).length &&
                              !Object.keys(errors).length
                            )
                          }
                          type="submit"
                        >
                          Invite
                        </Button>
                        {/* )} */}
                      </div>
                    </End>
                    <div style={{ gridRow: "4 / span 1" }}>
                      <AuthError status={status} />
                    </div>
                  </ModalGrid>
                </Form>
              );
            }}
          </Formik>
        </Modal>
      </>
    ) : (
      <Spinner />
    );
  }
}

const Grid = styled.div`
  display: flex;
  flex-direction: column;
`;

const ModalGrid = styled.div`
  display: grid;
  grid-template-rows: 60px 70px 40px min-content;
  padding: 32px 46px 24px 46px;
`;

const Spread = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px 0 24px;
`;

const Horizontal = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const End = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

interface LinkDispatchProps {
  login: (token: string) => void;
  fetchMembers: (members: Member[]) => void;
  fetchCoaches: (organization_id) => void;
  inviteCoach: (email: string, organization_id: any) => void;
}

const mapDispatchToProps = (
  dispatch: Dispatch<AppActions>
): LinkDispatchProps => ({
  login: bindActionCreators(login, dispatch),
  fetchMembers: bindActionCreators(fetchMembers, dispatch),
  fetchCoaches: bindActionCreators(fetchCoachesThunk, dispatch),
  inviteCoach: bindActionCreators(inviteCoachThunk, dispatch)
});

interface LinkStateProps {
  isAuthorized: boolean;
  coaches: Member[];
  auth: Auth;
  user: any;
  coachMembers: any;
}

// TODO: Switch isAuthorized back to real check
const mapStateToProps = (state: AppState): LinkStateProps => ({
  // isAuthorized: state.auth.role === "Head Coach"
  isAuthorized: true,
  coaches: getCoaches(state.members),
  auth: state.auth,
  user: state.auth.user,
  coachMembers: state.coaches
});

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
