import {
  FETCH_TEAMS,
  EDIT_TEAM,
  ADD_TEAM,
  REMOVE_TEAM,
  FETCH_TEAMMATES
} from "../types/actions";
import { Member, Team } from "../types";

export const fetchTeams = (teams: Team[]) => ({
  type: FETCH_TEAMS,
  teams
});

export const editTeam = (team: Team) => ({
  type: EDIT_TEAM,
  updates: team
});

export const addTeam = (team: Team) => ({
  type: ADD_TEAM,
  team
});

export const removeTeam = (team_id: number) => ({
  type: REMOVE_TEAM,
  team_id
});

export const fetchTeammates = (teammembers: Team[]) => ({
  type: FETCH_TEAMMATES,
  teammembers
});
