import { create } from "apisauce";
import { fetchSwimmers, deleteSwimmer } from "../actions/swimmer";
import { Swimmer } from "../types";
import { api } from "../api/api";

export const fetchSwimmersThunk = () => async (dispatch, getState) => {
  const token = getState().auth.token;
  const organization_id = getState().auth.user.organization_id;
  try {
    const res = await api.get(
      `/swimmer/get-by-organization?organization_id=${organization_id}`,
      {},
      { headers: { Authorization: `Bearer ${token}` } }
    );
    const action = fetchSwimmers(res.data as any);
    console.log("Swimmers RES DATA", res.data);
    dispatch(action);
  } catch (err) {
    console.log(err);
  }
};

export const deleteSwimmerThunk = member_id => async (dispatch, getState) => {
  const token = getState().auth.token;
  try {
    // const removeSwimMember = await api.delete(`/swimmer?member_id=${member_id}`);
    const deleteswimmer = await api.delete(
      `/swimmer/${member_id}`,
      // `/swimmer?external_swimmer_id=${member_id}`,
      {},
      { headers: { Authorization: `Bearer ${token}` } }
    );
    console.log("DELETE SWIMMER THUNK RESPONSE", deleteswimmer);
    const action = deleteSwimmer({ id: member_id } as any);
    dispatch(action);
  } catch (err) {
    console.log(err);
  }
};
