import React from "react";
import { Switch, Route, HashRouter } from "react-router-dom";
import createHistory from "history/createBrowserHistory";
import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";
import Profile from "../Components/Pages/Profile";
import SignIn from "../Components/Pages/SignIn";
import SignUp from "../Components/Pages/SignUp";
import Dashboard from "../Components/Pages/Dashboard";
import Header from "../Components/Segments/Header";
import ManageTeam from "../Components/Pages/ManageTeam";
import EditTeam from "../Components/Pages/EditTeam";

// Public and Private routes are used to handle authentication
// If authed user tries to access a public route, he or she will be
// routed to /main
// If unauthed user tries to access a private route, he or she will be
// routed to /

// Authentication is determined on whether the user_token value exists in
// Redux.

export const history = createHistory();

const AppRouter = () => (
  <HashRouter>
    <>
      <Header />
      <Switch>
        <PrivateRoute path="/" component={Dashboard} exact={true} />
        <PublicRoute path="/signin" component={SignIn} />
        <PublicRoute path="/signup" component={SignUp} />
        <PrivateRoute path="/profile" component={Profile} />
        <PrivateRoute path="/dashboard" component={Dashboard} />
        <PrivateRoute path="/team" component={ManageTeam} />
        <PrivateRoute path="/group/:team_id" component={EditTeam} />
      </Switch>
    </>
  </HashRouter>
);

export default AppRouter;
