import styled from "styled-components";
import { AppText } from ".";

export const TableHead = styled.div`
  background-color: #f4f5f5;
  height: 46px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 47px 0 47px;
`;

export const TableRow = styled.div`
  background-color: #fff;
  height: 80px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 47px 0 47px;
  border-bottom: 1px solid #f4f5f5;
  /* border-bottom-color: #f4f5f5; */
`;

export const TableRowName = styled(AppText)`
  font-size: 16px;
  color: #1f2125;
`;

export const TableRowDelete = styled(AppText)`
  font-size: 16px;
  color: #fa4374;
`;
export const TableRowEmail = styled(AppText)`
  font-size: 12px;
  color: #989898;
`;

export const TableRowTeam = styled(AppText)`
  font-size: 16px;
  line-height: 1.75;
  text-align: right;
  color: #989898;
`;
