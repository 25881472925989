import { OrganizationActionTypes } from "../types/actions";
import { Organization } from "../types";

const memberReducerDefaultState: Organization = {
  name: "",
  id: ""
};

const organizationReducer = (
  state = memberReducerDefaultState,
  action: OrganizationActionTypes
): Organization => {
  switch (action.type) {
    case "FETCH_ORGANIZATIONS":
      return action.organizations;
    case "EDIT_ORGANIZATION":
      return {
        ...state,
        name: action.organization.name
      };
    // state.map((organization: Organization) => {
    //   if (organization.organization_id === action.updates.id) {
    //     return {
    //       ...organization,
    //       ...action.updates
    //     };
    //   }
    //   return organization;
    // });
    // case "ADD_ORGANIZATION":
    //   return {...state, organizations: action.organization};
    case "LOGOUT":
      return memberReducerDefaultState;
    default:
      return state;
  }
};

export default organizationReducer;
