import styled from "styled-components";

interface Props {
  width?: string;
  backgroundColor?: string;
  color?: string;
}
const HR = styled.hr<Props>`
  width: ${props => props.width};
  background-color: ${props => props.backgroundColor};
  color: ${props => props.color};
  margin: 0;
`;

HR.defaultProps = {
  width: "100%",
  backgroundColor: "#e5e5e5",
  color: "#e5e5e5"
};

export { HR };
