import * as React from "react";
import styled from "styled-components";
import { WaveLoading } from "styled-spinkit";
import RSpinner from "react-spinkit";
import { PRIMARY_COLOR } from "../../utils/variables";

export interface SpinnerProps {
  color?: string;
  size?: number;
}

const Spinner: React.SFC<SpinnerProps> = ({ ...props }) => {
  return (
    <Div>
      <RSpinner name="line-scale" color={PRIMARY_COLOR} fadeIn="none" />
    </Div>
  );
};

const Div = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export { Spinner };
