import {
  FETCH_MEMBERS,
  EDIT_MEMBER,
  ADD_MEMBER,
  REMOVE_MEMBER,
  FETCH_MEMBER_TEAMS,
  FETCH_ONE_MEMBER
} from "../types/actions";
import { Member } from "../types";

export const fetchMembers = (members: Member[]) => ({
  type: FETCH_MEMBERS,
  members
});

export const editMember = (member: Member) => ({
  type: EDIT_MEMBER,
  member
});

export const addMember = (member: Member) => ({
  type: ADD_MEMBER,
  member
});

export const removeMember = (member_id: string) => ({
  type: REMOVE_MEMBER,
  member_id
});

export const fetchOneMember = (member: Member) => ({
  type: FETCH_ONE_MEMBER,
  member
});
