import * as React from "react";
import { connect } from "react-redux";
import {
  Button,
  TableHead,
  TableTitle,
  TableRow,
  TableRowName,
  TableRowEmail,
  TableRowTeam,
  Modal,
  ModalTitle,
  FormSegment,
  Input,
  ErrorText,
  Spinner,
  Container,
  AppText
} from "../UIElements";
import styled from "styled-components";
import { Member, Team } from "../../types";
import { AppActions } from "../../types/actions";
import { Dispatch, bindActionCreators } from "redux";
import { removeMember, addMember } from "../../actions/member";
import { AppState } from "../../store/configureStore";
// import { getEditTeam } from "../../selectors/getEditTeam";
import {
  Formik,
  FormikProps,
  Form,
  Field,
  FieldProps,
  ErrorMessage
} from "formik";
import { setError } from "../../firebase/error";
import AuthError from "../Segments/AuthError";
import { getTeam } from "../../selectors/getTeam";
import { getTeamAttributes } from "../../selectors/getTeamAttributes";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { editTeam } from "../../actions/team";
import AddMemberToTeamModal from "../Segments/Modals/AddMemberToTeamModal";
import memberReducer from "../../reducers/memberReducer";
import ChangeNameModal from "../Segments/Modals/ChangeNameModal";
import {
  removeSwimmerTeamThunk,
  removeCoachTeamThunk
} from "../../api/teamMember";
import TrashIcon from "../UIElements/TrashIcon";

interface EditTeamProps {
  team_id: any;
}
interface EditTeamState {
  showModal: boolean;
  showChangeNameModal: boolean;
}

type Props = RouteComponentProps<EditTeamProps> &
  LinkDispatchProps &
  LinkStateProps;

class EditTeam extends React.Component<Props, EditTeamState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      showModal: false,
      showChangeNameModal: false
    };
  }

  toggleModal = () => this.setState(prev => ({ showModal: !prev.showModal }));
  toggleChangeNameModal = () =>
    this.setState(prev => ({ showChangeNameModal: !prev.showChangeNameModal }));

  render() {
    console.log("EDIT TEAM PROPS", this.props);
    const team_id = this.props.match.params.team_id;
    console.log("TEAMID", typeof team_id);
    const teammatesArr = this.props.teammates[`${team_id}`]["0"].teammates;
    const teamName = this.props.teams.find(
      element => element.id === Number(team_id)
    );
    console.log("UPDATED TEAMNAME", teamName);
    return this.props.teammates &&
      this.props.teammates[`${team_id}`] &&
      this.props.teams ? (
      <Container>
        <div>
          <TeamDiv>
            <MyTeamText>{this.props.organization_name}&nbsp;/&nbsp;</MyTeamText>
            <TeamNameText>{teamName.name}</TeamNameText>
          </TeamDiv>
          <Spread style={{ marginBottom: "24px", marginTop: "13px" }}>
            <div />
            {/* <DeleteButton>Delete</DeleteButton> */}
            <div>
              <ChangeNameButton
                width="152px"
                onClick={this.toggleChangeNameModal}
              >
                Change Name
              </ChangeNameButton>
              <Button width="152px" onClick={this.toggleModal}>
                Add Member
              </Button>
            </div>
          </Spread>
          <TableHead>
            <Horizontal>
              {/* <Checkbox style={{ backgroundColor: "" }} /> */}
              <TableTitle>Name</TableTitle>
            </Horizontal>
            <TableTitle />
          </TableHead>
          <div>
            {teammatesArr.map((member: any) => {
              console.log("TEAM ID EDIT TEAM", team_id);
              return (
                <TableRow key={member.id}>
                  <div>
                    {member.type === "coach" ? (
                      <DeleteButton
                        style={{
                          position: "relative",
                          right: "30px",
                          top: "20px"
                        }}
                        onClick={() =>
                          this.props.removeCoachFromTeam(member.id, team_id)
                        }
                      >
                        <TrashIcon />
                      </DeleteButton>
                    ) : (
                      <DeleteButton
                        style={{
                          position: "relative",
                          right: "30px",
                          top: "20px"
                        }}
                        onClick={() =>
                          this.props.removeSwimmerFromTeam(
                            member.external_swimmer_id,
                            team_id
                          )
                        }
                      >
                        <TrashIcon />
                      </DeleteButton>
                    )}
                    <TableRowName
                      style={{ position: "relative", bottom: "15px" }}
                    >{`${member.first_name} ${member.last_name}`}</TableRowName>
                    <TableRowEmail
                      style={{ position: "relative", bottom: "15px" }}
                    >
                      {member.email}
                    </TableRowEmail>
                  </div>
                  <TableRowTeam>{member.type}</TableRowTeam>
                </TableRow>
              );
            })}
          </div>
        </div>
        <AddMemberToTeamModal
          //TODO NOTE
          team_id={this.props.match.params.team_id}
          toggleModal={this.toggleModal}
          showModal={this.state.showModal}
        />
        <ChangeNameModal
          team={this.props.match.params.team_id}
          toggleModal={this.toggleChangeNameModal}
          showModal={this.state.showChangeNameModal}
        />
      </Container>
    ) : (
      <Spinner />
    );
  }
}

const Grid = styled.div`
  display: grid;
  grid-template-rows: min-content 92px auto;
`;

const Spread = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Horizontal = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const DeleteButton = styled(Button)`
  height: 30px;
  width: 0px;
  border-radius: 1px;
  border: solid 0px rgba(143, 144, 146, 0.2);
  background-color: #f4f5f5;
  color: #fa4374;
  opacity: 0.5;
`;

const ChangeNameButton = styled(Button)`
  background-color: #f4f5f5;
  color: #09397f;
  margin-right: 16px;
`;

const MyTeamText = styled(AppText)`
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.2px;
  color: #09397f;
`;

const TeamNameText = styled(MyTeamText)`
  color: #8f9092;
`;

const TeamDiv = styled.div`
  display: flex;
  flex-direction: row;
`;

interface LinkDispatchProps {
  addMember: (member: Member) => void;
  removeMember: (member_id: string) => void;
  editTeam: (team: Team) => void;
  removeSwimmerFromTeam: (member_id: any, team_id: any) => void;
  removeCoachFromTeam: (member_id: any, team_id: any) => void;
}

const mapDispatchToProps = (
  dispatch: Dispatch<AppActions>
): LinkDispatchProps => ({
  addMember: bindActionCreators(addMember, dispatch),
  removeMember: bindActionCreators(removeMember, dispatch),
  editTeam: bindActionCreators(editTeam, dispatch),
  removeSwimmerFromTeam: bindActionCreators(removeSwimmerTeamThunk, dispatch),
  removeCoachFromTeam: bindActionCreators(removeCoachTeamThunk, dispatch)
});

interface LinkStateProps {
  members: Member[];
  team: Team;
  organization_name: string;
  teammates: any;
  teams: any;
  teststate: any;
}

// TODO: Switch isAuthorized back to real check
const mapStateToProps = (state: AppState, ownProps: Props): LinkStateProps => ({
  // isAuthorized: state.auth.role === "Head Coach"
  members: getTeam(
    ownProps.match.params.team_id,
    state.teamMembers,
    state.members
  ),
  team: getTeamAttributes(ownProps.match.params.team_id, state.teams),
  organization_name: state.organizations.name,
  teammates: state.teamMates,
  teams: state.teams,
  teststate: state.organizations
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(EditTeam));
