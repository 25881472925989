import * as React from "react";
import { connect } from "react-redux";
import {
  AuthContainer,
  ErrorText,
  Input,
  Button,
  AppText,
  FormSegment,
  Or,
  Center,
  Spinner,
  AppTitle,
  Card
} from "../../UIElements";
import {
  Formik,
  FormikProps,
  Form,
  Field,
  FieldProps,
  ErrorMessage
} from "formik";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { setError } from "../../../firebase/error";
import { login, editUser } from "../../../actions/auth";
import styled from "styled-components";
import { User } from "../../../types";
import { fetchUser } from "../../../actions/user";
import { Dispatch, bindActionCreators } from "redux";
import { AppActions } from "../../../types/actions";
import AuthError from "../../Segments/AuthError";
import {
  LinkText,
  LightText,
  AuthTitle,
  CardTitle,
  FormFieldLabel
} from "../../UIElements/Text";
import { AppState } from "../../../store/configureStore";
import { fakeUser } from "../../../static/user";
import { editUserThunk } from "../../../api/auth";

// QUESTION: Do we need to check for validation parameters for name, such as special characters?
// QUESTION: Do we need to check for validation parameters for password, such as special characters?

const schema = Yup.object().shape({
  email: Yup.string()
    .email("* Invalid email")
    .required("* Email is required"),
  name: Yup.string()
    .required("* Name is required")
    .max(30, "* Name must be under 30 characters"),
  password: Yup.string()
    // .required("* Please specify a password")
    .notRequired()
    .min(8, "* Must be at least 8 characters long")
    .max(50, "* Too Long!")
});

interface BasicInformationProps {
  name: string;
  email: string;
  password: string;
  user: object;
}

interface BasicInformationState {}

type Props = BasicInformationProps & LinkDispatchProps & LinkStateProps;

interface MyFormikProps {
  email: string;
  name?: any;
  password: string;
  user: any;
  first_name: string;
  last_name: string;
}

class BasicInformation extends React.Component<Props, BasicInformationState> {
  componentDidMount = () => {
    // this.props.fetchUser(fakeUser);
  };
  render(): JSX.Element {
    console.log("USER INFO------->", this.props.user);
    return (
      <Card padding="24px 24px 32px 24px">
        <Formik
          initialValues={{
            email: this.props.user.email,
            password: "",
            first_name: `${this.props.user.first_name}`,
            last_name: ` ${this.props.user.last_name}`,
            name:
              `${this.props.user.first_name}` + ` ${this.props.user.last_name}`,
            user: {}
          }}
          validationSchema={schema}
          onSubmit={async (values, { setFieldError, setStatus }) => {
            const { first_name, last_name, name, email, password } = values;
            console.log("values", values);
            const firstName = values.name.split(" ")["0"];
            const lastName = values.name.split(" ")["1"];
            setStatus({ error: "", loading: true });
            try {
              this.props.editSelf({
                user_id: this.props.user.id,
                first_name:
                  firstName !== this.props.user.first_name
                    ? firstName
                    : undefined,
                last_name:
                  lastName !== this.props.user.last_name ? lastName : undefined,
                email: email !== this.props.user.email ? email : undefined,
                password: password ? password : undefined
              });
            } catch (e) {
              console.log("login error: ", e);
              setStatus({ loading: false });
              return setError(e.code, setFieldError, setStatus);
            }
            console.log("no error");
            // If login was successful, send a request to the server to get
            // user token. Then dispatch user reducer and auth reducer
            // const token = auth!.currentUser!.uid;
            // const loginData = await loginAPI(token, email);

            // console.log("loginData: ", loginData);
            // if (loginData.error) {
            //   return setStatus({ error: loginData.error, loading: false });
            // }

            // this.props.fetchUser({
            //   ...loginData,
            //   user_token: loginData.user_token
            // });
            // setStatus({ loading: false });
            // this.props.login(loginData.user_token);
            // TODO: Replace this with the real token once the backend is hooked up.
            // this.props.login("supertoken");
          }}
        >
          {({
            status,
            touched,
            errors,
            setFieldTouched
          }: FormikProps<MyFormikProps>) => {
            return (
              <Form>
                <Grid>
                  <CardTitle
                    style={{ gridRow: "1 / span 1", gridColumn: "1 / span 1" }}
                  >
                    Basic Information
                  </CardTitle>
                  <div
                    style={{ gridRow: "2 / span 1", gridColumn: "1 / span 1" }}
                  >
                    <Field
                      name="name"
                      render={({
                        field,
                        form: { errors, touched }
                      }: FieldProps<MyFormikProps>) => {
                        const error = !!errors.name && !!touched.name;
                        return (
                          <FormSegment>
                            <FormFieldLabel>Name</FormFieldLabel>
                            <Input
                              {...field}
                              error={error}
                              placeholder="Enter your name"
                              inputWidth="228px"
                              onInput={() =>
                                setFieldTouched("organizationName", true)
                              }
                            />
                            <ErrorMessage
                              name={field.name}
                              component={ErrorText}
                            />
                          </FormSegment>
                        );
                      }}
                    />
                  </div>
                  <div
                    style={{ gridRow: "2 / span 1", gridColumn: "2 / span 1" }}
                  >
                    <Field
                      name="email"
                      render={({
                        field,
                        form: { errors, touched }
                      }: FieldProps<MyFormikProps>) => {
                        const error = !!errors.email && !!touched.email;
                        return (
                          <FormSegment>
                            <FormFieldLabel>EMAIL</FormFieldLabel>
                            <Input
                              {...field}
                              error={error}
                              placeholder="Enter your email"
                              inputWidth="228px"
                              onInput={() =>
                                setFieldTouched("organizationName", true)
                              }
                            />
                            <ErrorMessage
                              name={field.name}
                              component={ErrorText}
                            />
                          </FormSegment>
                        );
                      }}
                    />
                  </div>
                  <div
                    style={{ gridRow: "2 / span 1", gridColumn: "3 / span 1" }}
                  >
                    <Field
                      name="password"
                      render={({
                        field,
                        form: { errors, touched }
                      }: FieldProps<MyFormikProps>) => {
                        const error = !!errors.password && !!touched.password;
                        field.name;
                        return (
                          <FormSegment>
                            <FormFieldLabel>PASSWORD</FormFieldLabel>
                            <Input
                              {...field}
                              error={error}
                              type="password"
                              placeholder="Update your password"
                              inputWidth="228px"
                              onInput={() =>
                                setFieldTouched("organizationName", true)
                              }
                            />
                            <ErrorMessage
                              name={field.name}
                              component={ErrorText}
                            />
                          </FormSegment>
                        );
                      }}
                    />
                  </div>
                  <div
                    style={{
                      gridRow: "3 / span 1",
                      gridColumn: "3 / span 1",
                      justifySelf: "right"
                    }}
                  >
                    {!this.props.user ? (
                      <Spinner />
                    ) : (
                      <Button
                        // disabled={!Object.keys(errors).length}
                        type="submit"
                        width="152px"
                      >
                        Update
                      </Button>
                    )}
                  </div>
                  <div
                    style={{ gridRow: "3 / span 1", gridColumn: " 4/ span 1" }}
                  >
                    <AuthError status={status} />
                  </div>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </Card>
    );
  }
}

const Grid = styled.div`
  display: grid;
  grid-template-rows: 49px 112px 44px min-content;
  grid-template-columns: 238px 238px 238px;
  /* padding: 24px 24px 32px 24px; */
`;

interface LinkDispatchProps {
  // login: (token: string) => void;
  fetchUser: (user: User) => void;
  editSelf: ({
    user_id,
    first_name,
    last_name,
    email,
    password
  }: {
    user_id: number;
    first_name?: string;
    last_name?: string;
    email?: string;
    password?: string;
  }) => void;
}

const mapDispatchToProps = (
  dispatch: Dispatch<AppActions>
): LinkDispatchProps => ({
  // login: bindActionCreators(login, dispatch),
  fetchUser: bindActionCreators(fetchUser, dispatch),
  editSelf: bindActionCreators(editUserThunk, dispatch)
});

interface LinkStateProps {
  email: string;
  name: string;
  password: string;
  user: any;
}

const mapStateToProps = (state: AppState): LinkStateProps => ({
  email: state.user.user_email,
  password: state.user.user_password,
  name: `${state.user.user_first_name} ${state.user.user_last_name}`,
  user: state.auth.user
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BasicInformation);
