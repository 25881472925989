import { create } from "apisauce";
import { getAPIURL } from "../utils/variables";

export const api = create({
  // baseURL: "http://35.172.133.159/public/api",
  baseURL: getAPIURL(),
  headers: {
    // "Access-Control-Allow-Origin": "test",
    // Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "application/json"
  }
});

// const api = create({
//   baseURL: "https://stage.phlex.datech.dev/scripts",
//   headers: {
//     // "Access-Control-Allow-Origin": "*",
//     // Authorization: `Bearer ${token}`,
//     "Content-Type": "application/json",
//     Accept: "application/json"
//   }
// });
