import * as React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch, compose } from "redux";
import onClickOutside, {
  InjectedOnClickOutProps,
  HandleClickOutside
} from "react-onclickoutside";
import { AppText } from "../UIElements";
import { AppActions } from "../../types/actions";
import { logout } from "../../actions/auth";

interface DropdownMenuProps {
  toggleMenu: () => void;
  handleClickOutside: () => void;
}

type Props = DropdownMenuProps &
  LinkDispatchProps &
  InjectedOnClickOutProps &
  HandleClickOutside<any>;

class DropdownMenu extends React.Component<Props, any> {
  logout = () => {
    console.log("logout: ");
    this.props.logout();
    this.props.toggleMenu();
  };

  render() {
    return (
      <Container>
        <ArrowDiv>
          <UpArrow />
        </ArrowDiv>
        <Div>
          <Link
            to="/profile"
            onClick={this.props.toggleMenu}
            style={{ textDecoration: "none" }}
          >
            <Item>
              <ItemText>My Account</ItemText>
            </Item>
          </Link>
          <Link
            to="/team"
            style={{ textDecoration: "none" }}
            onClick={this.props.toggleMenu}
          >
            <Item>
              <ItemText>Manage Team</ItemText>
            </Item>
          </Link>
          <Item onClick={this.logout}>
            <ItemText style={{ color: "#fa4374" }}>Log out</ItemText>
          </Item>
        </Div>
      </Container>
    );
  }
}

const ArrowDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const Container = styled.div`
  width: 135px;
  position: absolute;
  left: -56px;
  top: 25px;
  z-index: 1;
`;

const Div = styled.div`
  border: solid 1px #f1f1f1;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.04);
`;

const UpArrow = styled.div`
  border-color: #fff transparent;
  border-style: solid;
  border-width: 0px 10px 10px 10px;
  height: 0px;
  width: 0px;
`;

const Item = styled.div`
  background-color: #fff;
  height: 40px;
  width: 100%;
  border-bottom-color: #f4f5f5;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  display: flex;
  align-items: center;
  :hover {
    cursor: pointer;
  }
`;

const ItemText = styled(AppText)`
  font-size: 16px;
  color: #1f2125;
  padding-left: 17px;
  text-decoration: none;
`;

interface LinkDispatchProps {
  logout: () => void;
}

const mapDispatchToProps = (
  dispatch: Dispatch<AppActions>
): LinkDispatchProps => ({
  logout: bindActionCreators(logout, dispatch)
});

export default connect(
  null,
  mapDispatchToProps
)(onClickOutside(DropdownMenu));
