import { CoachActionTypes } from "../types/actions";
import { Coach } from "../types";

// * Will add type property to account for swimmer / coach / Headcoach

const coachReducerDefaultState: Coach[] = [];

// This reducer holds user information that is returned from login/signup
// post requests

const coachReducer = (
  state = coachReducerDefaultState,
  action: CoachActionTypes
): Coach[] => {
  switch (action.type) {
    case "FETCH_COACHES":
      return action.coaches;
    case "LOGOUT":
      return coachReducerDefaultState;
    default:
      return state;
  }
};

export default coachReducer;
