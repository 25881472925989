import React from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import Header from "../Components/Segments/Header";
import { AppState } from "../store/configureStore";
import { Background } from "../Components/UIElements/Background";

// PrivateRoute
// See AppRouter for more information

interface PrivateRouteProps {
  component: any;
  path: string;
  exact?: boolean;
}

type Props = PrivateRouteProps & LinkStateProps;

export const PrivateRoute = ({
  isAuthenticated,
  component: Component,
  ...rest // sets the rest of the parameters to the rest variable
}: Props) => (
  <Route
    {...rest}
    component={(props: any) =>
      isAuthenticated ? (
        <Background backgroundColor="#FAFAFA">
          {/* <Header /> */}
          <Component {...props} />
        </Background>
      ) : (
        <Redirect to="/signin" />
      )
    }
  />
);

interface LinkStateProps {
  isAuthenticated: boolean;
}
// isAuthenticated is true if user_token exists in redux
const mapStateToProps = (state: AppState): LinkStateProps => ({
  isAuthenticated: !!state.auth.token
});

export default connect(mapStateToProps)(PrivateRoute);
