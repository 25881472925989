import * as React from "react";
import { connect } from "react-redux";
import {
  Button,
  TableHead,
  TableTitle,
  TableRow,
  TableRowName,
  TableRowDelete,
  TableRowEmail,
  TableRowTeam,
  Modal,
  ModalTitle,
  FormSegment,
  Input,
  ErrorText,
  Spinner
} from "../UIElements";
import styled from "styled-components";
import { Member, Organization, Swimmer } from "../../types";
import { AppActions } from "../../types/actions";
import { Dispatch, bindActionCreators } from "redux";
import * as Yup from "yup";
import { removeMember, addMember } from "../../actions/member";
import { AppState } from "../../store/configureStore";
import { getAllSwimmers } from "../../selectors/getAllSwimmers";
import {
  Formik,
  FormikProps,
  Form,
  Field,
  FieldProps,
  ErrorMessage
} from "formik";
import {
  fetchMembersThunk,
  removeMemberThunk,
  addMemberThunk
} from "../../api/member";
import { setError } from "../../firebase/error";
import AuthError from "./AuthError";
import { fetchSwimmersThunk, deleteSwimmerThunk } from "../../api/swimmers";
import TrashIcon from "../UIElements/TrashIcon";
import { errorSwimmerInvite } from "../../helperfunctions/toastFunctions";
import { ENGINE_METHOD_DIGESTS } from "constants";

// TODO: Need to lookup Yup email array validation

const schema = Yup.object().shape({
  email: Yup.string()
    .email("* Not a valid email address")
    .max(100, "* Email cannot be more than 100 characters")
    .required("* Email is required")
});

interface AllSwimmersProps {}
interface AllSwimmersState {
  showModal: boolean;
  inviteError: string;
}

interface MyFormikProps {
  email: string;
}

type Props = AllSwimmersProps & LinkDispatchProps & LinkStateProps;

class AllSwimmers extends React.Component<Props, AllSwimmersState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      showModal: false,
      inviteError: ""
    };
  }

  componentDidMount = () => {
    this.props.fetchSwimmers();
  };
  toggleModal = () => this.setState(prev => ({ showModal: !prev.showModal }));

  render() {
    console.log("ALL SWIMMER PROPS", this.props.swimMembers);
    return (
      <div>
        <Spread style={{ marginBottom: "24px", marginTop: "13px" }}>
          <div />
          {/* <DeleteButton />
            Delete
          </DeleteButton> */}
          <Button width="152px" onClick={this.toggleModal}>
            Add Member
          </Button>
        </Spread>
        <TableHead>
          {/* <DeleteButton>
            <TrashIcon />
          </DeleteButton> */}
          <Horizontal>
            <TableTitle>Name</TableTitle>
            {/* <Checkbox style={{ backgroundColor: "" }} /> */}
          </Horizontal>
          <TableTitle>Team</TableTitle>
        </TableHead>
        <div>
          {this.props.swimMembers.map((swimmer: any) => {
            return (
              <TableRow key={swimmer.id}>
                <div>
                  <DeleteButton
                    style={{
                      position: "relative",
                      right: "30px",
                      top: "20px"
                    }}
                    onClick={() =>
                      this.props.deleteSwimmer(swimmer.external_swimmer_id)
                    }
                  >
                    <TrashIcon />
                  </DeleteButton>
                  <TableRowName
                    style={{ position: "relative", bottom: "15px" }}
                  >{`${swimmer.first_name} ${swimmer.last_name}`}</TableRowName>
                  <TableRowEmail
                    style={{ position: "relative", bottom: "15px" }}
                  >
                    {swimmer.email}
                  </TableRowEmail>
                </div>
                <TableRowTeam>{swimmer.id}</TableRowTeam>
              </TableRow>
            );
          })}
        </div>
        <Modal
          showModal={this.state.showModal}
          toggleModal={this.toggleModal}
          label="Invite Member"
        >
          <Formik
            validateOnChange={true}
            validateOnBlur={false}
            initialValues={{
              email: "",
              first_name: "",
              last_name: ""
            }}
            validationSchema={schema}
            onSubmit={async (values, { setFieldError, setStatus }) => {
              const { email, first_name, last_name } = values;
              console.log("FORM VALUES", values);
              setStatus({ error: "", loading: true });
              try {
                const response = await this.props.inviteSwimmer(
                  email,
                  first_name,
                  last_name
                );
                console.log("invite response", response);
                if (typeof response === "string") {
                  this.setState({
                    inviteError: response
                  });
                  return;
                }
              } catch (e) {
                console.log("login error: ", e);
                setStatus({ loading: false });
                return setError(e.code, setFieldError, setStatus);
              }
              console.log("no error");
              setStatus({ loading: false });
              // TODO: Replace this with the real token once the backend is hooked up.
              this.toggleModal();
            }}
          >
            {({
              status,
              touched,
              errors,
              setFieldTouched
            }: FormikProps<MyFormikProps>) => {
              return (
                <Form>
                  <ModalGrid>
                    <ModalTitle>Invite Member</ModalTitle>
                    <Field
                      name="email"
                      render={({
                        field,
                        form: { errors, touched }
                      }: FieldProps<MyFormikProps>) => {
                        // field.onChange(() => setStatus({ canChange: true }));
                        const error = !!errors.email && !!touched.email;
                        return (
                          <FormSegment>
                            <Input
                              {...field}
                              error={error}
                              inputWidth="407px"
                              // TODO: Need to allow multiple emails separated by commas
                              placeholder="Enter email address"
                              onInput={() => setFieldTouched("email", true)}
                            />
                            <ErrorMessage
                              name={field.name}
                              component={ErrorText}
                            />
                          </FormSegment>
                        );
                      }}
                    />
                    <Field
                      name="first_name"
                      render={({
                        field,
                        form: { errors, touched }
                      }: FieldProps<MyFormikProps>) => {
                        // field.onChange(() => setStatus({ canChange: true }));
                        const error = !!errors.email && !!touched.email;
                        return (
                          <FormSegment>
                            <Input
                              {...field}
                              error={error}
                              inputWidth="407px"
                              placeholder="First Name"
                              onInput={() =>
                                setFieldTouched("first_name", true)
                              }
                            />
                            <ErrorMessage
                              name={field.name}
                              component={ErrorText}
                            />
                          </FormSegment>
                        );
                      }}
                    />
                    <div style={{ marginTop: "30px" }} />
                    <Field
                      name="last_name"
                      render={({
                        field,
                        form: { errors, touched }
                      }: FieldProps<MyFormikProps>) => {
                        // field.onChange(() => setStatus({ canChange: true }));
                        const error = !!errors.email && !!touched.email;
                        return (
                          <FormSegment>
                            <Input
                              {...field}
                              error={error}
                              inputWidth="407px"
                              placeholder="Last Name"
                              onInput={() => setFieldTouched("last_name", true)}
                            />

                            <ErrorMessage
                              name={field.name}
                              component={ErrorText}
                            />
                          </FormSegment>
                        );
                      }}
                    />

                    <End>
                      {this.state.inviteError && (
                        <AuthError
                          status={status}
                          error={this.state.inviteError}
                        />
                      )}
                      <TableTitle
                        style={{ marginTop: "30px" }}
                        clickable
                        onClick={this.toggleModal}
                      >
                        Cancel
                      </TableTitle>
                      <div
                        style={{ gridRow: "3 / span 1", marginLeft: "32px" }}
                      >
                        {/* {status && status.loading ? (
                          <Spinner />
                        ) : ( */}
                        <Button
                          style={{ marginTop: "30px" }}
                          width="152px"
                          disabled={
                            !(
                              Object.keys(touched).length &&
                              !Object.keys(errors).length
                            )
                          }
                          type="submit"
                        >
                          Send
                        </Button>
                        {/* )} */}
                      </div>
                    </End>
                    <div style={{ gridRow: "4 / span 1" }}>
                      <AuthError status={status} />
                    </div>
                  </ModalGrid>
                </Form>
              );
            }}
          </Formik>
        </Modal>
      </div>
    );
  }
}

const Spread = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Horizontal = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const DeleteButton = styled(Button)`
  height: 30px;
  width: 0px;
  border-radius: 0px;
  border: solid 0px rgba(143, 144, 146, 0.2);
  background-color: #f4f5f5;
  color: #fa4374;
  opacity: 0.5;
`;

const ModalGrid = styled.div`
  display: grid;
  grid-template-rows: 60px 70px 40px min-content;
  padding: 32px 46px 46px 46px;
`;

const End = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

interface LinkDispatchProps {
  addMember: (member: Member) => void;
  removeMember: (member_id: string) => void;
  fetchSwimmers: () => void;
  deleteSwimmer: (swimmer_id: number) => void;
  inviteSwimmer: (email: string, first_name: string, last_name: string) => void;
}

const mapDispatchToProps = (
  dispatch: Dispatch<AppActions>
): LinkDispatchProps => ({
  addMember: bindActionCreators(addMember, dispatch),
  removeMember: bindActionCreators(removeMember, dispatch),
  fetchSwimmers: bindActionCreators(fetchSwimmersThunk, dispatch),
  deleteSwimmer: bindActionCreators(deleteSwimmerThunk, dispatch),
  inviteSwimmer: bindActionCreators(addMemberThunk, dispatch)
});

interface LinkStateProps {
  swimmers: Member[];
  swimMembers: any;
  user: any;
}

// TODO: Switch isAuthorized back to real check
const mapStateToProps = (state: AppState): LinkStateProps => ({
  // isAuthorized: state.auth.role === "Head Coach"
  swimmers: getAllSwimmers(state.members),
  swimMembers: state.swimmers,
  user: state.auth
});

export default connect(mapStateToProps, mapDispatchToProps)(AllSwimmers);
