import { create } from "apisauce";
import { ThunkAction } from "redux-thunk";
import { AppState } from "../store/configureStore";
import { ActionCreator } from "redux";
import { MemberActionTypes } from "../types/actions";
import {
  // fetchUser,
  fetchOrganizations,
  editOrganization,
  addOrganization,
  fetchOrganizationUser
} from "../actions/organization";
import { Organization } from "../types";
import { api } from "../api/api";

// get organization
export const fetchOrganizationsThunk = () => async (dispatch, getState) => {
  const token = getState().auth.token;
  const organization_id = getState().auth.user.organization_id;
  try {
    const res = await api.get(
      `/organization?organization_id=${organization_id}`,
      {},
      { headers: { Authorization: `Bearer ${token}` } }
    );
    console.log("FETCH ORG RES.DATA", res.data);
    const action = fetchOrganizations(res.data as any);
    dispatch(action);
  } catch (err) {
    console.log(err);
  }
};

// edit organization

export const editOrganizationThunk = name => async (dispatch, getState) => {
  const token = getState().auth.token;
  const organization_id = getState().auth.user.organization_id;
  try {
    const res = await api.put(
      `/organization?name=${name}&organization_id=${organization_id}`,
      {},
      { headers: { Authorization: `Bearer ${token}` } }
    );
    console.log("EDITORG THUNK", res.data);
    const action = editOrganization(res.data as any);
    dispatch(action);
  } catch (err) {
    console.log(err);
  }
};
// add organization

export const addOrganizationThunk = name => async dispatch => {
  try {
    const formData = new FormData();
    formData.append("name", name);
    const res = await api.post("/organization", formData);
    const action = addOrganization(res.data as Organization);
    dispatch(action);
  } catch (err) {
    console.log(err);
  }
};

//find one coach/swimmer by email

export const fetchOrganizationUserThunk = email => async (
  dispatch,
  getState
) => {
  const token = getState().auth.token;
  const organization_id = getState().auth.user.organization_id;
  try {
    const res = await api.get(
      `/organization/user?email=${email}&organization_id=${organization_id}`,
      {},
      { headers: { Authorization: `Bearer ${token}` } }
    );
    const action = fetchOrganizationUser(res.data as Organization);
    dispatch(action);
  } catch (err) {
    console.log(err);
  }
};
