import * as React from "react";
import styled from "styled-components";

interface Props {
  cardWidth?: string;
  marginBottom?: string;
  padding?: string;
  backgroundColor?: string;
}

export const Card = styled.div<Props>`
  color: ${props => props.theme.TITLE_COLOR};
  font-family: ${props => props.theme.PRIMARY_FONT};
  width: ${(props: Props) => props.cardWidth};
  // height: 1014.7px;
  height: auto;
  background-color: ${(props: Props) => props.backgroundColor};
  margin-bottom: ${(props: Props) => props.marginBottom};
  padding: ${(props: Props) => props.padding};
  // box-shadow: 2.1px 2.1px 35px 0 rgba(209, 210, 218, 0.3);
  // border-radius: 6px;
`;

Card.defaultProps = {
  cardWidth: "auto",
  marginBottom: "30px",
  padding: "0 34px 34px 34px",
  backgroundColor: "#fff"
};

interface Props {
  type?: "auth" | "profile" | "offer";
}

export const AuthCard = styled(Card)`
  width: 900px;
  margin-bottom: 45.2px;
`;

export const ProfileCard = styled(Card)`
  width: 900px;
`;

export const OfferCard = styled(Card)`
  width: fit-content;
  padding: 12px;
`;

export const CardHead = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 75px;
`;
