import * as React from "react";
import { connect } from "react-redux";
import {
  AuthContainer,
  ErrorText,
  Input,
  Button,
  AppText,
  FormSegment,
  Or,
  Center,
  Spinner,
  AppTitle,
  Card
} from "../../UIElements";
import {
  Formik,
  FormikProps,
  Form,
  Field,
  FieldProps,
  ErrorMessage
} from "formik";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { setError } from "../../../firebase/error";
import { login } from "../../../actions/auth";
import styled from "styled-components";
import { User, EditUser } from "../../../types";
import { fetchUser, editUser } from "../../../actions/user";
import { Dispatch, bindActionCreators } from "redux";
import { AppActions } from "../../../types/actions";
import AuthError from "../../Segments/AuthError";
import {
  LinkText,
  LightText,
  AuthTitle,
  CardTitle,
  FormFieldLabel
} from "../../UIElements/Text";
import { AppState } from "../../../store/configureStore";
import {
  editOrganizationThunk,
  fetchOrganizationsThunk
} from "../../../api/organization";

// BLOCK: Cannot update backend update endpoint
// QUESTION: Do we need to check for validation parameters for organization_name, such as special characters?
// QUESTION: Do we need to check for validation parameters for password, such as special characters?

const signupSchema = Yup.object().shape({
  organization_name: Yup.string()
    .max(50, "* Organization name cannot be more than 50 characters")
    .required("* Email is required")
});

interface MyOrganizationProps {}

interface MyOrganizationState {}

type Props = MyOrganizationProps & LinkDispatchProps & LinkStateProps;

interface MyFormikProps {
  organization_name: string;
}

class MyOrganization extends React.Component<Props, MyOrganizationState> {
  componentDidMount = () => {
    this.props.fetchOrganization();
  };
  render(): JSX.Element | null {
    console.log("props: --->", this.props);
    if (!this.props.isAuthorized) {
      return null;
    }
    return this.props.organization_name.name ? (
      <Formik
        validateOnChange={true}
        validateOnBlur={false}
        initialValues={{
          organization_name: this.props.organization_name.name
        }}
        validationSchema={signupSchema}
        onSubmit={async (
          values,
          { setFieldError, setStatus, setFieldTouched }
        ) => {
          const { organization_name } = values;
          const { editOrganization /*, organization_id */ } = this.props;
          // TODO will need to get organization id from props.
          editOrganization(organization_name);
          setStatus({ error: "", loading: true });
          try {
          } catch (e) {
            console.log("login error: ", e);
            setStatus({ loading: false });
            return setError(e.code, setFieldError, setStatus);
          }
          console.log("no error");
          // If login was successful, send a request to the server to get
          // user token. Then dispatch user reducer and auth reducer
          // const token = auth!.currentUser!.uid;
          // const loginData = await loginAPI(token, email);

          // console.log("loginData: ", loginData);
          // if (loginData.error) {
          //   return setStatus({ error: loginData.error, loading: false });
          // }

          // this.props.fetchUser({
          //   ...loginData,
          //   user_token: loginData.user_token
          // });
          setStatus({ loading: false });

          // this.props.login(loginData.user_token);
          // TODO: Replace this with the real token once the backend is hooked up.
          // this.props.editUser({ organization_name });
          // TODO: Replace after hooked into backend
          setFieldTouched("organization_name", false);
        }}
      >
        {({
          status,
          touched,
          errors,
          setFieldTouched
        }: FormikProps<MyFormikProps>) => {
          return (
            <Form>
              <Card padding="24px 24px 32px 24px">
                <Grid>
                  <CardTitle style={{ gridRow: "1 / span 1" }}>
                    My Organization
                  </CardTitle>
                  <Field
                    name="organization_name"
                    render={({
                      field,
                      form: { errors, touched }
                    }: FieldProps<MyFormikProps>) => {
                      // field.onChange(() => setStatus({ canChange: true }));
                      const error =
                        !!errors.organization_name &&
                        !!touched.organization_name;
                      return (
                        <FormSegment>
                          <FormFieldLabel>ORGANIZATION NAME</FormFieldLabel>
                          <Input
                            {...field}
                            error={error}
                            inputWidth="305px"
                            placeholder="Enter your name"
                            onInput={() =>
                              setFieldTouched("organization_name", true)
                            }
                            // onChange={() => setStatus({ canUpdate: true })}
                          />
                          <ErrorMessage
                            name={field.name}
                            component={ErrorText}
                          />
                        </FormSegment>
                      );
                    }}
                  />
                  <div style={{ gridRow: "3 / span 1", justifySelf: "right" }}>
                    {status && status.loading ? (
                      <Spinner />
                    ) : (
                      <Button
                        width="152px"
                        disabled={
                          !(
                            Object.keys(touched).length &&
                            !Object.keys(errors).length
                          )
                        }
                        type="submit"
                      >
                        Update
                      </Button>
                    )}
                  </div>
                  <div style={{ gridRow: "4 / span 1" }}>
                    <AuthError status={status} />
                  </div>
                </Grid>
              </Card>
            </Form>
          );
        }}
      </Formik>
    ) : (
      <Spinner />
    );
  }
}

const Grid = styled.div`
  display: grid;
  grid-template-rows: 49px 112px 44px min-content;
  /* padding: 24px 24px 32px 24px; */
`;

interface LinkDispatchProps {
  editUser: (User: User) => void;
  fetchUser: (user: User) => void;
  editOrganization: (name: string) => void;
  fetchOrganization: () => void;
}

const mapDispatchToProps = (
  dispatch: Dispatch<AppActions>
): LinkDispatchProps => ({
  editUser: bindActionCreators(editUser, dispatch),
  fetchUser: bindActionCreators(fetchUser, dispatch),
  editOrganization: bindActionCreators(editOrganizationThunk, dispatch),
  fetchOrganization: bindActionCreators(fetchOrganizationsThunk, dispatch)
});

interface LinkStateProps {
  organization_name: any;
  isAuthorized: boolean;
}

// TODO: Switch back to real isauthorized check
const mapStateToProps = (state: AppState): LinkStateProps => ({
  organization_name: state.organizations,
  // isAuthorized: state.auth.role === "Head Coach"
  isAuthorized: true
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MyOrganization);
