import * as React from "react";
import { connect } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";
import { AppActions } from "../../../types/actions";
import { User, Member, Team, TeamMember } from "../../../types";
import { AppState } from "../../../store/configureStore";
import TeamSelector from "./TeamSelector";
import { fetchTeams } from "../../../actions/team";
import { fetchMemberTeams } from "../../../actions/teamMember";
import { teamMember } from "../../../static/teamMember";
import { teams } from "../../../static/teams";
import { fetchMembers } from "../../../actions/member";
import { fakeMembers } from "../../../static/members";
import styled from "styled-components";
import { fetchTeammatesThunk, fetchTeamsThunk } from "../../../api/teams";
import { Spinner } from "../../UIElements/Spinner";

export interface SwimmerSelectorProps {
  onSelectSwimmer: any;
  month: any;
  year: any;
  onMonthChange: any;
  fetchCurrentWorkout: any;
  getHRData: any;
  fetchWorkoutOnDateClick: any;
  currentSwimmerID: number;
}
export interface SwimmerSelectorState {
  temporaryState: object;
  selectedSwimmer: 0;
}

type Props = SwimmerSelectorProps & LinkStateProps & LinkDispatchProps;

class SwimmerSelector extends React.Component<Props, SwimmerSelectorState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedSwimmer: 0,
      temporaryState: {}
    };
  }

  componentDidMount = () => {
    this.props.fetchTeamMembers(teamMember);
    // this.props.fetchTeams(teams);
    // this.props.fetchMembers(fakeMembers);
    this.props.fetchAllTeams();
  };

  getInvidivialState = state => {
    this.setState({
      temporaryState: state
    });
    this.props.onSelectSwimmer(this.state.temporaryState);
  };
  render() {
    const { teams } = this.props;
    // console.log("SWIMMERSELECTOR STATE ---->", this.props);
    //this.props.teams
    console.log("teams:?", this.props);
    return this.props.teams.length ? (
      <Div>
        {teams.map((team, index) => (
          <TeamSelector
            currentSwimmerID={this.props.currentSwimmerID}
            index={index}
            month={this.props.month}
            year={this.props.year}
            key={team.id}
            name={team.name}
            id={team.id}
            onSelectSwimmer={this.getInvidivialState}
            onMonthChange={this.props.onMonthChange}
            allTeammates={team.teammates}
            firstTeam={this.props.teams["0"]}
            fetchCurrentWorkout={this.props.fetchCurrentWorkout}
            getHRData={this.props.getHRData}
            fetchWorkoutOnDateClick={this.props.fetchWorkoutOnDateClick}
          />
        ))}
      </Div>
    ) : (
      <div />
    );
  }
}

const Div = styled.div``;

interface LinkDispatchProps {
  fetchTeams: (teams: Team[]) => void;
  fetchTeamMembers: (teamMembers: TeamMember[]) => void;
  fetchMembers: (members: Member[]) => void;
  fetchAllTeams: () => void;
  fetchAllTeamMembers: (team_id: any) => void;
}

const mapDispatchToProps = (
  dispatch: Dispatch<AppActions>
): LinkDispatchProps => ({
  fetchTeams: bindActionCreators(fetchTeams, dispatch),
  fetchTeamMembers: bindActionCreators(fetchMemberTeams, dispatch),
  fetchMembers: bindActionCreators(fetchMembers, dispatch),
  fetchAllTeams: bindActionCreators(fetchTeamsThunk, dispatch),
  fetchAllTeamMembers: bindActionCreators(fetchTeammatesThunk, dispatch)
});

interface LinkStateProps {
  // teams: Team[];
  teams: any;
}

const mapStateToProps = (state: AppState): LinkStateProps => ({
  // teams: state.teams,
  teams: state.teams
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SwimmerSelector);
