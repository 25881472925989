import { TeammatesActionTypes } from "../types/actions";
import { TeamMates } from "../types";

const teammateReducerDefaultState: TeamMates = {
  teammates: []
};

const teammateReducer = (
  state = teammateReducerDefaultState,
  action: TeammatesActionTypes
): TeamMates => {
  switch (action.type) {
    case "FETCH_TEAMMATES":
      return {
        ...state,
        [action.teammates.team.id]: [...state.teammates, action.teammates]
      };
    case "ADD_COACH_TO_TEAM":
      return {
        ...state,
        [action.teammates.team_id]: {
          ...state[action.teammates.team_id],
          "0": {
            ...state[action.teammates.team_id]["0"],
            teammates: [
              ...state[action.teammates.team_id]["0"].teammates,
              action.teammates
            ]
          }
        }

        //user getState and dispatch that payload.
        // teammates: state.....teammates.push(action.payload)
      };

    case "ADD_SWIMMER_TO_TEAM":
      return {
        ...state,
        [action.teammates.team_id]: {
          ...state[action.teammates.team_id],
          "0": {
            ...state[action.teammates.team_id]["0"],
            teammates: [
              ...state[action.teammates.team_id]["0"].teammates,
              action.teammates
            ]
          }
        }
      };
    case "REMOVE_COACH_FROM_TEAM":
      return {
        ...state,
        [action.teammates.team_id]: {
          ...state[action.teammates.team_id],
          "0": {
            ...state[action.teammates.team_id]["0"],
            teammates: state[action.teammates.team_id]["0"].teammates.filter(
              team => team.id !== action.teammates.member_id
            )
          }
        }
      };
    // state.teammates.filter(
    //   (teammates: TeamMates) =>
    //     !(
    //       teammates.id !== action.teammates.id &&
    //       teammates.id !== action.teammates.id
    //     )
    // );
    case "REMOVE_SWIMMER_FROM_TEAM":
      return {
        ...state,
        [action.teammates.team_id]: {
          ...state[action.teammates.team_id],
          "0": {
            ...state[action.teammates.team_id]["0"],
            teammates: state[action.teammates.team_id]["0"].teammates.filter(
              team => team.external_swimmer_id !== action.teammates.member_id
            )
          }
        }
      };
    // state.teammates.filter(
    //   (teammates: TeamMates) =>
    //     !(
    //       teammates.external_swimmer_id !==
    //         action.teammates.external_swimmer_id &&
    //       teammates.external_swimmer_id !==
    //         action.teammates.external_swimmer_id
    //     )
    // );
    case "LOGOUT":
      return teammateReducerDefaultState;
    default:
      return state;
  }
};

export default teammateReducer;
