import {
  ADD_MEMBER_TO_TEAM,
  REMOVE_MEMBER_FROM_TEAM,
  FETCH_MEMBER_TEAMS,
  ADD_COACH_TO_TEAM,
  ADD_SWIMMER_TO_TEAM,
  REMOVE_COACH_FROM_TEAM,
  REMOVE_SWIMMER_FROM_TEAM
} from "../types/actions";
import { TeamMember } from "../types";

export const addMemberToTeam = (memberTeam: TeamMember) => ({
  type: ADD_MEMBER_TO_TEAM,
  memberTeam
});

export const removeMemberFromTeam = (memberTeam: TeamMember) => ({
  type: REMOVE_MEMBER_FROM_TEAM,
  memberTeam
});

export const fetchMemberTeams = (memberTeams: TeamMember[]) => ({
  type: FETCH_MEMBER_TEAMS,
  memberTeams
});

export const addCoachToTeam = (memberTeam: TeamMember) => ({
  type: ADD_COACH_TO_TEAM,
  memberTeam
});

export const removeCoachFromTeam = (memberTeam: TeamMember) => ({
  type: REMOVE_COACH_FROM_TEAM,
  memberTeam
});
export const addSwimmerToTeam = (memberTeam: TeamMember) => ({
  type: ADD_SWIMMER_TO_TEAM,
  memberTeam
});

export const removeSwimmerFromTeam = (memberTeam: TeamMember) => ({
  type: REMOVE_SWIMMER_FROM_TEAM,
  memberTeam
});
