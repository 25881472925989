import { create } from "apisauce";
import { ThunkAction } from "redux-thunk";
import { AppState } from "../store/configureStore";
import { ActionCreator } from "redux";
import { TeamMemberActionTypes } from "../types/actions";
import {
  addMemberToTeam,
  removeMemberFromTeam,
  fetchMemberTeams
} from "../actions/teamMember";
import {
  addCoachToTeam,
  addSwimmerToTeam,
  removeCoachFromTeam,
  removeSwimmerFromTeam
} from "../actions/teamMates";
import { TeamMember } from "../types";
import { api } from "../api/api";

//get all teammembers of particular team
export const fetchMemberTeamsThunk = team_id => async (dispatch, getState) => {
  const token = getState().auth.token;
  try {
    const res = await api.get<TeamMember[]>(
      `/team/get-teammates?team_id=${team_id}`,
      {},
      { headers: { Authorization: `Bearer ${token}` } }
    );
    const action = fetchMemberTeams(res.data as TeamMember[]);
    dispatch(action);
  } catch (err) {
    console.log(err);
  }
};

// add  swimmer
export const addSwimmerTeamThunk = (member_id, team_id) => async (
  dispatch,
  getState
) => {
  const token = getState().auth.token;
  const singleSwimmer = getState().swimmers.filter(
    obj => obj.external_swimmer_id === member_id
  )["0"];
  singleSwimmer["team_id"] = team_id;
  singleSwimmer["type"] = "swimmer";
  try {
    const formData = new FormData();
    formData.append("external_swimmer_id", member_id);
    formData.append("team_id", team_id);
    const res = await api.post("/team/add-swimmer", formData, {
      headers: { Authorization: `Bearer ${token}` }
    });
    const action = addSwimmerToTeam(singleSwimmer as any);
    dispatch(action);
  } catch (err) {
    console.log(err);
  }
};

// delete swimmer

export const removeSwimmerTeamThunk = (member_id, team_id) => async (
  dispatch,
  getState
) => {
  const token = getState().auth.token;
  try {
    await api.post(
      `/team/remove-swimmer?external_swimmer_id=${member_id}&team_id=${team_id}`,
      {},
      { headers: { Authorization: `Bearer ${token}` } }
    );
    console.log("REMOVE SWiMMER TEAM THUNK HAPPENED");
    const action = removeSwimmerFromTeam({
      member_id: member_id,
      team_id: team_id
    } as any);
    dispatch(action);
  } catch (err) {
    console.log(err);
  }
};

//add coach
export const addCoachTeamThunk = (member_id, team_id) => async (
  dispatch,
  getState
) => {
  const token = getState().auth.token;
  const singleCoach = getState().coaches.filter(obj => obj.id === member_id)[
    "0"
  ];
  singleCoach["team_id"] = team_id;
  singleCoach["type"] = "coach";
  console.log("addCoachTeamThunk", singleCoach);
  try {
    const formData = new FormData();
    formData.append("coach_id", member_id);
    formData.append("team_id", team_id);
    const res = await api.post("/team/add-coach", formData, {
      headers: { Authorization: `Bearer ${token}` }
    });
    const action = addCoachToTeam(singleCoach as any);
    dispatch(action);
  } catch (err) {
    console.log(err);
  }
};

//remove coach
export const removeCoachTeamThunk = (member_id, team_id) => async (
  dispatch,
  getState
) => {
  const token = getState().auth.token;

  try {
    await api.post(
      `/team/remove-coach?coach_id=${member_id}&team_id=${team_id}`,
      {},
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    );
    console.log("REMOVECOACHTHUNK MEMBERID", member_id);
    console.log("REMOVECOACHTHUNK TEAMID", team_id);
    const action = removeCoachFromTeam({
      member_id: member_id,
      team_id: team_id
    } as any);
    dispatch(action);
  } catch (err) {
    console.log(err);
  }
};
