import * as React from "react";
import { connect } from "react-redux";
import { Container, PageTitle } from "../UIElements";
import styled from "styled-components";
import { fetchUser } from "../../actions/user";
import { User } from "../../types";
import { Dispatch, bindActionCreators } from "redux";
import { AppActions } from "../../types/actions";
import { AppState } from "../../store/configureStore";
import AllSwimmers from "../Segments/AllSwimmers";
import Groups from "../Segments/Groups";
import { fetchCoachesThunk } from "../../api/coach";
import { fetchOrganizationsThunk } from "../../api/organization";

// * Also known as My Account page

interface ProfileProps {}
interface ProfileState {
  selectedTab: "All Swimmers" | "Groups";
  showModal: boolean;
}

type Props = ProfileProps & LinkStateProps & LinkDispatchProps;

class Profile extends React.Component<Props, ProfileState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      selectedTab: "All Swimmers",
      showModal: false
    };
  }
  componentDidMount = async () => {
    // const { user_token, user_id } = this.props;
    // const user = await fetchUserAPI(user_token as string, user_id as number);
    // if (user.error) {
    //   return console.log("failure to load user: ", user.error);
    // }
    // this.props.fetchUser(user);
    this.props.fetchCoaches(this.props.user.organization_id);
    this.props.fetchOrganization();
  };

  selectGroups = () => this.setState({ selectedTab: "Groups" });
  selectAllSwimmers = () => this.setState({ selectedTab: "All Swimmers" });

  // use border bottom to do underline affect

  render(): JSX.Element {
    const { selectedTab } = this.state;
    console.log("MANAGE TEAM PROPS", this.props);
    return (
      <Container>
        <Grid>
          <Heading>
            <TeamPageTitle
              onClick={this.selectAllSwimmers}
              style={{
                gridRow: "1 / span 1",
                borderBottom: `2px solid ${
                  selectedTab === "All Swimmers" ? "#09397f" : "#ccc"
                }`,
                color: `${
                  selectedTab === "All Swimmers" ? "#09397f" : "#d5d5d5"
                }`
              }}
            >
              All Swimmers
            </TeamPageTitle>
            <Underline width="60px" />

            <TeamPageTitle
              onClick={this.selectGroups}
              style={{
                borderBottom: `2px solid ${
                  selectedTab === "Groups" ? "#09397f" : "#ccc"
                }`,
                color: `${selectedTab === "Groups" ? "#09397f" : "#d5d5d5"}`
              }}
            >
              Groups
            </TeamPageTitle>
            <Underline width="100%" />
          </Heading>
          {selectedTab === "All Swimmers" ? <AllSwimmers /> : <Groups />}
        </Grid>
      </Container>
    );
  }
}

const Grid = styled.div`
  display: grid;
  grid-template-rows: min-content min-content auto;
  grid-gap: 17px;
  /* margin: 116px 161px 66px 161px; */
`;

const TeamPageTitle = styled(PageTitle)`
  :hover {
    cursor: pointer;
  }
  padding-bottom: 18px;
`;

const Heading = styled.div`
  display: flex;
  flex-direction: row;
  white-space: nowrap;
`;

interface UnderlineProps {
  width?: string;
}

const Underline = styled.div<UnderlineProps>`
  border-bottom-width: 2px;
  border-bottom-color: #ccc;
  border-bottom-style: solid;
  /* opacity: 0.8; */
  height: 40px;
  width: ${props => props.width};
  padding-bottom: 18px;
`;
Underline.defaultProps = {
  width: "60px"
};

interface LinkDispatchProps {
  fetchUser: (user: User) => void;
  fetchCoaches: (organization_id) => void;
  fetchOrganization: () => void;
}

interface LinkStateProps {
  user_token: string | undefined;
  user_id: number | null;
  coaches: any;
  user: any;
}

const mapDispatchToProps = (
  dispatch: Dispatch<AppActions>
): LinkDispatchProps => ({
  fetchUser: bindActionCreators(fetchUser, dispatch),
  fetchCoaches: bindActionCreators(fetchCoachesThunk, dispatch),
  fetchOrganization: bindActionCreators(fetchOrganizationsThunk, dispatch)
});

// isAuthenticated is true if user_token exists in redux
const mapStateToProps = (state: AppState): LinkStateProps => ({
  user_token: state.auth.access_token,
  user_id: state.user.user_id,
  coaches: state.coaches,
  user: state.auth.user
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Profile);
