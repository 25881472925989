// TODO: Use chart.js to make doughnut chart
import * as React from "react";
import {
  VictoryPie,
  VictoryPieProps,
  VictoryLabel,
  VictoryTooltip
} from "victory";
import styled from "styled-components";
import { Card, AppTitle, AppText, GraphBigText } from "../../UIElements";
import { secondsToMin } from "../../../helperfunctions/secondstominutes";
interface Data {
  x: string;
  y: number;
  percent?: any;
}

export interface DoughnutProps {
  data?: Data[];
  title?: string;
  legendType?: "distance" | "HRZone";
}

const DoughnutGraph: React.SFC<DoughnutProps & VictoryPieProps> = ({
  title,
  legendType,
  ...props
}) => (
  <Div>
    <Heading>{title}</Heading>
    <svg width={195.5} height={195.5}>
      <VictoryPie
        innerRadius={67}
        {...props}
        height={195.5}
        width={195.5}
        padding={0}
        labels={() => ""}
        standalone={false}
      />
    </svg>
    <FooterDiv>
      {legendType === "distance" ? (
        <DistanceFooter
          data={props.data as Data[]}
          colorScale={props.colorScale}
        />
      ) : (
        <HRZoneFooter
          data={props.data as Data[]}
          colorScale={props.colorScale}
        />
      )}
    </FooterDiv>
  </Div>
);

DoughnutGraph.defaultProps = {
  title: "DISTANCE",
  legendType: "distance"
};

interface FooterProps {
  data: Data[];
  colorScale: any;
}

const DistanceFooter: React.SFC<FooterProps> = ({ data, colorScale }) => (
  <>
    {data.map((datum, index) =>
      datum.y > 5 ? (
        <LegendItem key={datum.x}>
          <Circle color={colorScale[index]} />
          <DistanceLegendText>{datum.x}</DistanceLegendText>
          <DistanceValueText>{datum.y}</DistanceValueText>
        </LegendItem>
      ) : null
    )}
  </>
);

// QUESTION: What do the values in the legend represent?

const HRZoneFooter: React.SFC<FooterProps> = ({ data, colorScale }) => (
  <>
    {data.map((datum, index) =>
      datum.y > 5 ? (
        <LegendItem>
          <GraphBigText style={{ fontSize: "15px" }}>{`${secondsToMin(
            datum.y
          )}`}</GraphBigText>
          <Horizontal>
            <HRZoneBold>{datum.x}:</HRZoneBold>
            <HRZoneText>{`${datum.percent}%`}</HRZoneText>
          </Horizontal>
        </LegendItem>
      ) : null
    )}
  </>
);

type CircleProps = {
  color: string;
};

const Horizontal = styled.div`
  display: flex;
  flex-direction: row;
`;

const HRZoneText = styled(AppText)`
  font-size: 14px;
  letter-spacing: 0.2px;
  color: #1f2125;
  opacity: 0.6;
`;

const HRZoneBold = styled(AppText)`
  font-size: 14px;
  letter-spacing: 0.2px;
  color: #1f2125;
  white-space: nowrap;
`;

const Circle = styled.div<CircleProps>`
  border-radius: 50%;
  width: 6px;
  height: 6px;
  object-fit: contain;
  border: solid 3px ${props => props.color};
`;

const DistanceLegendText = styled(AppText)`
  font-size: 12px;
  letter-spacing: 0.1px;
  text-align: center;
  color: #1f2125;
`;

const DistanceValueText = styled(AppText)`
  opacity: 0.5;
  font-size: 13px;
  font-stretch: condensed;
  text-align: center;
  color: #1f2125;
`;

const FooterDiv = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
`;

const LegendItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 45px;
`;

interface CustomLabelProps {
  text: string;
}

class CustomLabel extends React.Component<CustomLabelProps> {
  render() {
    return (
      <g>
        <VictoryLabel text={this.props.text} />
        <VictoryTooltip
          {...this.props}
          x={200}
          y={250}
          text={`# ${this.props.text}`}
          orientation="top"
          pointerLength={0}
          cornerRadius={50}
          width={100}
          height={100}
          flyoutStyle={{ fill: "black" }}
        />
      </g>
    );
  }
}

const Div = styled(Card)`
  height: 352px;
  width: 444.5px;
  padding: 0;
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
`;

const Heading = styled(AppTitle)`
  opacity: 0.5;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  color: #1f2125;
`;

export { DoughnutGraph };
