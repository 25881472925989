import * as React from "react";
import { connect } from "react-redux";
import * as Yup from "yup";
import {
  Modal,
  ModalTitle,
  FormSegment,
  Input,
  ErrorText,
  TableTitle,
  Spinner,
  Button,
  Select
} from "../../UIElements";
import {
  Formik,
  FormikProps,
  Form,
  Field,
  FieldProps,
  ErrorMessage
} from "formik";
import { setError } from "../../../firebase/error";
import AuthError from "../AuthError";
import styled from "styled-components";
import { addMemberToTeam } from "../../../actions/teamMember";
import { TeamMember, Team, Member } from "../../../types";
import { Dispatch, bindActionCreators } from "redux";
import { AppActions } from "../../../types/actions";
import { editTeam } from "../../../actions/team";
import { AppState } from "../../../store/configureStore";
import { getMembersNotInTeam } from "../../../selectors/getMembersNotInTeam";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Option } from "react-select/lib/filters";
import {
  addSwimmerTeamThunk,
  addCoachTeamThunk
} from "../../../api/teamMember";
import { classNames } from "react-select/lib/utils";

const addMemberSchema = Yup.object().shape({
  name: Yup.string().required("* Email is required")
});

interface MyFormikProps {
  name: string;
  swimmer_id?: number | null;
  coach_id?: number | null;
}

interface AddMemberToTeamModalProps {
  showModal: boolean;
  toggleModal: () => void;
  team_id: number | undefined;
}

interface RouteProps {
  team_id: number | undefined;
}

type Props = AddMemberToTeamModalProps & LinkDispatchProps & LinkStateProps;

class AddMemberToTeamModal extends React.Component<Props, any> {
  includesSwimHelper = swim_id => {
    const teamArr = this.props.teammates[`${this.props.team_id}`]["0"]
      .teammates;
    for (let i = 0; i < teamArr.length; i++) {
      if (teamArr[i].external_swimmer_id === swim_id) {
        return true;
        break;
      }
    }
    return false;
  };
  includesCoachHelper = id => {
    const teamArr = this.props.teammates[`${this.props.team_id}`]["0"]
      .teammates;
    for (let i = 0; i < teamArr.length; i++) {
      if (teamArr[i].id === id) {
        return true;
        break;
      }
    }
    return false;
  };
  render() {
    console.log("members: ", this.props);
    console.log("includes or not", this.includesSwimHelper(50));

    return (
      <Modal
        showModal={this.props.showModal}
        toggleModal={this.props.toggleModal}
        label="Add Member to Team"
      >
        <Formik
          validateOnChange={true}
          validateOnBlur={false}
          initialValues={{
            name: "",
            swimmer_id: null,
            coach_id: null
          }}
          validationSchema={addMemberSchema}
          onSubmit={async (values, { setFieldError, setStatus }) => {
            const { name } = values;
            setStatus({ error: "", loading: true });
            console.log("SUBMIT VALUES", values);
            try {
              if (values.swimmer_id) {
                if (!this.includesSwimHelper(values.swimmer_id)) {
                  this.props.addSwimmer(values.swimmer_id, this.props.team_id);
                } else {
                  console.log("Swimmer already included");
                }
              }
              if (values.coach_id) {
                const teamArr = this.props.teammates[`${this.props.team_id}`][
                  "0"
                ].teammates;
                if (!this.includesCoachHelper(values.coach_id)) {
                  this.props.addCoach(values.coach_id, this.props.team_id);
                } else {
                  console.log("coach already included");
                }
              }
            } catch (e) {
              console.log("login error: ", e);
              setStatus({ loading: false });
              return setError(e.code, setFieldError, setStatus);
            }
            console.log("no error");
            setStatus({ loading: false });
            // TODO: Replace this with the real token once the backend is hooked up.
            this.props.toggleModal();
          }}
        >
          {({
            status,
            touched,
            errors,
            setFieldTouched
          }: FormikProps<MyFormikProps>) => {
            console.log("ADDMEMBER PROPS", this.props);
            const swimmers_select = this.props.allSwimmers.map(swimmer => ({
              label: `${swimmer.first_name} ${swimmer.last_name}`,
              value: swimmer.external_swimmer_id,
              swimmer_id: swimmer.external_swimmer_id
            }));
            const coaches_select = this.props.allCoaches.map(coach => ({
              label: `${coach.first_name} ${coach.last_name}`,
              value: coach.id,
              coach_id: coach.id
            }));
            const allMembersArr = coaches_select.concat(swimmers_select);
            // const allMembersArr = this.props.allCoaches.concat(
            //   this.props.allSwimmers
            // );
            console.log("ALL MEMBERS", allMembersArr);
            const teammatesArr = this.props.teammates[`${this.props.team_id}`][
              "0"
            ].teammates;
            return (
              <Form>
                <ModalGrid>
                  <ModalTitle>Add Member to Team</ModalTitle>
                  <Field
                    name="name"
                    render={({
                      field,
                      form,
                      form: { errors, touched }
                    }: FieldProps<MyFormikProps>) => {
                      // field.onChange(() => setStatus({ canChange: true }));
                      const error = !!errors.name && !!touched.name;
                      return (
                        <FormSegment>
                          <Select
                            {...field}
                            // inputWidth={192}
                            options={allMembersArr}
                            // isDisabled
                            placeholder="Search by full name, or email address"
                            form={form}
                            field={field}
                            onInput={() => setFieldTouched("name", true)}
                          />
                          {/* <Input
                            {...field}
                            error={error}
                            inputWidth="407px"
                            placeholder="Search by full name, or email address"
                            onInput={() => setFieldTouched("email", true)}
                          /> */}
                          <ErrorMessage
                            name={field.name}
                            component={ErrorText}
                          />
                        </FormSegment>
                      );
                    }}
                  />
                  <End>
                    <TableTitle clickable onClick={this.props.toggleModal}>
                      Cancel
                    </TableTitle>
                    <div style={{ gridRow: "3 / span 1", marginLeft: "32px" }}>
                      {status && status.loading ? (
                        <Spinner />
                      ) : (
                        <Button
                          width="152px"
                          disabled={
                            !(
                              Object.keys(touched).length &&
                              !Object.keys(errors).length
                            )
                          }
                          type="submit"
                        >
                          Send
                        </Button>
                      )}
                    </div>
                  </End>
                  <div style={{ gridRow: "4 / span 1" }}>
                    <AuthError status={status} />
                  </div>
                </ModalGrid>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

const End = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

const ModalGrid = styled.div`
  display: grid;
  grid-template-rows: 60px 70px 40px min-content;
  padding: 32px 46px 24px 46px;
`;

interface LinkDispatchProps {
  addMemberToTeam: (teamMember: TeamMember) => void;
  editTeam: (team: Team) => void;
  addSwimmer: (member_id: any, team_id: any) => void;
  addCoach: (member_id: any, team_id: any) => void;
}

interface LinkStateProps {
  // members: Option[];
  teammates: any;
  allSwimmers: any;
  allCoaches: any;
}

const mapDispatchToProps = (
  dispatch: Dispatch<AppActions>
): LinkDispatchProps => ({
  addMemberToTeam: bindActionCreators(addMemberToTeam, dispatch),
  editTeam: bindActionCreators(editTeam, dispatch),
  addSwimmer: bindActionCreators(addSwimmerTeamThunk, dispatch),
  addCoach: bindActionCreators(addCoachTeamThunk, dispatch)
});

// TODO: Create getMembersNotInTeam selector
const mapStateToProps = (
  state: AppState,
  ownProps: AddMemberToTeamModalProps
): LinkStateProps => ({
  // members: getMembersNotInTeam(
  //   ownProps.team_id,
  //   state.teamMembers,
  //   state.members
  // ).map((member: Member) => ({
  //   label: `${member.member_first_name} ${member.member_last_name}`,
  //   value: member.member_id,
  //   data: ""
  // }))
  teammates: state.teamMates,
  allSwimmers: state.swimmers,
  allCoaches: state.coaches
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddMemberToTeamModal);
