export const secondsToMin = time => {
  const number = Number(time);
  let h = Math.floor(number / 3600);
  let m = Math.floor((number % 3600) / 60);
  let s = Math.floor((number % 3600) % 60);

  let hDisplay = h > 0 ? h + (h == 1 ? " H, " : " H, ") : "";
  let mDisplay = m > 0 ? m + (m == 1 ? " MIN, " : " MINS, ") : "";
  let sDisplay = s > 0 ? s + (s == 1 ? " SEC" : " SEC") : "";
  return hDisplay + mDisplay + sDisplay;
};
