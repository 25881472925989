import { TeamMember } from "../types";

export const teamMember: TeamMember[] = [
  {
    team_member_id: "1",
    team_id: "1",
    member_id: "1"
  },
  {
    team_member_id: "2",
    team_id: "1",
    member_id: "2"
  },
  {
    team_member_id: "3",
    team_id: "1",
    member_id: "3"
  },
  {
    team_member_id: "4",
    team_id: "1",
    member_id: "4"
  },
  {
    team_member_id: "5",
    team_id: "2",
    member_id: "5"
  },
  {
    team_member_id: "6",
    team_id: "2",
    member_id: "6"
  },
  {
    team_member_id: "7",
    team_id: "2",
    member_id: "7"
  },
  {
    team_member_id: "8",
    team_id: "2",
    member_id: "8"
  },
  {
    team_member_id: "9",
    team_id: "2",
    member_id: "9"
  }
];
