import styled from "styled-components";

interface Props {
  backgroundColor?: string;
}

export const Background = styled.div<Props>`
  min-width: inherit;
  min-height: inherit;
  background-color: ${props => props.backgroundColor};
`;
Background.defaultProps = {
  backgroundColor: "#fff"
};
