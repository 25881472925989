import * as React from "react";
import { connect } from "react-redux";
import {
  AuthContainer,
  Input,
  ErrorText,
  Button,
  AppText,
  FormSegment,
  Center,
  Spinner,
  AppTitle,
  AuthTitle,
  LightText,
  LinkText
} from "../UIElements";
import * as Yup from "yup";
import {
  Formik,
  FormikProps,
  Form,
  Field,
  FieldProps,
  ErrorMessage
} from "formik";
import { Link, withRouter, RouteComponentProps } from "react-router-dom";
import { setError } from "../../firebase/error";
import styled from "styled-components";
import { User, Auth } from "../../types";
import { login } from "../../actions/auth";
import { fetchUser } from "../../actions/user";
import { Dispatch, bindActionCreators } from "redux";
import { AppActions } from "../../types/actions";
import AuthError from "../Segments/AuthError";
import { adminSignupThunk, coachSignupThunk } from "../../api/auth";
import queryString from "query-string";
import { AppState } from "../../store/configureStore";

const signupSchema = Yup.object().shape({
  name: Yup.string()
    .required("* First name is required")
    .max(50, "* Too long!"),
  email: Yup.string()
    .email("* Invalid email")
    .required("* Email is required"),
  password: Yup.string()
    .required("* Password is required")
    .max(50, "* Too Long!")
    .min(8, "* Minumum 8 characters"),
  organization_name: Yup.string()
    .required("* Organization Name is required")
    .max(50, "* Too long!")
});
const coachSignupSchema = Yup.object().shape({
  name: Yup.string()
    .required("* First name is required")
    .max(50, "* Too long!"),
  email: Yup.string()
    .email("* Invalid email")
    .required("* Email is required"),
  password: Yup.string()
    .required("* Password is required")
    .max(50, "* Too Long!")
    .min(8, "* Minumum 8 characters")
});

interface MyFormikProps {
  name: string;
  email: string;
  password: string;
  organization_name: string;
  first_name?: string;
  last_name?: string;
}

export interface SignUpProps {}
export interface SignUpState {}

type Props = RouteComponentProps<{}> &
  SignUpProps &
  LinkDispatchProps &
  LinkStateProps;

class SignUp extends React.Component<Props, SignUpState> {
  render() {
    const params = queryString.parse(this.props.location.search);
    return (
      <AuthContainer>
        <Formik
          initialValues={{
            name: "",
            email: "",
            password: "",
            organization_name: ""
          }}
          validationSchema={!params ? signupSchema : coachSignupSchema}
          onSubmit={async (values, { setFieldError, setStatus }) => {
            const { email, password, name, organization_name } = values;
            const firstName = name.split(" ")["0"];
            const lastName = name.split(" ")["1"];
            setStatus({ loading: true });
            try {
              if (!params.token) {
                await this.props.adminSignup(
                  firstName,
                  lastName,
                  email,
                  password,
                  organization_name
                );
              }
              if (params.token) {
                await this.props.coachSignup(
                  firstName,
                  lastName,
                  email,
                  password,
                  params
                );
              }
            } catch (e) {
              console.log("sign up error: ", e);
              setStatus({ loading: false });
              return setError(e.code, setFieldError, setStatus);
            }
            // If signup was successful, dispatch the authentication reducer
            // const token = auth!.currentUser!.uid;
            // const signupData = await signupAPI({
            //   firstName,
            //   lastName,
            //   email,
            //   uid: token
            // });
            // console.log("signupData: ", signupData);
            // if (signupData.error) {
            //   setStatus({ loading: false });
            //   return setStatus({ error: signupData.error });
            // }
            // this.props.fetchUser({
            //   ...signupData,
            //   user_token: signupData.user_token
            // });
            setStatus({ loading: false });
            // this.props.login(signupData.user_token);
            // this.props.login("supertoken");
            console.log("end of signup");
          }}
        >
          {({ status, setStatus }: FormikProps<MyFormikProps>) => {
            return (
              <Form>
                <Grid>
                  <AuthTitle>Phlex Coaching</AuthTitle>
                  {this.props.auth.signupError ? (
                    <AuthError
                      status={status}
                      error={this.props.auth.signupError}
                    />
                  ) : (
                    <div></div>
                  )}
                  <Field
                    name="email"
                    render={({
                      field,
                      form: { errors, touched }
                    }: FieldProps<MyFormikProps>) => {
                      const error = !!errors.email && !!touched.email;
                      return (
                        <FormSegment>
                          <Input
                            {...field}
                            error={error}
                            placeholder="Enter your email address"
                          />
                          <ErrorMessage
                            name={field.name}
                            component={ErrorText}
                          />
                        </FormSegment>
                      );
                    }}
                  />
                  <Field
                    name="password"
                    render={({
                      field,
                      form: { errors, touched }
                    }: FieldProps<MyFormikProps>) => {
                      const error = !!errors.password && !!touched.password;
                      field.name;
                      return (
                        <FormSegment>
                          <Input
                            {...field}
                            error={error}
                            placeholder="Create a password"
                            type="password"
                          />
                          <ErrorMessage
                            name={field.name}
                            component={ErrorText}
                          />
                        </FormSegment>
                      );
                    }}
                  />
                  <Field
                    name="name"
                    render={({
                      field,
                      form: { errors, touched }
                    }: FieldProps<MyFormikProps>) => {
                      const error = !!errors.name && !!touched.name;
                      return (
                        <FormSegment>
                          <Input
                            {...field}
                            error={error}
                            placeholder="Enter name"
                          />
                          <ErrorMessage
                            name={field.name}
                            component={ErrorText}
                          />
                        </FormSegment>
                      );
                    }}
                  />
                  {!params.token ? (
                    <Field
                      name="organization_name"
                      render={({
                        field,
                        form: { errors, touched }
                      }: FieldProps<MyFormikProps>) => {
                        const error =
                          !!errors.organization_name &&
                          !!touched.organization_name;
                        field.name;
                        return (
                          <FormSegment>
                            <Input
                              {...field}
                              error={error}
                              placeholder="New organization name"
                              type="organization_name"
                            />
                            <ErrorMessage
                              name={field.name}
                              component={ErrorText}
                            />
                          </FormSegment>
                        );
                      }}
                    />
                  ) : null}
                  <div>
                    <Button style={{ marginTop: "25px" }} type="submit">
                      Sign Up
                    </Button>
                    {/* {status && status.loading ? (
                      <Spinner />
                    ) : (
                    )} */}
                    <AuthError status={status} />
                  </div>
                  <Center style={{ gridRow: "9 / span 1" }}>
                    <LightText>Already have an account?&nbsp;</LightText>
                    <Link to="/signin">
                      <LinkText>Sign In</LinkText>
                    </Link>
                  </Center>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </AuthContainer>
    );
  }
}

const Grid = styled.div`
  display: grid;
  grid-template-rows: 100px 70px 70px 70px 70px 50px min-content 36px auto;
  justify-content: center;
  justify-items: center;
`;

interface LinkDispatchProps {
  login: (token: string) => void;
  fetchUser: (user: User) => void;
  adminSignup: (
    first_name: string,
    last_name: string,
    email: string,
    password: string,
    organization_name: string
  ) => void;
  coachSignup: (
    first_name: string,
    last_name: string,
    email: string,
    password: string,
    token: any
  ) => void;
}

const mapDispatchToProps = (
  dispatch: Dispatch<AppActions>
): LinkDispatchProps => ({
  login: bindActionCreators(login, dispatch),
  fetchUser: bindActionCreators(fetchUser, dispatch),
  adminSignup: bindActionCreators(adminSignupThunk, dispatch),
  coachSignup: bindActionCreators(coachSignupThunk, dispatch)
});

interface LinkStateProps {
  auth: Auth;
}
const mapStateToProps = (state: AppState): LinkStateProps => ({
  // isAuthorized: state.auth.role === "Head Coach"
  auth: state.auth
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SignUp));
