export const zoneColor = zone => {
  switch (zone) {
    case 6:
      return "#f5a623";
    case 5:
      return "#f5a623";
    case 4:
      return "#f5a623";
    case 3:
      return "#00d1e1";
    case 2:
      return "#00d1e1";
    case 1:
      return "#fa4374";
    default:
      return "Resting";
  }
};
