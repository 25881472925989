export const averageHRData = (arr, divider) => {
  const result = [];
  let sum = 0;
  for (let i = 0; i < arr.length; i++) {
    sum += arr[i].hr_bpm;
    if (i % divider === 0 && i !== 0) {
      //   // Check if value is numeric. If not use default value as 0
      (result as any).push({
        x: arr[i].hr_time,
        y: Math.floor(sum / divider)
        // label: Math.floor(sum / 10)
      });
      sum = 0;
    }
  }
  return result;
};
