import React, { Component } from "react";
import { Provider } from "react-redux";
import { createGlobalStyle } from "styled-components";
import { store, persistor } from "./store/configureStore";
import { PersistGate } from "redux-persist/integration/react";
import AppRouter from "../src/routers/AppRouter";
import { ThemeProvider } from "styled-components";
import { theme } from "./utils/theme";
import * as Sentry from "@sentry/browser";
import { SENTRY_DSN } from "./utils/variables";

// * Sentry Bug Tracking
Sentry.init({
  dsn: SENTRY_DSN,
  environment: process.env.REACT_APP_ENV,
  // Get use feedback after an event occurs
  beforeSend(event) {
    if (event.exception) {
      Sentry.showReportDialog();
    }
    return event;
  }
});

// * Styled Components Global Styles
const GlobalStyles = createGlobalStyle`
  html{
    /* @import url('https://fonts.googleapis.com/css?family=Roboto'); */
    @import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700');
    margin: 0;
    min-height: 100vh;
    /* height: 100vh; */
  }
  body, #root {
    /* min-height: 100%; */
    min-height: 100vh;
    margin: 0;
  }
`;

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        {/* <PersistGate loading={null} persistor={persistor}> */}
        <GlobalStyles />
        <ThemeProvider theme={theme}>
          <AppRouter />
        </ThemeProvider>
        {/* </PersistGate> */}
      </Provider>
    );
  }
}

export default App;
