import * as React from "react";
import styled from "styled-components";
import {
  GraphBigText,
  GraphLightText,
  Card,
  Icon,
  SpaceBetween
} from "../../UIElements";

export interface MiniDashboardCardProps {
  leftValue: string;
  leftDescription: string;
  rightValue: string;
  rightDescription: string;
  icon: string;
}

export interface MiniDashboardCardState {}

type Props = MiniDashboardCardProps;

class MiniDashboardCard extends React.Component<Props, MiniDashboardCardState> {
  constructor(props: Props) {
    super(props);
  }
  render() {
    const {
      leftDescription,
      leftValue,
      rightDescription,
      rightValue,
      icon
    } = this.props;
    return (
      <Div>
        <ChartField>
          <GraphBigText>{leftValue !== undefined ? leftValue : 0}</GraphBigText>
          <SpaceBetween height="6px" width="0px" />
          <GraphLightText>{leftDescription}</GraphLightText>
        </ChartField>
        <Icon src={icon} width={24} height={24} />
        <ChartField>
          <GraphBigText>{rightValue}</GraphBigText>
          <SpaceBetween height="6px" width="0px" />
          <GraphLightText>{rightDescription}</GraphLightText>
        </ChartField>
      </Div>
    );
  }
}

const ChartField = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Div = styled(Card)`
  width: 444.5px;
  height: 110px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 0;
  margin-bottom: 0;
`;

export default MiniDashboardCard;
