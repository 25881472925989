import { Team } from "../types";

// * Returns team of team_id param
// ----------------------------------------------
export const getTeamAttributes = (team_id: string, teams: Team[]): Team => {
  console.log("teams: ", teams);
  console.log("team_id: ", team_id);

  return teams.filter(team => team.team_id === team_id)[0];
};
