export const PRIMARY_COLOR = "#00d1e1";
export const SECONDARY_COLOR = "#09397f";
export const PRIMARY_FONT = "Roboto";
export const BACKGROUND_COLOR = "#f7f8fc";

export const API_URL = "";
export const API_BASE_URL = "";
export const SENTRY_DSN = "";

// TODO: separate car placeholder image and profile placeholder image
console.log("PROCESS ENV ------->", process.env.REACT_APP_ENV);
export const getAPIURL = () => {
  switch (process.env.REACT_APP_ENV) {
    case undefined:
        return "https://dev.phlex-coaching.datech.dev/api";
    case "STAGING":
      return "https://dev.phlex-coaching.datech.dev/api";
    case "PRODUCTION":
      return "https://coaching.phlex.us/api";
    case "DEV":
      return "https://dev.phlex-coaching.datech.dev/api";
      break;

    default:
      break;
  }
};
