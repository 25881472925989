import React from "react";
import ReactSelect, { components } from "react-select";
import { Styles } from "react-select/lib/styles";
import { PRIMARY_FONT } from "../../utils/variables";
import { Icon, Checkbox } from ".";
import { FieldArrayRenderProps, FieldProps } from "formik";
import { Option } from "react-select/lib/filters";

// * Description of react-select components
//   https://react-select.com/components
//   https://react-select.com/props#dropdownindicator

// TODO: figure out how to do types on this component

interface Props {
  inputWidth?: number;
  error?: boolean;
  arrayHelpers?: FieldArrayRenderProps;
  options: Option[];
}

export const Select = ({
  options,
  field,
  form,
  inputWidth = 400,
  error = false,
  // arrayHelpers,
  ...props
}): any => {
  // const DropdownIndicator = (props: any) => {
  //   return (
  //     components.DropdownIndicator && (
  //       <components.DropdownIndicator {...props}>
  //         <Icon type="dropdown-down" />
  //       </components.DropdownIndicator>
  //     )
  //   );
  // };

  const Option = (OptionProps: any) => {
    if (!props.isMulti) {
      return <components.Option {...OptionProps} />;
    }
    return (
      <components.Option {...OptionProps}>
        <Checkbox checked={OptionProps.isSelected} onChange={() => null} />
        <label>{OptionProps.value}</label>
      </components.Option>
    );
  };

  const customStyles: Styles = {
    valueContainer: (base, state) => ({
      ...base,
      padding: 0,
      margin: 0
    }),

    multiValue: base => ({ ...base, backgroundColor: "red" }),
    menuList: base => ({ ...base, maxHeight: 150 }),
    menu: base => ({ ...base, maxHeight: 150, zIndex: 1 }),
    multiValueLabel: base => ({
      ...base,
      fontFamily: PRIMARY_FONT,
      color: "#252733",
      fontSize: 16
    }),
    option: (base, state) => ({
      ...base,
      fontFamily: PRIMARY_FONT,
      fontSize: "16px",
      color: "#252733",
      backgroundColor: "#fff",
      display: "flex",
      flexDirection: "row",
      alignItems: "center"
    }),
    placeholder: base => ({
      ...base,
      margin: 0,
      color: "#9fa2b4",
      fontSize: 16,
      fontFamily: PRIMARY_FONT
    }),
    singleValue: base => ({ ...base, margin: 0 }),
    dropdownIndicator: base => ({ ...base, marginRight: 15 }),
    indicatorSeparator: base => ({
      ...base,
      display: "none"
    }),
    control: (base, state) => ({
      ...base,
      width: inputWidth,
      minHeight: 50,
      backgroundColor: state.isDisabled ? "lightgray" : "#ffffff",
      borderBottom: "solid 1px #dfe0eb",
      borderWidth: "0 0 1px 0",
      outline: error ? "none" : "initial",
      borderColor: error ? "red" : "",
      boxShadow: error ? "0 0 5px red" : "",
      color: "#252733",
      fontSize: "$6px",
      fontFamily: PRIMARY_FONT,
      // &:disabled {
      //   background: lightgray,
      // },
      ":focus": {
        outline: "none",
        boxShadow: "0px 0px 0px blue"
      },
      // ::placeholder {
      //   color: #9fa2b4;
      //   font-size: 16px;
      //   font-family: ${props => props.theme.PRIMARY_FONT};
      // }
      // width: inputWidth,
      // minHeight: 54,
      // height: "auto",
      // backgroundColor: state.isDisabled ? "lightgray" : "#ffffff",
      // borderRadius: 25,
      // border: "solid 1 #dfe0eb",
      // paddingLeft: 25,
      // outline: error ? "none" : "initial",
      // borderColor: error ? "red" : "#dfe0eb",
      // boxShadow: error ? "0 0 5px red" : "initial",
      // color: "#252733",
      // fontSize: 16,
      // fontFamily: PRIMARY_FONT,
      "&:hover": {
        border: "solid 1 #dfe0eb"
      }
    })
  };

  return (
    <ReactSelect
      {...props}
      components={{ Option }}
      styles={customStyles}
      options={options}
      name={field.name}
      value={
        options ? options.find(option => option.value === field.value) : ""
      }
      onChange={(value: any, action: any) => {
        console.log("VALUE", value);
        if (props.isMulti) {
          form.setFieldValue(action.name, value.map(item => item.value));
        } else if (value.coach_id) {
          form.setFieldValue(action.name, value.label);
          form.setFieldTouched(action.name, true);
          form.setFieldValue("coach_id", value.value);
          form.setFieldTouched(action.Value, true);
        } else {
          form.setFieldValue(action.name, value.label);
          form.setFieldTouched(action.name, true);
          form.setFieldValue("swimmer_id", value.value);
          form.setFieldTouched(action.Value, true);
        }
      }}
      // onChange={(option: Option) =>
      //   form.setFieldValue(field.name, option.value)
      // }
      onBlur={field.onBlur}
      menuPortalTarget={document.body}
    />
  );
};

// * OnChange configuration
// * https://gist.github.com/hubgit/e394e9be07d95cd5e774989178139ae8
