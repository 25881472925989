import { FETCH_WORKOUTS, EDIT_WORKOUT, ADD_WORKOUT, REMOVE_WORKOUT } from "../types/actions";
import { Workout } from '../types/index';

export const fetchWorkouts = (workouts: Workout[]) => ({
    type: FETCH_WORKOUTS,
    workouts
  });

  export const editWorkout = (workout: Workout) => ({
    type: EDIT_WORKOUT,
    workout
  });

  export const addWorkout = (workout: Workout) => ({
    type: ADD_WORKOUT,
    workout
  });

  export const removeWorkout = (workout_id: string) => ({
    type: REMOVE_WORKOUT,
    workout_id
  });

  