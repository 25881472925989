import * as React from "react";
import { connect } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";
import { AppActions } from "../../../types/actions";
import { User, Member, Team, TeamMember, Workout } from "../../../types";
import { AppState } from "../../../store/configureStore";
import TeamSelector from "./TeamSelector";
import { fetchTeams } from "../../../actions/team";
import { fetchMemberTeams } from "../../../actions/teamMember";
import { teamMember } from "../../../static/teamMember";
import { teams } from "../../../static/teams";
import { fetchMembers } from "../../../actions/member";
import { fakeMembers } from "../../../static/members";
import styled from "styled-components";
import { setWorkoutFilter } from "../../../actions/filter";
import { workout } from "../../../static/workout";
import { AppTitle, AppText } from "../../UIElements";
import { create } from "apisauce";
import { secondsToMin } from "../../../helperfunctions/secondstominutes";
import momentTimezone from "moment-timezone";

export interface WorkoutSelectorItemProps {
  workout: any;
  name: string;
  external_id: number | null;
  fetchCurrentWorkout: any;
  index: any;
  getHRData: any;
}
export interface WorkoutSelectorItemState {
  singleWorkouts: any;
}

const api = create({
  baseURL: "https://ap1.phlex.us/scripts",
  headers: {
    // "Access-Control-Allow-Origin": "*",
    // Authorization: "Bearer {token}",
    "Content-Type": "application/x-www-form-urlencoded",
    Accept: "application/json"
  }
});

type Props = WorkoutSelectorItemProps & LinkStateProps & LinkDispatchProps;

class WorkoutSelectorItem extends React.Component<
  Props,
  WorkoutSelectorItemState
> {
  constructor(props: Props) {
    super(props);
    this.state = {
      singleWorkouts: {}
    };
  }

  componentDidMount = async () => {};

  selectWorkout = async () => {
    const currentTime = momentTimezone.tz.guess();

    this.props.setWorkoutFilter(this.props.workout.workout_id);
    this.props.fetchCurrentWorkout(this.props.workout);
    const formData = new FormData();
    formData.append("requesting_user_id", `${this.props.external_id}`);
    formData.append("workout_id", this.props.workout.workout_id);
    formData.append("user_token", "super_token");
    formData.append("timezone", currentTime);
    const res = await api.post("/fetch-workout.php", formData);
    this.props.getHRData(res.data);
  };

  render() {
    const { workout, selectedWorkoutId, name } = this.props;
    // console.log("WORKOUTSELECTOR PROPS", this.props);
    return workout.workout_swim_duration_sec > 0 ? (
      <Grid onClick={this.selectWorkout}>
        {selectedWorkoutId === workout.workout_id && <SelectedBar />}
        <Vertical style={{ gridColumn: "2 / span 1" }}>
          <WorkoutName>{`Workout ${this.props.index + 1}`}</WorkoutName>
          <WorkoutDistance>{`${workout.workout_distance} m`}</WorkoutDistance>
        </Vertical>
        <Vertical style={{ gridColumn: "3 / span 1" }}>
          <WorkoutTime>
            {secondsToMin(workout.workout_swim_duration_sec)}
          </WorkoutTime>
          <AppText style={{ textAlign: "right" }}>
            {workout.workout_splash_score}
          </AppText>
        </Vertical>
      </Grid>
    ) : null;
  }
}

const Grid = styled.div`
  display: grid;
  grid-template-columns: 30px 200px auto;
  height: 52px;
  :hover {
    cursor: pointer;
  }
`;

const WorkoutName = styled(AppText)`
  font-size: 14px;
  letter-spacing: 0.2px;
  color: #1f2125;
`;

const WorkoutDistance = styled(AppText)`
  opacity: 0.5;
  font-size: 12px;
  color: #1f2125;
`;

const WorkoutTime = styled(AppText)`
  font-size: 12px;
  letter-spacing: 0.1px;
  text-align: right;
  color: ${props => props.theme.PRIMARY_COLOR};
`;

const Vertical = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  grid-template-rows: 30px 200px auto;
  height: 52px;
`;

const SelectedBar = styled.div`
  background-color: ${props => props.theme.PRIMARY_COLOR};
  height: 100%;
  width: 3px;
  grid-column: 1 / span 1;
`;

interface LinkDispatchProps {
  setWorkoutFilter: (workout_id: string) => void;
}

const mapDispatchToProps = (
  dispatch: Dispatch<AppActions>
): LinkDispatchProps => ({
  setWorkoutFilter: bindActionCreators(setWorkoutFilter, dispatch)
});

interface LinkStateProps {
  selectedWorkoutId: string;
}

const mapStateToProps = (state: AppState): LinkStateProps => ({
  // workouts: getSelectedWorkouts(state.workouts, state.filters.swimmerId, state.filters.date)
  // TODO: This is just a stub; replace with real logic
  selectedWorkoutId: state.filters.workoutId
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WorkoutSelectorItem);
