import { ERROR, EDIT_USER, SIGNUP_ERROR } from "./../types/actions";
// import { SignupParams } from "./../types/index";
// import { API_URL } from "../utils/variables";
// import { getError } from "../firebase/error";
import {
  LOGIN,
  LOGOUT,
  SEND_FORGOT_PASSWORD_EMAIL,
  GET_USER
} from "../types/actions";
import { Auth } from "../types";

// Login takes firebase uid as input
// dispatches fetchUser if successful
// dispatches error if unsuccessful

export const login = (access_token: string) => ({
  type: LOGIN,
  access_token
});

export const logout = () => ({
  type: LOGOUT
});

export const startError = (error: string) => ({
  type: ERROR,
  error
});

export const signupError = (error: string) => ({
  type: SIGNUP_ERROR,
  error
});

export const sendForgotEmail = (email: string) => ({
  type: SEND_FORGOT_PASSWORD_EMAIL,
  email
});

export const getMe = (user: object) => ({
  type: GET_USER,
  user
});

export const editUser = (user: object) => ({
  type: EDIT_USER,
  user
});
// export const loginAPI = async (uid: string, email: string): Promise<any> => {
//   const formData = new FormData();
//   formData.append(
//     "user_data",
//     `{
//       "user_firebase_auth_id": "${uid}",
//       "user_email": "${email}"
//     }`
//   );
//   let response;
//   try {
//     const rawData = await fetch(`${API_URL}/user-auth.php`, {
//       method: "POST",
//       body: formData
//     });
//     response = await rawData.json();
//   } catch (error) {
//     return { error: getError(error.message) };
//   }

//   // error checks
//   if (response.status === "error") {
//     return { error: getError(response.message) };
//   }
//   if (!response.user_token) {
//     return { error: "Unable to authenticate" };
//   }
//   if (!response.user_is_active) {
//     return { error: "User is not active" };
//   }
//   return response;
// };

// export const signupAPI = async ({
//   uid,
//   email,
//   firstName,
//   lastName,
//   photo = ""
// }: SignupParams) => {
//   // * For signup, we need to pass in a first name, last name, auth token, and email.
//   // * A photo is optional
//   const formData = new FormData();
//   const body = `{
//       "user_email": "${email}",
//       "user_firebase_auth_id": "${uid}",
//       "user_first_name": "${firstName}",
//       "user_last_name": "${lastName}",
//       "user_profile_image": "${photo}"
//     }`;
//   console.log("body: ", body);
//   formData.append("user_data", body);
//   let response;
//   try {
//     const rawData = await fetch(`${API_URL}/user-auth.php`, {
//       method: "POST",
//       body: formData
//     });
//     response = await rawData.json();
//   } catch (e) {
//     console.log("error signupAPI: ", e);
//     return { error: "Unable to authenticate" };
//   }
//   // error checks
//   if (response.status === "error") {
//     return { error: getError(response.message) };
//   }
//   if (!response.user_token) {
//     return { error: "Unable to authenticate" };
//   }
//   if (!response.user_is_active) {
//     return { error: "User is not active" };
//   }
//   return response;
// };

// export const startLogout = () => {
//   return async (dispatch: any) => {
//     await auth.signOut();
//     return dispatch(logout());
//   };
// };
