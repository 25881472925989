import { PRIMARY_FONT, PRIMARY_COLOR, SECONDARY_COLOR } from "./variables";
export const theme = {
  PRIMARY_COLOR: PRIMARY_COLOR,
  SECONDARY_COLOR: SECONDARY_COLOR,
  // BACKGROUND_COLOR: "#f7f8fc",
  BACKGROUND_COLOR: "#fcfcfc",
  TEXT_LIGHT_COLOR: "#9fa2b4",
  ERROR_TEXT: "red",
  SUCCESS_TEXT: "green",
  PRIMARY_FONT: PRIMARY_FONT,
  SECONDARY_FONT: "helvetica"
};
