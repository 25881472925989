import { TeamMemberActionTypes } from "../types/actions";
import { TeamMember } from "../types";

const teamMemberReducerDefaultState: TeamMember[] = [];

// This reducer holds user information that is returned from login/signup
// post requests

const teamMemberReducer = (
  state = teamMemberReducerDefaultState,
  action: TeamMemberActionTypes
): TeamMember[] => {
  switch (action.type) {
    case "FETCH_MEMBER_TEAMS":
      return [...action.memberTeams];
    case "ADD_MEMBER_TO_TEAM":
      return [...state, action.memberTeam];
    // case "ADD_COACH_TO_TEAM":
    //   return [...state, action.memberTeam];
    // case "ADD_SWIMMER_TO_TEAM":
    //   return [...state, action.memberTeam];
    case "REMOVE_MEMBER_FROM_TEAM":
      return state.filter(
        (teamMember: TeamMember) =>
          !(
            teamMember.member_id !== action.memberTeam.member_id &&
            teamMember.team_id !== action.memberTeam.team_id
          )
      );
    case "LOGOUT":
      return teamMemberReducerDefaultState;
    default:
      return state;
  }
};

export default teamMemberReducer;
