import { User, EditUser } from "../types";
import { FETCH_USER, EDIT_USER } from "./../types/actions";
// import { APIError } from "./../types/index";
// import { API_URL } from "../utils/variables";
// import { getError } from "../firebase/error";

export const fetchUser = (user: User) => ({
  type: FETCH_USER,
  user
});
export const editUser = (user: User) => ({
  type: EDIT_USER,
  user
});

// export const fetchUserAPI = (
//   user_token: string,
//   requesting_user_id: number
// ): Promise<User & APIError> => {
//   const formData = new FormData();
//   formData.append("requesting_user_id", requesting_user_id as any);
//   formData.append("user_token", user_token);
//   formData.append("user_id", requesting_user_id as any);
//   return fetch(`${API_URL}/fetch-user-profile.php`, {
//     method: "POST",
//     body: formData
//   })
//     .then(response => response.json())
//     .then(response => {
//       console.log("response: ", response);
//       if (response.status === "error") {
//         return { error: getError(response.message) };
//       }
//       return response;
//     })
//     .catch(error => {
//       console.log("error: ", error);
//       return { error: getError(error) };
//     });
// };

// // * When a edit user request is sent, the email address must be included

// export const editUserAPI = (
//   user_data: EditUserInput
// ): Promise<User & APIError> => {
//   const user_token = user_data.user_token;
//   const requesting_user_id = user_data.user_id;
//   const formData = new FormData();
//   formData.append("requesting_user_id", requesting_user_id as any);
//   formData.append("user_token", user_token as string);
//   formData.append("user_id", requesting_user_id as any);
//   const {
//     vehicle_preference: {
//       vehicle_color,
//       vehicle_model,
//       vehicle_num_seats,
//       vehicle_max_price,
//       vehicle_min_price,
//       vehicle_condition_is_new,
//       vehicle_condition_is_used
//     },
//     user_email,
//     user_first_name,
//     user_last_name,
//     user_profile_image
//   } = user_data;
//   // user_profile_image = "0" ? "" : user_profile_image;
//   const body = `{
//       "user_email": "${user_email}",
//       "user_first_name": "${user_first_name}",
//       "user_last_name": "${user_last_name}",
//       "user_profile_image": "${user_profile_image}",
//       "vehicle_preference": {
//         "vehicle_color": "${vehicle_color}",
//         "vehicle_num_seats": ${vehicle_num_seats},
//         "vehicle_max_price": ${vehicle_max_price},
//         "vehicle_min_price": ${vehicle_min_price},
//         "vehicle_condition_is_new": ${vehicle_condition_is_new},
//         "vehicle_condition_is_used": ${vehicle_condition_is_used},
//         "vehicle_model": "${vehicle_model}"
//       }
//     }
//     `;
//   console.log("body: ", body);
//   formData.append("user_data", body);
//   return fetch(`${API_URL}/edit-user-profile.php`, {
//     method: "POST",
//     body: formData
//   })
//     .then(response => response.json())
//     .then(response => {
//       console.log("response: ", response);
//       if (response.status === "error") {
//         return { error: getError(response.name) };
//       }
//       // If login successful, return response
//       return response;
//     })
//     .catch(error => {
//       console.log("error: ", error);
//       return { error: getError(error.name) };
//     });
// };

// export const fetchVehicleColorsAPI = (
//   user_token: string,
//   requesting_user_id: number
// ): Promise<any & APIError> => {
//   const formData = new FormData();
//   formData.append("requesting_user_id", requesting_user_id as any);
//   formData.append("user_token", user_token);
//   return fetch(`${API_URL}/fetch-vehicle-colors.php`, {
//     method: "POST",
//     body: formData
//   })
//     .then(response => response.json())
//     .then(response => {
//       console.log("response: ", response);
//       if (response.status === "error") {
//         return { error: getError(response.message) };
//       }
//       return response;
//     })
//     .catch(error => {
//       console.log("error: ", error);
//       return { error: getError(error) };
//     });
// };

// export const fetchVehicleModelClassAPI = (
//   user_token: string,
//   requesting_user_id: number
// ): Promise<any & APIError> => {
//   const formData = new FormData();
//   formData.append("requesting_user_id", requesting_user_id as any);
//   formData.append("user_token", user_token);
//   return fetch(`${API_URL}/fetch-vehicle-models.php`, {
//     method: "POST",
//     body: formData
//   })
//     .then(response => response.json())
//     .then(response => {
//       console.log("response: ", response);
//       if (response.status === "error") {
//         return { error: getError(response.message) };
//       }
//       return response;
//     })
//     .catch(error => {
//       console.log("error: ", error);
//       return { error: getError(error) };
//     });
// };
