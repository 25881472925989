import styled from "styled-components";

import * as React from "react";

interface AuthContainerProps {
  children: React.ReactNode;
}

const AuthContainer: React.FunctionComponent<AuthContainerProps> = ({
  children
}) => {
  return (
    <Container>
      <Div>{children}</Div>
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-template-rows: 151px min-content 94px auto;
  justify-items: center;
  /* flex-direction: column;
  justify-content: center;
  align-items: center; */
  background-color: ${props => props.theme.BACKGROUND_COLOR};
  min-height: inherit;
`;

const Div = styled.div`
  width: 500px;
  height: auto;
  box-shadow: 2.1px 2.1px 35px 0 rgba(209, 210, 218, 0.3);
  background-color: #ffffff;
  grid-row: 2 / span 1;
  padding: 50px 0;
`;

export { AuthContainer };
