import * as React from "react";
import { AppText, Card, AppTitle, Spinner } from "../../UIElements";
import styled from "styled-components";
import { zones } from "../../../static/zones";
import { WorkoutCollapsible } from "../../UIElements/WorkoutCollapsible";
import { zoneColor } from "../../../helperfunctions/zoneColor";
import { zoneCheck } from "../../../helperfunctions/zoneCheck";

export interface WorkoutSetsProps {
  data: any;
}
export interface WorkoutSetsState {}

type Props = WorkoutSetsProps;

class WorkoutSets extends React.Component<Props, WorkoutSetsState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedSwimmer: 0
    };
  }
  render() {
    const { data } = this.props;
    console.log("WORKOUTSET DATA", data);
    // Title
    // Set
    // Component
    return data.sets ? (
      <Div>
        <WorkoutTitleDiv>
          <WorkoutTitle>Workout Sets</WorkoutTitle>
        </WorkoutTitleDiv>
        {data.sets.map((set: any, setNumber) => {
          console.log("set: ", set);
          if (!set.components.length) {
            return;
          }
          // If not
          return (
            <div key={set.set_id}>
              <SetTitle setNumber={setNumber + 1} key={set.set_id} />
              {set.components.map((component: any, index: number) => (
                <SetItem
                  superlaps={component}
                  key={component.comp_set_id}
                  name={component.comp_title}
                  time={component.comp_total_time_formatted}
                  DPS={`${component.comp_dps}m`}
                  Zone={component.comp_hr_zone_type}
                  avg_hr={`${component.comp_avg_hr_bpm} bpm`}
                />
              ))}
            </div>
          );
        })}
      </Div>
    ) : null;
  }
}

const WorkoutTitle = styled(AppTitle)`
  opacity: 0.5;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.2px;
  text-align: center;
  color: #1f2125;
`;

const WorkoutTitleDiv = styled.div`
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
`;

const Div = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
`;

interface SetTitleProps {
  setNumber: number;
}
const SetTitle: React.SFC<SetTitleProps> = ({ setNumber }) => (
  <SetTitleGrid>
    <SetTitleText>{`SET ${setNumber}`}</SetTitleText>
    <SetTitleText style={{ textAlign: "right" }}>Time</SetTitleText>
    <SetTitleText style={{ textAlign: "right" }}>DPS</SetTitleText>
    <SetTitleText style={{ textAlign: "center" }}>ZONE</SetTitleText>
    <SetTitleText style={{ textAlign: "center" }}>AVG. HR</SetTitleText>
  </SetTitleGrid>
);

interface SetItemProps {
  name: string;
  time: string;
  DPS: string;
  Zone: string;
  avg_hr: string;
  superlaps: any;
}

// TODO: color zone text

const SetItem: React.SFC<SetItemProps> = ({
  DPS,
  avg_hr,
  name,
  time,
  Zone,
  superlaps
}) => {
  // console.log("Zone: ", Zone);
  // console.log("zones: ", zones);
  // console.log(`zones[${Zone}]: `, zones[Zone]);
  return superlaps.super_laps ? (
    <WorkoutCollapsible
      DPS={DPS}
      avg_hr={avg_hr}
      name={name}
      time={time}
      Zone={Zone}
      superlaps={superlaps}
    >
      {superlaps.super_laps.map(laps => (
        <SuperLapGrid>
          <SetNameText style={{ marginLeft: "25px" }}>
            {laps.super_lap_style}
          </SetNameText>
          <SetTitleText style={{ textAlign: "right" }}>
            {laps.super_lap_time_formatted}
          </SetTitleText>
          <SetTitleText style={{ textAlign: "right" }}>
            {laps.super_lap_dps}m
          </SetTitleText>
          <SetZoneText
            style={{ textAlign: "center" }}
            color={zoneColor(laps.super_hr_zone_type)}
          >
            {zoneCheck(laps.super_hr_zone_type)}
          </SetZoneText>
          <SetTitleText style={{ textAlign: "center" }}>
            {laps.super_lap_avg_hr_bpm} bpm
          </SetTitleText>
        </SuperLapGrid>
      ))}
    </WorkoutCollapsible>
  ) : (
    <Spinner />
  );
};

const SetTitleGrid = styled.div`
  display: grid;
  grid-template-columns: 320px 40px 80px 200px auto;
  height: 32px;
  background-color: #f4f5f5;
  padding-left: 30px;
  align-items: center;
`;

// const StyledCollapsible = styled(Collapsible)`
//   height: 60px;
//   background: #fff;
//   display: grid;
//   grid-template-columns: 320px 40px 80px 200px auto;
//   height: 32px;
//   padding-left: 30px;
//   align-items: center;
// `;
const SetItemGrid = styled(SetTitleGrid)`
  height: 60px;
  background-color: #fff;
`;

const SuperLapGrid = styled(SetTitleGrid)`
  height: 60px;
  background-color: #fbfbfb;
  border-bottom: 1px solid #cccccc;
`;
interface SetZoneTextProps {
  color?: string;
}

const SetZoneText = styled(AppText)<SetZoneTextProps>`
  opacity: 0.5;
  font-size: 13px;
  color: ${props => props.color};
  font-weight: 600;
`;

SetZoneText.defaultProps = {
  color: "#1f2125"
};

const SetNameText = styled(AppText)`
  font-size: 14px;
  letter-spacing: 0.2px;
  color: #1f2125;
`;

const SetTitleText = styled(AppText)`
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 1.2px;
  color: #8f9092;
`;

export { WorkoutSets };
