import { create } from "apisauce";
import {
  sendForgotEmail,
  login,
  getMe,
  startError,
  logout,
  signupError
} from "../actions/auth";
import { Email } from "../types";
import { editUser } from "../actions/user";
import { api } from "../api/api";

// getMe thunk to serve up user info
export const getMeThunk = () => async (dispatch, getState) => {
  const token = getState().auth.token;
  try {
    const res: any = await api.post(
      "/auth/me",
      {},
      { headers: { Authorization: `Bearer ${token}` } }
    );
    if (res.data.error === "Token Expired") {
      logout();
    } else {
      const action = getMe(res.data as any);
      console.log("USER RESDATA", action);
      getMe(action);
      dispatch(action);
    }
  } catch (err) {
    console.log(err);
  }
};

export const sendForgotEmailThunk = email => async dispatch => {
  try {
    const formData = new FormData();
    formData.append("email", email);
    const res = await api.post("/auth/send-reset-password", formData);
    const action = sendForgotEmail(res.data as any);
    dispatch(action);
  } catch (err) {
    console.log(err);
  }
};

export const loginThunk = (email, password) => async dispatch => {
  try {
    const formData = new FormData();
    formData.append("email", email);
    formData.append("password", password);
    const res: any = await api.post("/auth/login", formData);
    console.log("RES DATA", res.data);
    if (res.data.access_token) {
      const action = login(res.data.access_token);
      // get me api
      const userPayload = await api.post(
        "/auth/me",
        {},
        { headers: { Authorization: `Bearer ${res.data.access_token}` } }
      );
      console.log("USER PAYLOAD", userPayload);
      const userAction = getMe(userPayload.data as any);
      dispatch(userAction);
      dispatch(action);
    } else {
      const action = startError(res.data.error);
      dispatch(action);
    }
  } catch (err) {
    console.log(err);
  }
};

export const editUserThunk = ({
  user_id,
  first_name,
  last_name,
  email,
  password
}: {
  user_id: number;
  first_name?: string;
  last_name?: string;
  email?: string;
  password?: string;
}) => async (dispatch, getState) => {
  const token = getState().auth.token;
  try {
    const formData = new FormData();
    formData.append("user_id", `${user_id}`);
    !!first_name && formData.append("first_name", first_name);
    !!last_name && formData.append("last_name", last_name);
    !!email && formData.append("email", email);
    !!password && formData.append("password", password);
    const res = await api.post("/auth/edit", formData, {
      headers: { Authorization: `Bearer ${token}` }
    });
    console.log("EDIT USER THUNK", res.data);
    const action = editUser(res.data as any);
    dispatch(action);
  } catch (err) {
    console.log(err);
  }
};

export const adminSignupThunk = (
  first_name,
  last_name,
  email,
  password,
  organization_name
) => async dispatch => {
  try {
    const formData = new FormData();
    formData.append("first_name", first_name);
    formData.append("last_name", last_name);
    formData.append("email", email);
    formData.append("password", password);
    formData.append("organization_name", organization_name);
    const res: any = await api.post("auth/register-admin", formData);
    console.log("adminSign up response", res);

    if (res.ok && res.data.token.access_token) {
      const action = login(res.data.token.access_token);
      dispatch(action);
    }
    if (!res.ok) {
      console.log("res error", res.data.errors.email[0]);
      const action = signupError(res.data.errors.email[0]);
      dispatch(action);
    }
    console.log("adminSign up response", res.data);
  } catch (err) {
    console.log(err);
  }
};

export const coachSignupThunk = (
  first_name,
  last_name,
  email,
  password,
  token
) => async dispatch => {
  try {
    const formData = new FormData();
    formData.append("first_name", first_name);
    formData.append("last_name", last_name);
    formData.append("email", email);
    formData.append("password", password);
    formData.append("token", token.token);
    const res = await api.post<any>("auth/register-coach", formData);
    if (res.data.token.access_token) {
      const action = login(res.data.token.access_token);
      dispatch(action);
    }
    console.log("coach signed up");
  } catch (err) {
    console.log(err);
  }
};
