import styled from "styled-components";

interface Props {
  error?: boolean;
  inputWidth?: string;
}

export const Input = styled.input<Props>`
  width: ${props => props.inputWidth};
  height: 50px;
  background-color: #ffffff;
  border-bottom: solid 1px #dfe0eb;
  border-width: 0 0 1px 0;
  /* outline: ${props => (props.error ? "none" : "")}; */
  border-color: ${props => (props.error ? "red" : "")};
  /* box-shadow: ${props => (props.error ? "0 0 5px red" : "")}; */
  color: #252733;
  font-size: 16px;
  font-family: ${props => props.theme.PRIMARY_FONT};
  &:disabled {
    background: lightgray;
  }
  :focus {
    outline: none;
    box-shadow: 0px 0px 0px  blue;
  }
  ::placeholder {
    color: #9fa2b4;
    font-size: 16px;
    font-family: ${props => props.theme.PRIMARY_FONT};
  }
`;

Input.defaultProps = {
  error: false,
  inputWidth: "285px" // 400 - 25px padding
};
