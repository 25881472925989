import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunk, { ThunkMiddleware } from "redux-thunk";
import {
  persistStore,
  persistReducer,
  persistCombineReducers,
  createTransform
} from "redux-persist";
import authReducer from "../reducers/authReducer";
import filterReducer from "../reducers/filterReducer";
import storage from "redux-persist/lib/storage";
import userReducer from "../reducers/userReducer";
import swimmerReducer from "../reducers/memberReducer";
import workoutReducer from "../reducers/workoutReducer";
import memberReducer from "../reducers/memberReducer";
import teamReducer from "../reducers/teamReducer";
import teamMemberReducer from "../reducers/teamMemberReducer";
import coachReducer from "../reducers/coachReducer";
import organizationReducer from "../reducers/organizationReducer";
import allSwimmerReducer from "../reducers/swimmerReducer";
import teammateReducer from "../reducers/teammateReducer";
import { AppActions } from "../types/actions";
import SwimmerDataReducer from "../reducers/swimmerDataReducer";

// Redux Store
// -----------
// This file is dense main because of the use of Redux-persist
// Redux-persist saves the redux-store to disk, which allows
// the data to persist between browser reloads.
// Firebase is used to persist data between login/logout sessions.

const persistConfig = {
  key: "phlex-web",
  storage,
  // Redux-persist converts Moment objects to strings when saving to disk
  // to convert the items back to Moment items upon launch, we use a transform
  // to convert them back to Moment objects.
  transforms: [
    createTransform(
      // The first parameter converts objects when writing to disk
      state => state,
      // The second parameter converts objects when loading into memory
      state => state
      // (outboundState: any) => {
      //   // convert startDate and endDate back into Moment objects
      //   return {
      //     ...outboundState
      //     // startDate: moment(outboundState.startdate),
      //     // endDate: moment(outboundState.endDate)
      //   };
      // }
      // The third parameter denotes which reducers to apply the change to
      // { whitelist: ["filters"] }
    ),
    // Clear error state when persisting to disk
    createTransform(state => ({ ...state, error: "" }), state => state, {
      whitelist: ["auth"]
    })
  ]
};

// Used for Typescript
// -------------------
const rootReducer = combineReducers({
  auth: authReducer,
  filters: filterReducer,
  user: userReducer,
  members: memberReducer,
  workouts: workoutReducer,
  teams: teamReducer,
  teamMembers: teamMemberReducer,
  organizations: organizationReducer,
  coaches: coachReducer,
  swimmers: allSwimmerReducer,
  teamMates: teammateReducer,
  selectedSwimmer: SwimmerDataReducer
});

export type AppState = ReturnType<typeof rootReducer>;
// -------------------

// Combine reduces
// const reducers = persistCombineReducers(persistConfig, {
//   auth: authReducer,
//   filters: filterReducer,
//   user: userReducer,
//   members: memberReducer,
//   workouts: workoutReducer,
//   teams: teamReducer,
//   teamMembers: teamMemberReducer,
//   organizations: organizationReducer,
//   coaches: coachReducer,
//   swimmers: allSwimmerReducer,
//   teamMates: teammateReducer,
//   selectedSwimmer: SwimmerDataReducer
// });
const reducers = combineReducers({
  auth: authReducer,
  filters: filterReducer,
  user: userReducer,
  members: memberReducer,
  workouts: workoutReducer,
  teams: teamReducer,
  teamMembers: teamMemberReducer,
  organizations: organizationReducer,
  coaches: coachReducer,
  swimmers: allSwimmerReducer,
  teamMates: teammateReducer,
  selectedSwimmer: SwimmerDataReducer
});

const composeEnhancers =
  ((window as any).__REDUX_DEVTOOLS_EXTENSION__ &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION__()) ||
  compose;

// create redux store
// const store = createStore(reducers, composeEnhancers(applyMiddleware(thunk)));
const store = createStore(
  reducers,
  applyMiddleware(thunk as ThunkMiddleware<AppState, AppActions>)
);
// Create redux-persist instance
const persistor = persistStore(store);

export { store, persistor };
