import styled from "styled-components";

interface Props {
  width?: string;
  height?: string;
}

const SpaceBetween = styled.div<Props>`
  width: ${props => props.width};
  height: ${props => props.height};
`;

SpaceBetween.defaultProps = {
  width: "14px",
  height: "0px"
};

export { SpaceBetween };
