import * as React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
// import { DropdownMenu, MenuItem } from "react-bootstrap-dropdown-menu";
import {
  Icon,
  SpaceBetween,
  AppText,
  AppTitle,
  HeaderTitle,
  HeaderText
} from "../UIElements";
import { Link } from "react-router-dom";
import { AppState } from "../../store/configureStore";
import { phlexLogo, gearIcon } from "../../img";
import DropdownMenu from "./DropdownMenu";

// * Only renders if the user is authenticated
// TODO: Build dropdown menu
// https://blog.campvanilla.com/reactjs-dropdown-menus-b6e06ae3a8fe

interface HeaderProps {}
interface HeaderState {
  menuOpen: boolean;
}

type Props = HeaderProps & LinkStateProps;

class Header extends React.Component<Props, HeaderState> {
  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false
    };
  }

  toggleMenu = () => this.setState(prev => ({ menuOpen: !prev.menuOpen }));

  render(): JSX.Element | null {
    const { menuOpen } = this.state;
    if (!this.props.isAuthenticated) {
      return null;
    }
    return (
      <Div>
        <Main>
          <Link to="/" style={{ textDecoration: "none" }}>
            <LogoDiv>
              <Icon clickable src={phlexLogo} height={30} width={83} />
              <HeaderTitle style={{ marginLeft: "12px" }}>
                Coaching Beta
              </HeaderTitle>
            </LogoDiv>
          </Link>
          <div>
            <Profile>
              <Link to="/dashboard">
                <HeaderText>Dashboard</HeaderText>
              </Link>
              <SpaceBetween width="42px" />
              <ParentMenu>
                <Icon
                  src={gearIcon}
                  height={24}
                  width={24}
                  clickable
                  onClick={this.toggleMenu}
                />
                {menuOpen && (
                  <DropdownMenu
                    toggleMenu={this.toggleMenu}
                    handleClickOutside={this.toggleMenu}
                  />
                )}
              </ParentMenu>
            </Profile>
          </div>
        </Main>
        <Shadow />
      </Div>
    );
  }
}

const ParentMenu = styled.div`
  position: relative;
  display: inline-block;
`;
const Profile = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Main = styled.div`
  background-color: #fff;
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 45px;
  box-shadow: 0 1px 0 0 #f4f5f5;
  background-color: #09397f;
  padding: 0 74px 0 74px;
`;

const Div = styled.div`
  position: fixed;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  z-index: 1;
`;

const Shadow = styled.div`
  background-color: #dfe0eb;
  height: 1px;
`;

const LogoDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

interface LinkStateProps {
  isAuthenticated: boolean;
  // url: string;
}

const mapStateToProps = (state: AppState): LinkStateProps => ({
  isAuthenticated: !!state.auth.token
  // isAuthenticated: !!state.auth.token,
  // url:
  //   state.user.user_profile_image === "0"
  //     ? PROFILE_PLACEHOLDER_IMAGE
  //     : state.user.user_profile_image
});

export default connect(mapStateToProps, null)(Header);
