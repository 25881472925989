import { create } from "apisauce";
import { ThunkAction } from "redux-thunk";
import { AppState } from "../store/configureStore";
import { ActionCreator } from "redux";
import { MemberActionTypes } from "../types/actions";
import {
  fetchMembers,
  editMember,
  addMember,
  removeMember,
  fetchOneMember
} from "../actions/member";
import { Member } from "../types";
import { api } from "../api/api";
import { errorSwimmerInvite } from "../helperfunctions/toastFunctions";

//Thunk creators

//Get one swimmer/coach by email address for logged in user's organization

export const fetchOneMemberThunk = email => async (dispatch, getState) => {
  const token = getState().auth.token;
  const organization_id = getState().auth.user.organization_id;
  try {
    //to get res.data accepting any
    // const res = await api.get<any>(
    //   `api/organization/user?email=${email}&organization_id=${organization_id}`
    // );
    // const action = fetchOneMember(res.data.member_id as Member);
    const res = await api.get(
      `api/organization/user?email=${email}&organization_id=${organization_id}`
    );
    const action = fetchOneMember(res.data as Member);
    dispatch(action);
  } catch (err) {
    console.log(err);
  }
};

//Get Swimmers logged In User's Organization
export const fetchMembersThunk = () => async (dispatch, getState) => {
  const token = getState().auth.token;
  const organization_id = getState().auth.user.organization_id;
  try {
    const res = await api.get(
      `/swimmer/get-by-organization?organization_id=${organization_id}`,
      {},
      { headers: { Authorization: `Bearer ${token}` } }
    );
    const action = fetchMembers(res.data as Member[]);
    dispatch(action);
  } catch (err) {
    console.log(err);
  }
};

// Delete a swimmer
export const removeMemberThunk = member_id => async (dispatch, getState) => {
  const token = getState().auth.token;
  try {
    // const removeSwimMember = await api.delete(`/swimmer?member_id=${member_id}`);
    const res = await api.delete(
      `/swimmer/${member_id}`,
      {},
      { headers: { Authorization: `Bearer ${token}` } }
    );
    const action = removeMember(res.data as string);
    dispatch(action);
  } catch (err) {
    console.log(err);
  }
};

//Invite a Swimmer to organization
export const addMemberThunk = (email, first_name, last_name) => async (
  dispatch,
  getState
) => {
  const token = getState().auth.token;
  const organization_id = getState().auth.user.organization_id;
  console.log("ADDMEMBER THUNK ORG ID", getState());
  const formData = new FormData();
  formData.append("organization_id", organization_id);
  formData.append("email", email);
  formData.append("first_name", first_name);
  formData.append("last_name", last_name);

  try {
    const res: any = await api.post(
      "/swimmer/invite-to-organization",
      formData,
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    );
    if (res.ok) {
      const action = addMember(res.data as Member); //expects just an email but endpoint expects email/first+last name/external id
      dispatch(action);
    }
    if (!res.ok && res.data.error) {
      return res.data.error;
    }
  } catch (err) {
    console.log(err);
  }
};

//accept swimmer (possibly not needed)
export const acceptMemberThunk = (email, token_id) => async dispatch => {
  const formData = new FormData();
  formData.append("email", email);
  formData.append("token", token_id);
  try {
    await api.post("/swimmer/accept-invite", formData);
  } catch (err) {
    console.log(err);
  }
};
