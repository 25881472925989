import * as React from "react";
import { connect } from "react-redux";
import { Member } from "../../../types";
import { AppState } from "../../../store/configureStore";
import { getTeam } from "../../../selectors/getTeam";
import { Collapsible, Spinner } from "../../UIElements";
import SwimmerSelectorItem from "./SwimmerSelectorItem";
import { Dispatch, bindActionCreators } from "redux";
import { fetchTeammatesThunk } from "../../../api/teams";
import {
  addCoachTeamThunk,
  addSwimmerTeamThunk,
  removeCoachTeamThunk,
  removeSwimmerTeamThunk
} from "../../../api/teamMember";
import { editTeamThunk } from "../../../api/teams";
import { AppActions } from "../../../types/actions";
import { create } from "apisauce";

export interface TeamSelectorProps {
  id: string;
  name: string;
  onSelectSwimmer: any;
  month: any;
  year: any;
  onMonthChange: any;
  allTeammates: any;
  firstTeam: any;
  fetchCurrentWorkout: any;
  getHRData: any;
  fetchWorkoutOnDateClick: any;
  index: any;
  currentSwimmerID: number;
}
export interface TeamSelectorState {
  selectedSwimmer: 0;
  temporaryState: object;
}

// TODO: Set filter when unit is selected

type Props = TeamSelectorProps & LinkStateProps & LinkDispatchProps;

const api = create({
  baseURL: "https://ap1.phlex.us/scripts",
  headers: {
    // "Access-Control-Allow-Origin": "*",
    // Authorization: "Bearer {token}",
    "Content-Type": "application/x-www-form-urlencoded",
    Accept: "application/json"
  }
});

class TeamSelector extends React.Component<Props, TeamSelectorState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedSwimmer: 0,
      temporaryState: {}
    };
  }
  componentDidMount = async () => {
    // this.props.fetchAllTeamMembers(this.props.id);
    // console.log("FETCH ALL TEAMMEMBER ID", this.props);
    this.props.fetchAllTeamMembers(this.props.id);
    if (this.props.name === this.props.firstTeam.name) {
      const teamObj = this.props.firstTeam.teammates;
      for (let key in teamObj) {
        if (teamObj[key].external_swimmer_id !== undefined) {
          const formData = new FormData();
          formData.append(
            "requesting_user_id",
            teamObj[key].external_swimmer_id
          );
          formData.append("user_token", "super_token");
          const res = await api.post(`/fetch-last-workout.php`, formData);
          this.props.fetchCurrentWorkout(res.data);
          this.props.getHRData(res.data);
          break;
        }
      }
    }
  };
  getInvidivialState = state => {
    this.setState({
      temporaryState: state
    });
    this.props.onSelectSwimmer(this.state.temporaryState);
  };
  render() {
    const { members, name, id } = this.props;
    // console.log("TEAMSELECTOR PROPS", this.props);
    return this.props.teammates[`${this.props.id}`] &&
      this.props.teammates[`${this.props.id}`]["0"] ? (
      <Collapsible title={name} index={this.props.index}>
        {this.props.teammates[`${this.props.id}`]["0"].teammates.map(member =>
          member.external_swimmer_id ? (
            <SwimmerSelectorItem
              currentSwimmerID={this.props.currentSwimmerID}
              month={this.props.month}
              year={this.props.year}
              swimmer_id={member.id}
              swimmer_name={`${member.first_name} ${member.last_name}`}
              external_swimmer_id={member.external_swimmer_id}
              key={member.id}
              onSelectSwimmer={this.getInvidivialState}
              onMonthChange={this.props.onMonthChange}
              fetchCurrentWorkout={this.props.fetchCurrentWorkout}
              getHRData={this.props.getHRData}
              fetchWorkoutOnDateClick={this.props.fetchWorkoutOnDateClick}
            />
          ) : null
        )}
      </Collapsible>
    ) : (
      <Spinner />
    );
  }
}

// const StyledCollapsible = styled(Collapsible)`
//   .Collapsible {
//     background-color: "red";
//   }
// `;

// interface LinkDispatchProps {
//   fetchUser: (user: User) => void;
// }

interface LinkDispatchProps {
  fetchTeammates: (team_id: number) => void;
  // addSwimmer: (swimmer_id: number, team_id: number) => void;
  // removeSwimmer: (swimmer_id: number, team_id: number) => void;
  // addCoach: (coach_id: number, team_id: number) => void;
  // removeCoach: (coach_id: number, team_id: number) => void;
  editTeamName: (team_id: number, team_name: string) => void;
  fetchAllTeamMembers: (team_id: any) => void;
}
// const mapDispatchToProps = (
//   dispatch: Dispatch<AppActions>
// ): LinkDispatchProps => ({
//   fetchUser: bindActionCreators(fetchUser, dispatch)
// });

const mapDispatchToProps = (
  dispatch: Dispatch<AppActions>
): LinkDispatchProps => ({
  fetchTeammates: bindActionCreators(fetchTeammatesThunk, dispatch),
  // addSwimmer: bindActionCreators(addSwimmerTeamThunk, dispatch),
  // removeSwimmer: bindActionCreators(removeSwimmerTeamThunk, dispatch),
  // addCoach: bindActionCreators(addCoachTeamThunk, dispatch),
  // removeCoach: bindActionCreators(removeCoachTeamThunk, dispatch),
  editTeamName: bindActionCreators(editTeamThunk, dispatch),
  fetchAllTeamMembers: bindActionCreators(fetchTeammatesThunk, dispatch)
});

interface LinkStateProps {
  members: Member[];
  teammates: any;
}

const mapStateToProps = (
  state: AppState,
  ownProps: TeamSelectorProps
): LinkStateProps => {
  // console.log("state: ", state);
  return {
    members: getTeam(ownProps.id, state.teamMembers, state.members),
    teammates: state.teamMates
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TeamSelector);
