import styled from "styled-components";

interface Props {
  src: string;
  clickable?: boolean;
  small?: boolean;
}
const Avatar = styled.img<Props>`
  width: ${props => (props.small ? "47px" : "133px")};
  height: ${props => (props.small ? "49px" : "133px")};
  border-radius: 50%;
  cursor: ${props => (props.clickable ? "pointer" : "default")};
`;

Avatar.defaultProps = {
  clickable: false,
  small: false
};

export { Avatar };
