export const zoneCheck = zone => {
  switch (zone) {
    case 6:
      return "Resting";
    case 5:
      return "Recovery";
    case 4:
      return "Fat Burn";
    case 3:
      return "Aerobic";
    case 2:
      return "Anerobic";
    case 1:
      return "Max";
    default:
      return "Resting";
  }
};
