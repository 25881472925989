import { AuthActionTypes } from "./../types/actions";
import { Auth } from "../types";

// Auth Reducer
// ------------
// Allows user to login or logout
// Also handle errors in this reducer

const defaultAuthReducerState: Auth = {
  access_token: "",
  // role: "",
  error: "",
  signupError: "",
  user: {}
};

const authReducer = (
  state = defaultAuthReducerState,
  action: AuthActionTypes
): Auth => {
  switch (action.type) {
    case "LOGIN":
      return {
        ...state,
        token: action.access_token,
        // role: action.role,
        token_type: action.token_type,
        error: ""
      };
    case "GET_USER":
      return { ...state, user: action.user };
    case "EDIT_USER":
      console.log("EDIT USER REDUCER CASE", action);
      return { ...state, user: action.user };
    case "ERROR":
      return { ...state, error: action.error };
    case "SIGNUP_ERROR":
      return { ...state, signupError: action.error };
    case "LOGOUT":
      return defaultAuthReducerState;
    case "ERROR":
      return { ...state, error: action.error };
    default:
      return state;
  }
};

export default authReducer;
