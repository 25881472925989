import * as React from "react";
import styled from "styled-components";
import { SFC } from "react";
import {
  editIcon,
  dashboardSelectedIcon,
  dashboardIcon,
  searchIcon,
  searchSelectedIcon,
  chatSelectedIcon,
  chatIcon,
  notificationIcon,
  miniPhotoIcon,
  photoIcon,
  googleIcon,
  facebookIcon,
  googleMiniIcon,
  logo,
  facebookMiniIcon,
  logoutIcon,
  bigCheckBox,
  dropdownIcon,
  trashcan,
  badDealIcon,
  goodDealIcon,
  greatDealIcon,
  fairDealIcon,
  chatIconWhite,
  phlexLogo
} from "../../img";

interface Props {
  clickable?: boolean;
  height?: number;
  width?: number;
}

interface AdditionalProps {
  icon: string;
}

export const Icon = styled.img.attrs<AdditionalProps>({
  src: props => props.icon
})<Props>`
  :hover {
    cursor: ${props => (props.clickable ? "pointer" : "default")};
  }
  height: ${props => props.height};
  width: ${props => props.width};
`;

Icon.defaultProps = {
  clickable: false,
  height: 12,
  width: 12
};
