import * as React from "react";
import { connect } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";
import { AppActions } from "../../../types/actions";
import { User, Member, Team, TeamMember, Workout } from "../../../types";
import { AppState } from "../../../store/configureStore";
import TeamSelector from "./TeamSelector";
import { fetchTeams } from "../../../actions/team";
import { fetchMemberTeams } from "../../../actions/teamMember";
import { teamMember } from "../../../static/teamMember";
import { teams } from "../../../static/teams";
import { fetchMembers } from "../../../actions/member";
import { fakeMembers } from "../../../static/members";
import styled from "styled-components";
import { setWorkoutFilter } from "../../../actions/filter";
import { workout } from "../../../static/workout";
import { AppTitle } from "../../UIElements";
import WorkoutSelectorItem from "./WorkoutSelectorItem";

export interface WorkoutSelectorProps {
  workout_data: any;
  external_id: any;
  fetchCurrentWorkout: any;
  getHRData: any;
}
export interface WorkoutSelectorState {}

type Props = WorkoutSelectorProps & LinkStateProps & LinkDispatchProps;

class WorkoutSelector extends React.Component<Props, WorkoutSelectorState> {
  constructor(props: Props) {
    super(props);
  }

  componentDidMount = () => {};

  render() {
    const { workouts } = this.props;
    // this.props.workout_data.objects && this.props.workout_data.objects["0"]
    //   ? console.log("WORKOUT SELECTOR", this.props.workout_data.objects)
    //   : console.log("NO WORKOUT SELECTOR");
    return (
      <Div>
        <WorkoutTitle>Workouts</WorkoutTitle>
        {this.props.workout_data.objects && this.props.workout_data.objects["0"]
          ? this.props.workout_data.objects.map((workout, index) => (
              <WorkoutSelectorItem
                workout={workout}
                name={`Workout`}
                key={workout.workout_id}
                external_id={this.props.external_id}
                fetchCurrentWorkout={this.props.fetchCurrentWorkout}
                index={index}
                getHRData={this.props.getHRData}
              />
            ))
          : null}
      </Div>
    );
  }
}

const Div = styled.div`
  padding: 20px 50px 20px 20px;
`;

const WorkoutTitle = styled(AppTitle)`
  opacity: 0.5;
  font-size: 14px;
  font-weight: 500;
  color: #1f2125;
`;

interface LinkDispatchProps {
  setWorkoutFilter: (workout_id: string) => void;
}

const mapDispatchToProps = (
  dispatch: Dispatch<AppActions>
): LinkDispatchProps => ({
  setWorkoutFilter: bindActionCreators(setWorkoutFilter, dispatch)
});

interface LinkStateProps {
  workouts: Workout[];
}

const mapStateToProps = (state: AppState): LinkStateProps => ({
  // workouts: getSelectedWorkouts(state.workouts, state.filters.swimmerId, state.filters.date)
  // TODO: This is just a stub; replace with real logic
  workouts: workout.filter(
    workout => workout.member_id === state.filters.swimmerId
  )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WorkoutSelector);
