import * as React from "react";
import { connect } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";
import { AppActions } from "../../../types/actions";
import { User, Member, Team } from "../../../types";
import { AppState } from "../../../store/configureStore";
import { getSwimmers } from "../../../selectors/swimmers";
import { getTeam } from "../../../selectors/getTeam";
import { AppText } from "../../UIElements";
import { setSwimmerIdFilter } from "../../../actions/filter";
import styled from "styled-components";
import { create } from "apisauce";
import { SetSwimmerID } from "../../../actions/swimmerData";
import momentTimezone from "moment-timezone";
export interface SwimmerSelectorItemProps {
  swimmer_id: string;
  swimmer_name: string;
  external_swimmer_id: any;
  onSelectSwimmer: any;
  month: any;
  year: any;
  onMonthChange: any;
  fetchCurrentWorkout: any;
  getHRData: any;
  fetchWorkoutOnDateClick: any;
  currentSwimmerID: any;
}
export interface SwimmerSelectorItemState {
  selectedSwimmer: any;
  workoutDatesAPI: any;
}

const api = create({
  baseURL: "https://ap1.phlex.us/scripts",
  headers: {
    // "Access-Control-Allow-Origin": "*",
    // Authorization: "Bearer {token}",
    "Content-Type": "application/x-www-form-urlencoded",
    Accept: "application/json"
  }
});
type Props = SwimmerSelectorItemProps & LinkStateProps & LinkDispatchProps;

class SwimmerSelectorItem extends React.Component<
  Props,
  SwimmerSelectorItemState
> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedSwimmer: null,
      workoutDatesAPI: []
    };
  }
  componentDidMount = () => {
    if (this.props.currentSwimmerID === this.props.swimmer_id) {
      this.onClick();
    }
  };

  getWorkOutData = async () => {
    const formData = new FormData();
    formData.append("requesting_user_id", this.props.external_swimmer_id);
    formData.append("user_token", "super_token");
    const res = await api.post(`/fetch-last-workout.php`, formData);
    console.log("fetch latest workout", res.data);
    this.props.onSelectSwimmer(res.data);
    this.props.fetchCurrentWorkout(res.data);
    this.props.getHRData(res.data);
  };

  onClick = async () => {
    const currentTime = momentTimezone.tz.guess();

    this.props.setSwimmerFilter(this.props.swimmer_id);
    const properMonth = this.props.month + 1;
    const formData = new FormData();
    formData.append("requesting_user_id", `${this.props.external_swimmer_id}`);
    formData.append("month", `${properMonth}`);
    formData.append("year", `${this.props.year}`);
    formData.append("user_token", "super_token");
    formData.append("timezone", currentTime);

    const res = await api.post("/fetch-workout-dates.php", formData);
    this.setState({
      workoutDatesAPI: res.data
    });
    this.props.setSwimmerExtID(this.props.external_swimmer_id);
    this.props.onMonthChange(this.state.workoutDatesAPI);
    this.getWorkOutData();
    this.props.fetchWorkoutOnDateClick({});
  };
  render() {
    const { swimmer_name, swimmer_id, isSelected } = this.props;
    return (
      <Div
        onClick={this.onClick}
        style={{ backgroundColor: `${isSelected ? "#00d1e1" : "#fff"}` }}
      >
        <SwimmerText color={isSelected ? "#fff" : "#000"} key={swimmer_id}>
          {swimmer_name}
        </SwimmerText>
      </Div>
    );
  }
}

const Div = styled.div`
  height: 60px;
  padding-left: 36px;
  display: flex;
  align-items: center;
  :hover {
    cursor: pointer;
  }
`;

const SwimmerText = styled(AppText)`
  font-size: 16px;
  letter-spacing: 0.2px;
  color: #1f2125;
`;

interface LinkDispatchProps {
  setSwimmerFilter: (swimmerId: string) => void;
  setSwimmerExtID: (external_swimmer_id: any) => void;
}

const mapDispatchToProps = (
  dispatch: Dispatch<AppActions>
): LinkDispatchProps => ({
  setSwimmerFilter: bindActionCreators(setSwimmerIdFilter, dispatch),
  setSwimmerExtID: bindActionCreators(SetSwimmerID, dispatch)
});

interface LinkStateProps {
  isSelected: boolean;
}

const mapStateToProps = (
  state: AppState,
  ownProps: SwimmerSelectorItemProps
): LinkStateProps => ({
  isSelected: state.filters.swimmerId === ownProps.swimmer_id
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SwimmerSelectorItem);
