import { TeamMember, Member } from "../types";

// * Returns members of a team based on a team id
// ----------------------------------------------
// Not the most efficient algorithm at the moment
export const getTeam = (
  team_id: string,
  teamMembers: TeamMember[],
  members: Member[]
): Member[] => {
  return teamMembers
    .filter(teamMember => teamMember.team_id === team_id)
    .map(teamMember => {
      // return member info bas on teamMember.member_id
      return members.filter(
        member => member.member_id === teamMember.member_id
      )[0];
    });
};

// grab team id
// filter memberTeam, by teamID
// Look up each member by member ID
// add member to array
// return array of members
