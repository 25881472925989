import * as React from "react";
import { connect } from "react-redux";
import * as Yup from "yup";
import {
  Modal,
  ModalTitle,
  FormSegment,
  Input,
  ErrorText,
  TableTitle,
  Spinner,
  Button
} from "../../UIElements";
import {
  Formik,
  FormikProps,
  Form,
  Field,
  FieldProps,
  ErrorMessage
} from "formik";
import { setError } from "../../../firebase/error";
import AuthError from "../AuthError";
import styled from "styled-components";
import { addMemberToTeam } from "../../../actions/teamMember";
import { TeamMember, Team } from "../../../types";
import { Dispatch, bindActionCreators } from "redux";
import { AppActions } from "../../../types/actions";
import { editTeam } from "../../../actions/team";
import { editTeamThunk } from "../../../api/teams";

const schema = Yup.object().shape({
  name: Yup.string().required("* Team name is required")
});

interface MyFormikProps {
  name: string;
}

interface ChangeNameModalProps {
  showModal: boolean;
  toggleModal: () => void;
  team: any;
}

type Props = ChangeNameModalProps & LinkDispatchProps;

class ChangeNameModal extends React.Component<Props, any> {
  render() {
    return (
      <Modal
        showModal={this.props.showModal}
        toggleModal={this.props.toggleModal}
        label="Team name"
      >
        <Formik
          validateOnChange={true}
          validateOnBlur={false}
          initialValues={{
            name: ""
          }}
          validationSchema={schema}
          onSubmit={async (values, { setFieldError, setStatus }) => {
            const { name } = values;
            setStatus({ error: "", loading: true });
            try {
              console.log("CHANGENAME NAME", values);
              this.props.editTeam(values.name, this.props.team);
            } catch (e) {
              console.log("login error: ", e);
              setStatus({ loading: false });
              return setError(e.code, setFieldError, setStatus);
            }
            console.log("no error");
            setStatus({ loading: false });
            // TODO: Replace this with the real token once the backend is hooked up.
            // this.props.editTeam({ ...this.props.team, team_name: name });
            this.props.toggleModal();
          }}
        >
          {({
            status,
            touched,
            errors,
            setFieldTouched
          }: FormikProps<MyFormikProps>) => {
            console.log("RENAME GROUP", this.props);
            return (
              <Form>
                <ModalGrid>
                  <ModalTitle>Rename Group</ModalTitle>
                  <Field
                    name="name"
                    render={({
                      field,
                      form: { errors, touched }
                    }: FieldProps<MyFormikProps>) => {
                      // field.onChange(() => setStatus({ canChange: true }));
                      const error = !!errors.name && !!touched.name;
                      return (
                        <FormSegment>
                          <Input
                            {...field}
                            error={error}
                            inputWidth="407px"
                            placeholder="Team name"
                            onInput={() => setFieldTouched("name", true)}
                          />
                          <ErrorMessage
                            name={field.name}
                            component={ErrorText}
                          />
                        </FormSegment>
                      );
                    }}
                  />
                  <End>
                    <TableTitle clickable onClick={this.props.toggleModal}>
                      Cancel
                    </TableTitle>
                    <div style={{ gridRow: "3 / span 1", marginLeft: "32px" }}>
                      {status && status.loading ? (
                        <Spinner />
                      ) : (
                        <Button
                          width="152px"
                          disabled={
                            !(
                              Object.keys(touched).length &&
                              !Object.keys(errors).length
                            )
                          }
                          type="submit"
                        >
                          Update
                        </Button>
                      )}
                    </div>
                  </End>
                  <div style={{ gridRow: "4 / span 1" }}>
                    <AuthError status={status} />
                  </div>
                </ModalGrid>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

const End = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

const ModalGrid = styled.div`
  display: grid;
  grid-template-rows: 60px 70px 40px min-content;
  padding: 32px 46px 24px 46px;
`;

interface LinkDispatchProps {
  editTeam: (name: string, id: number) => void;
}

const mapDispatchToProps = (
  dispatch: Dispatch<AppActions>
): LinkDispatchProps => ({
  editTeam: bindActionCreators(editTeamThunk, dispatch)
});

export default connect(
  null,
  mapDispatchToProps
)(ChangeNameModal);
