import { Member } from "../types";

export const fakeMembers: Member[] = [
  {
    member_id: "1",
    member_email: "test1@test.com",
    member_first_name: "Steve",
    member_last_name: "Ekins",
    member_group_id: "1",
    member_type: "Coach"
  },
  {
    member_id: "2",
    member_email: "test2@test.com",
    member_first_name: "Henry",
    member_last_name: "Smith",
    member_group_id: "1",
    member_type: "Coach"
  },
  {
    member_id: "3",
    member_email: "test3@test.com",
    member_first_name: "Jacob",
    member_last_name: "Russell",
    member_group_id: "1",
    member_type: "Coach"
  },
  {
    member_id: "4",
    member_email: "test4@test.com",
    member_first_name: "Sarah",
    member_last_name: "Cahill",
    member_group_id: "1",
    member_type: "Swimmer"
  },
  {
    member_id: "5",
    member_email: "test5@test.com",
    member_first_name: "Bob",
    member_last_name: "Zhu",
    member_group_id: "1",
    member_type: "Swimmer"
  },
  {
    member_id: "6",
    member_email: "test6@test.com",
    member_first_name: "Hannah",
    member_last_name: "O'dell",
    member_group_id: "2",
    member_type: "Coach"
  },
  {
    member_id: "7",
    member_email: "test7@test.com",
    member_first_name: "Tim",
    member_last_name: "Dell",
    member_group_id: "2",
    member_type: "Swimmer"
  },
  {
    member_id: "8",
    member_email: "test8@test.com",
    member_first_name: "Antwan",
    member_last_name: "Cudjoe",
    member_group_id: "2",
    member_type: "Swimmer"
  },
  {
    member_id: "9",
    member_email: "test9@test.com",
    member_first_name: "Tucker",
    member_last_name: "Brown",
    member_group_id: "2",
    member_type: "Swimmer"
  }
];
