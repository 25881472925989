import { DoughnutProps } from "../Components/Segments/Graphs/Doughnut";

export const dummyDistance = [
  { x: "KICKING", y: 1500 },
  { x: "FREESTYLE", y: 1600 },
  { x: "BUTTERFLY", y: 300 },
  { x: "BACKSTROKE", y: 700 },
  { x: "OTHER", y: 500 }
];

export const colorDistance = [
  "#fa4374",
  "#00d1e1",
  "#f5a623",
  "#09397f",
  "#cccccc"
];

// QUESTION: What are the zone names?

export const dummyHRZone = [
  { x: "LOW", y: 1500 },
  { x: "MEDIUM", y: 1600 },
  { x: "HIGH", y: 300 },
  { x: "INTENSE", y: 700 }
];

export const colorHRZone = [
  "#fa4374",
  "#00d1e1",
  "#f5a623",
  "#09397f",
  "#cccccc"
];
