import styled from "styled-components";
import { PRIMARY_COLOR } from "../../utils/variables";

interface Props {
  disabled?: boolean;
  width?: string;
  height?: string;
  backgroundColor?: string;
}

const Button = styled.button<Props>`
  width: ${props => props.width};
  height: ${props => props.height};
  border-radius: 1px;
  color: #fff;
  opacity: ${props => (props.disabled ? 1 : 1)};
  background-color: ${props =>
    props.disabled ? "#7f7f7f" : props.backgroundColor};
  font-weight: 500;
  font-family: ${props => props.theme.PRIMARY_FONT};
  font-size: 14px;
  letter-spacing: 0.2px;
  text-align: center;
  border-width: 0;
  padding: 0;
  :focus {
    outline: none;
  }
  :hover {
    cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
  }
`;

Button.defaultProps = {
  disabled: false,
  width: "255px",
  height: "50px",
  backgroundColor: PRIMARY_COLOR
};

export { Button };
