import { TeamActionTypes } from "../types/actions";
import { Team } from "../types";

const teamReducerDefaultState: Team[] = [];

// This reducer holds user information that is returned from login/signup
// post requests

const teamReducer = (
  state = teamReducerDefaultState,
  action: TeamActionTypes
): Team[] => {
  switch (action.type) {
    case "FETCH_TEAMS":
      return action.teams;
    case "ADD_TEAM":
      return [...state, action.team];
    case "REMOVE_TEAM":
      return state.filter((Team: Team) => Team.team_id != action.team_id);
    case "EDIT_TEAM":
      console.log("EDIT_TEAM STATE", state);
      return state.map((team: Team) => {
        if (team.id === action.updates.id) {
          return {
            id: action.updates.id,
            name: action.updates.name,
            organization_id: action.updates.organization_id,
            teammates: team.teammates
          };
        }
        return team;
      });
    case "LOGOUT":
      return teamReducerDefaultState;
    default:
      return state;
  }
};

export default teamReducer;
