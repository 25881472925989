import {
  FETCH_TEAMMATES,
  ADD_COACH_TO_TEAM,
  REMOVE_COACH_FROM_TEAM,
  ADD_SWIMMER_TO_TEAM,
  REMOVE_SWIMMER_FROM_TEAM
} from "../types/actions";
import { TeamMates, Team } from "../types";

export const fetchTeammates = (teammates: TeamMates) => ({
  type: FETCH_TEAMMATES,
  teammates
});

export const addCoachToTeam = (teammates: TeamMates) => ({
  type: ADD_COACH_TO_TEAM,
  teammates
});

export const removeCoachFromTeam = (teammates: TeamMates) => ({
  type: REMOVE_COACH_FROM_TEAM,
  teammates
});
export const addSwimmerToTeam = (teammates: TeamMates) => ({
  type: ADD_SWIMMER_TO_TEAM,
  teammates
});

export const removeSwimmerFromTeam = (teammates: TeamMates) => ({
  type: REMOVE_SWIMMER_FROM_TEAM,
  teammates
});
