import { Workout } from "../types";

export const workout: Workout[] = [
  {
    workout_id: "1",
    member_id: "1",
    distance: 3400,
    time: "2hrs 43 mins",
    performance: 4,
    hr_avg: 150,
    hr_max: 190,
    workout_datetime_epoch: 1554060755,
    set: [
      {
        workout_id: "1",
        set_id: "1",
        component: [
          {
            component_id: "1",
            set_id: "1",
            name: "400 free",
            time: "2:35.5",
            DPS: "1.95m",
            Zone: "Fat Burn",
            avg_hr: "135 bpm"
          },
          {
            component_id: "2",
            set_id: "1",
            name: "5 x 200 free @3:00 and a little longer",
            time: "2:35.5",
            DPS: "1.95m",
            Zone: "Aerobic",
            avg_hr: "165 bpm"
          }
        ]
      },
      {
        workout_id: "1",
        set_id: "2",
        component: [
          {
            component_id: "3",
            set_id: "2",
            name: "400 free",
            time: "2:35.6",
            DPS: "1.95m",
            Zone: "Fat Burn",
            avg_hr: "135 bpm"
          },
          {
            component_id: "4",
            set_id: "2",
            name: "5 x 200 free @3:00 and a little longer",
            time: "2:35.5",
            DPS: "1.95m",
            Zone: "Aerobic",
            avg_hr: "165 bpm"
          },
          {
            component_id: "5",
            set_id: "2",
            name: "200 back",
            time: "2:33.6",
            DPS: "1.15m",
            Zone: "Fat Burn",
            avg_hr: "115 bpm"
          },
          {
            component_id: "6",
            set_id: "2",
            name: "5 x 200 free @3:00 and a little longer",
            time: "2:35.5",
            DPS: "1.95m",
            Zone: "Aerobic",
            avg_hr: "165 bpm"
          },
          {
            component_id: "7",
            set_id: "2",
            name: "400 free",
            time: "2:35.6",
            DPS: "1.95m",
            Zone: "Fat Burn",
            avg_hr: "135 bpm"
          },
          {
            component_id: "8",
            set_id: "2",
            name: "5 x 200 free @3:00 and a little longer",
            time: "2:35.5",
            DPS: "1.95m",
            Zone: "Aerobic",
            avg_hr: "165 bpm"
          }
        ]
      }
    ]
  },
  {
    workout_id: "2",
    member_id: "1",
    distance: 3000,
    time: "2hrs 13 mins",
    performance: 5,
    hr_avg: 130,
    hr_max: 160,
    workout_datetime_epoch: 1553801555,
    set: [
      {
        workout_id: "2",
        set_id: "3",
        component: [
          {
            component_id: "9",
            set_id: "1",
            name: "400 free",
            time: "2:35.5",
            DPS: "1.95m",
            Zone: "Fat Burn",
            avg_hr: "135 bpm"
          }
        ]
      },
      {
        workout_id: "2",
        set_id: "4",
        component: [
          {
            component_id: "10",
            set_id: "2",
            name: "400 free",
            time: "2:35.6",
            DPS: "1.95m",
            Zone: "Fat Burn",
            avg_hr: "135 bpm"
          }
        ]
      }
    ]
  },
  {
    workout_id: "3",
    member_id: "2",
    distance: 3000,
    time: "2hrs 13 mins",
    performance: 5,
    hr_avg: 130,
    hr_max: 160,
    workout_datetime_epoch: 1553801555,
    set: [
      {
        workout_id: "3",
        set_id: "5",
        component: [
          {
            component_id: "10",
            set_id: "5",
            name: "400 free",
            time: "2:35.5",
            DPS: "1.95m",
            Zone: "Fat Burn",
            avg_hr: "135 bpm"
          }
        ]
      },
      {
        workout_id: "4",
        set_id: "6",
        component: [
          {
            component_id: "11",
            set_id: "6",
            name: "400 free",
            time: "2:35.6",
            DPS: "1.95m",
            Zone: "Fat Burn",
            avg_hr: "135 bpm"
          }
        ]
      }
    ]
  },
  {
    workout_id: "5",
    member_id: "3",
    distance: 3000,
    time: "2hrs 13 mins",
    performance: 5,
    hr_avg: 130,
    hr_max: 160,
    workout_datetime_epoch: 1553801555,
    set: [
      {
        workout_id: "5",
        set_id: "7",
        component: [
          {
            component_id: "12",
            set_id: "7",
            name: "400 free",
            time: "2:35.5",
            DPS: "1.95m",
            Zone: "Fat Burn",
            avg_hr: "135 bpm"
          }
        ]
      },
      {
        workout_id: "5",
        set_id: "8",
        component: [
          {
            component_id: "13",
            set_id: "8",
            name: "400 free",
            time: "2:35.6",
            DPS: "1.95m",
            Zone: "Fat Burn",
            avg_hr: "135 bpm"
          }
        ]
      }
    ]
  },
  {
    workout_id: "6",
    member_id: "4",
    distance: 3000,
    time: "2hrs 13 mins",
    performance: 5,
    hr_avg: 130,
    hr_max: 160,
    workout_datetime_epoch: 1553801555,
    set: [
      {
        workout_id: "6",
        set_id: "9",
        component: [
          {
            component_id: "14",
            set_id: "9",
            name: "400 free",
            time: "2:35.5",
            DPS: "1.95m",
            Zone: "Fat Burn",
            avg_hr: "135 bpm"
          }
        ]
      },
      {
        workout_id: "6",
        set_id: "10",
        component: [
          {
            component_id: "15",
            set_id: "10",
            name: "400 free",
            time: "2:35.6",
            DPS: "1.95m",
            Zone: "Fat Burn",
            avg_hr: "135 bpm"
          }
        ]
      }
    ]
  },
  {
    workout_id: "7",
    member_id: "5",
    distance: 3000,
    time: "2hrs 13 mins",
    performance: 5,
    hr_avg: 130,
    hr_max: 160,
    workout_datetime_epoch: 1553801555,
    set: [
      {
        workout_id: "7",
        set_id: "11",
        component: [
          {
            component_id: "16",
            set_id: "11",
            name: "400 free",
            time: "2:35.5",
            DPS: "1.95m",
            Zone: "Fat Burn",
            avg_hr: "135 bpm"
          }
        ]
      },
      {
        workout_id: "7",
        set_id: "12",
        component: [
          {
            component_id: "17",
            set_id: "12",
            name: "400 free",
            time: "2:35.6",
            DPS: "1.95m",
            Zone: "Fat Burn",
            avg_hr: "135 bpm"
          }
        ]
      }
    ]
  },
  {
    workout_id: "8",
    member_id: "6",
    distance: 3000,
    time: "2hrs 13 mins",
    performance: 5,
    hr_avg: 130,
    hr_max: 160,
    workout_datetime_epoch: 1553801555,
    set: [
      {
        workout_id: "8",
        set_id: "13",
        component: [
          {
            component_id: "18",
            set_id: "13",
            name: "400 free",
            time: "2:35.5",
            DPS: "1.95m",
            Zone: "Fat Burn",
            avg_hr: "135 bpm"
          }
        ]
      },
      {
        workout_id: "8",
        set_id: "14",
        component: [
          {
            component_id: "19",
            set_id: "14",
            name: "400 free",
            time: "2:35.6",
            DPS: "1.95m",
            Zone: "Fat Burn",
            avg_hr: "135 bpm"
          }
        ]
      }
    ]
  },
  {
    workout_id: "9",
    member_id: "7",
    distance: 3000,
    time: "2hrs 13 mins",
    performance: 5,
    hr_avg: 130,
    hr_max: 160,
    workout_datetime_epoch: 1553801555,
    set: [
      {
        workout_id: "9",
        set_id: "14",
        component: [
          {
            component_id: "20",
            set_id: "14",
            name: "400 free",
            time: "2:35.5",
            DPS: "1.95m",
            Zone: "Fat Burn",
            avg_hr: "135 bpm"
          }
        ]
      },
      {
        workout_id: "9",
        set_id: "15",
        component: [
          {
            component_id: "21",
            set_id: "15",
            name: "400 free",
            time: "2:35.6",
            DPS: "1.95m",
            Zone: "Fat Burn",
            avg_hr: "135 bpm"
          }
        ]
      }
    ]
  },
  {
    workout_id: "10",
    member_id: "8",
    distance: 3000,
    time: "2hrs 13 mins",
    performance: 5,
    hr_avg: 130,
    hr_max: 160,
    workout_datetime_epoch: 1553801555,
    set: [
      {
        workout_id: "10",
        set_id: "16",
        component: [
          {
            component_id: "22",
            set_id: "16",
            name: "400 free",
            time: "2:35.5",
            DPS: "1.95m",
            Zone: "Fat Burn",
            avg_hr: "135 bpm"
          }
        ]
      },
      {
        workout_id: "15",
        set_id: "17",
        component: [
          {
            component_id: "23",
            set_id: "17",
            name: "400 free",
            time: "2:35.6",
            DPS: "1.95m",
            Zone: "Fat Burn",
            avg_hr: "135 bpm"
          }
        ]
      }
    ]
  },
  {
    workout_id: "10",
    member_id: "9",
    distance: 3000,
    time: "2hrs 13 mins",
    performance: 5,
    hr_avg: 130,
    hr_max: 160,
    workout_datetime_epoch: 1553801555,
    set: [
      {
        workout_id: "10",
        set_id: "18",
        component: [
          {
            component_id: "24",
            set_id: "18",
            name: "400 free",
            time: "2:35.5",
            DPS: "1.95m",
            Zone: "Fat Burn",
            avg_hr: "135 bpm"
          }
        ]
      },
      {
        workout_id: "8",
        set_id: "19",
        component: [
          {
            component_id: "25",
            set_id: "19",
            name: "400 free",
            time: "2:35.6",
            DPS: "1.95m",
            Zone: "Fat Burn",
            avg_hr: "135 bpm"
          }
        ]
      }
    ]
  }
];
