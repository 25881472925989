import { SET_SWIMMER_FILTER, SET_WORKOUT_FILTER } from "../types/actions";

// Filter data is not yet needed for MVP

export const setSwimmerIdFilter = (swimmerId: string) => ({
  type: SET_SWIMMER_FILTER,
  swimmerId
});

export const setDateFilter = (date: number) => ({
  type: SET_SWIMMER_FILTER,
  date
});

export const setWorkoutFilter = (workoutId: string) => ({
  type: SET_WORKOUT_FILTER,
  workoutId
});
