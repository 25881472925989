import { WorkoutActionTypes } from "../types/actions";
import { Workout } from "../types";

const workoutReducerDefaultState: Workout[] = [];

// This reducer holds user information that is returned from login/signup
// post requests

const workoutReducer = (
  state = workoutReducerDefaultState,
  action: WorkoutActionTypes
): Workout[] => {
  switch (action.type) {
    case "FETCH_WORKOUTS":
      return [...action.workouts];
    case "ADD_WORKOUT":
      return [...state, action.workout];
    case "REMOVE_WORKOUT":
      return state.filter(
        (Workout: Workout) => Workout.workout_id != action.workout_id
      );
    case "EDIT_WORKOUT":
      return state.map((workout: Workout) => {
        if (workout.workout_id === action.workout_id) {
          return {
            ...workout,
            ...action.updates
          };
        }
        return workout;
      });
    case "LOGOUT":
      return workoutReducerDefaultState;
    default:
      return state;
  }
};

export default workoutReducer;
