import {
  User,
  Workout,
  Member,
  Team,
  TeamMember,
  Organization,
  Coach,
  Email,
  Swimmer,
  TeamMates,
  SwimmerWorkoutData
} from "./index";
export const FETCH_USER = "FETCH_USER";

interface FetchUserAction {
  type: typeof FETCH_USER;
  user: User;
}

// interface EditUserAction {
//   type: typeof EDIT_USER;
//   user: User;
// }

export type UserActionTypes = FetchUserAction | EditUserAction | LogoutAction;

export const ADD_MEMBER = "ADD_MEMBER";
export const EDIT_MEMBER = "EDIT_MEMBER";
export const FETCH_MEMBERS = "FETCH_MEMBERS";
export const REMOVE_MEMBER = "REMOVE_MEMBER";
export const FETCH_ONE_MEMBER = "FETCH_ONE_MEMBER";

interface AddMemberAction {
  type: typeof ADD_MEMBER;
  member: Member;
}

interface EditMemberAction {
  type: typeof EDIT_MEMBER;
  member_id: string;
  updates: Member;
}

interface FetchMemberAction {
  type: typeof FETCH_MEMBERS;
  members: Member[];
}

interface RemoveMemberAction {
  type: typeof REMOVE_MEMBER;
  member_id: string;
}

interface FetchOneMemberAction {
  type: typeof FETCH_ONE_MEMBER;
  member: Member;
}

export type MemberActionTypes =
  | AddMemberAction
  | EditMemberAction
  | FetchMemberAction
  | RemoveMemberAction
  | FetchOneMemberAction
  | LogoutAction;

export const ADD_TEAM = "ADD_TEAM";
export const FETCH_TEAMS = "FETCH_TEAMS";
export const REMOVE_TEAM = "REMOVE_TEAM";

interface AddTeamAction {
  type: typeof ADD_TEAM;
  team: Team;
}

interface FetchTeamsAction {
  type: typeof FETCH_TEAMS;
  teams: Team[];
}

interface RemoveTeamAction {
  type: typeof REMOVE_TEAM;
  team_id: string;
}

export type TeamActionTypes =
  | AddTeamAction
  | FetchTeamsAction
  | RemoveTeamAction
  | FetchTeammatesAction
  | EditTeamAction
  | LogoutAction;

export const ADD_MEMBER_TO_TEAM = "ADD_MEMBER_TO_TEAM";
export const REMOVE_MEMBER_FROM_TEAM = "REMOVE_MEMBER_FROM_TEAM";
export const FETCH_MEMBER_TEAMS = "FETCH_MEMBER_TEAMS";
export const ADD_COACH_TO_TEAM = "ADD_COACH_TO_TEAM";
export const ADD_SWIMMER_TO_TEAM = "ADD_SWIMMER_TO_TEAM";
export const REMOVE_COACH_FROM_TEAM = "REMOVE_COACH_FROM_TEAM";
export const REMOVE_SWIMMER_FROM_TEAM = "REMOVE_SWIMMER_FROM_TEAM";

interface AddMemberToTeamAction {
  type: typeof ADD_MEMBER_TO_TEAM;
  memberTeam: TeamMember;
}

interface RemoveMemberFromTeamAction {
  type: typeof REMOVE_MEMBER_FROM_TEAM;
  memberTeam: TeamMember;
}

interface FetchMemberTeamsAction {
  type: typeof FETCH_MEMBER_TEAMS;
  memberTeams: TeamMember[];
}

export type TeamMemberActionTypes =
  | AddMemberToTeamAction
  | FetchMemberTeamsAction
  | RemoveMemberFromTeamAction
  | AddSwimmerToTeam
  // | AddCoachToTeam
  | RemoveSwimmerFromTeam
  | RemoveCoachFromTeam
  | LogoutAction;

export const ADD_WORKOUT = "ADD_WORKOUT";
export const EDIT_WORKOUT = "EDIT_WORKOUT";
export const FETCH_WORKOUTS = "FETCH_WORKOUTS";
export const REMOVE_WORKOUT = "REMOVE_WORKOUT";

interface AddWorkoutAction {
  type: typeof ADD_WORKOUT;
  workout: Workout;
}

interface EditWorkoutAction {
  type: typeof EDIT_WORKOUT;
  workout_id: string;
  updates: Workout;
}

interface FetchWorkoutsAction {
  type: typeof FETCH_WORKOUTS;
  workouts: Workout[];
}

interface RemoveWorkoutAction {
  type: typeof REMOVE_WORKOUT;
  workout_id: string;
}

export type WorkoutActionTypes =
  | AddWorkoutAction
  | EditWorkoutAction
  | FetchWorkoutsAction
  | RemoveWorkoutAction
  | LogoutAction;

export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const ERROR = "ERROR";
export const SIGNUP_ERROR = "SIGNUP_ERROR";
export const SEND_FORGOT_PASSWORD_EMAIL = "SEND_FORGOT_PASSWORD_EMAIL";
export const GET_USER = "GET_USER";
export const EDIT_USER = "EDIT_USER";

interface LoginAction {
  type: typeof LOGIN;
  access_token: string;
  token_type: string;
  // role: "Head Coach" | "Coach" | "Swimmer" | "";
}

interface LogoutAction {
  type: typeof LOGOUT;
}

interface ErrorAction {
  type: typeof ERROR;
  error: string;
}

interface SignupErrorAction {
  type: typeof SIGNUP_ERROR;
  error: string;
}

interface SendForgotEmailAction {
  type: typeof SEND_FORGOT_PASSWORD_EMAIL;
  email: Email;
}

interface GetMeAction {
  type: typeof GET_USER;
  user: object;
}

interface EditUserAction {
  type: typeof EDIT_USER;
  user: any;
}

export type AuthActionTypes =
  | LoginAction
  | LogoutAction
  | ErrorAction
  | GetMeAction
  | EditUserAction
  | SignupErrorAction
  | SendForgotEmailAction;

export const SET_DATE_FILTER = "SET_DATE_FILTER";
export const SET_SWIMMER_FILTER = "SET_SWIMMER_FILTER";
export const SET_WORKOUT_FILTER = "SET_WORKOUT_FILTER";

interface SetDateFilterAction {
  type: typeof SET_DATE_FILTER;
  date: number;
}

interface SetSwimmerFilterAction {
  type: typeof SET_SWIMMER_FILTER;
  swimmerId: string;
}

interface SetWorkoutFilterAction {
  type: typeof SET_WORKOUT_FILTER;
  workoutId: string;
}

export type FilterActionTypes =
  | SetDateFilterAction
  | SetSwimmerFilterAction
  | SetWorkoutFilterAction
  | LogoutAction;

export type AppActions =
  | AuthActionTypes
  | WorkoutActionTypes
  | UserActionTypes
  | MemberActionTypes
  | FilterActionTypes;

// 6/4 code

export const FETCH_ORGANIZATIONS = "FETCH_ORGANIZATIONS";
export const EDIT_ORGANIZATION = "EDIT_ORGANIZATION";
export const ADD_ORGANIZATION = "ADD_ORGANIZATION";
export const FETCH_ORGANIZATION_USER = "FETCH_ORGANIZATION_USER";

interface FetchOrganizationsAction {
  type: typeof FETCH_ORGANIZATIONS;
  // organizations: Organization[];
  organizations: Organization;
}

interface EditOrganizationAction {
  type: typeof EDIT_ORGANIZATION;
  // organization_id: string;
  organization: Organization;
}

interface AddOrganizationAction {
  type: typeof ADD_ORGANIZATION;
  organization: Organization;
}

interface FetchOrganizationUserAction {
  type: typeof FETCH_ORGANIZATION_USER;
  organization: Organization;
}
export type OrganizationActionTypes =
  | FetchOrganizationsAction
  | EditOrganizationAction
  | AddOrganizationAction
  | FetchOrganizationUserAction
  | LogoutAction;

export const FETCH_COACHES = "FETCH_COACHES";
export const INVITE_COACH = "INVITE_COACH";

interface FetchCoachesAction {
  type: typeof FETCH_COACHES;
  coaches: any;
}

interface InviteCoachAction {
  type: typeof INVITE_COACH;
  coach: Coach;
}

export type CoachActionTypes =
  | FetchCoachesAction
  | InviteCoachAction
  | LogoutAction;

// 6/8

export const FETCH_SWIMMERS = "FETCH_SWIMMERS";
export const DELETE_SWIMMER = "DELETE_SWIMMER";
export const INVITE_SWIMMER = "INVITE_SWIMMER";
export const ACCEPT_SWIMMER = "ACCEPT_SWIMMER";

interface FetchSwimmerAction {
  type: typeof FETCH_SWIMMERS;
  swimmers: any;
}

interface DeleteSwimmerAction {
  type: typeof DELETE_SWIMMER;
  swimmer: Swimmer;
}

interface InviteSwimmerAction {
  type: typeof INVITE_SWIMMER;
  swimmer: any;
}

interface AcceptSwimmerAction {
  type: typeof ACCEPT_SWIMMER;
  swimmer: any;
}

export type SwimmerActionTypes =
  | FetchSwimmerAction
  | DeleteSwimmerAction
  | InviteSwimmerAction
  | AcceptSwimmerAction
  | LogoutAction;

export const FETCH_TEAMMATES = "FETCH_TEAMMATES";
export const EDIT_TEAM = "EDIT_TEAM";

interface FetchTeammatesAction {
  type: typeof FETCH_TEAMMATES;
  teammates: any;
}
interface AddSwimmerToTeam {
  type: typeof ADD_SWIMMER_TO_TEAM;
  teammates: any;
}

interface AddCoachToTeam {
  type: typeof ADD_COACH_TO_TEAM;
  teammates: any;
}

interface RemoveSwimmerFromTeam {
  type: typeof REMOVE_SWIMMER_FROM_TEAM;
  teammates: any;
}

interface RemoveCoachFromTeam {
  type: typeof REMOVE_COACH_FROM_TEAM;
  teammates: any;
}

interface EditTeamAction {
  type: typeof EDIT_TEAM;
  team_id: string;
  updates: Team;
  teammates: any;
  id?: any;
  name?: any;
  organization_id?: any;
}
export type TeammatesActionTypes =
  | FetchTeammatesAction
  | AddSwimmerToTeam
  | AddCoachToTeam
  | RemoveSwimmerFromTeam
  | RemoveCoachFromTeam
  | EditTeamAction
  | LogoutAction;

export const SET_SWIMMER_ID = "SET_SWIMMER_ID";

interface SetSwimmerIdAction {
  type: typeof SET_SWIMMER_ID;
  external_swimmer_id: number;
}

export type SwimmerDataActionTypes = SetSwimmerIdAction | LogoutAction;
