import styled from "styled-components";

interface Props {
  error?: boolean;
}

// TODO: Invert colors

export const Checkbox = styled.input.attrs({ type: "checkbox" })<Props>`
  width: 22px;
  height: 22px;
  border-radius: 4px;
  border: solid 1px #dfe0eb;
  background-color: rgba(255, 255, 255, 0);
  outline: ${props => (props.error ? "none" : "")};
  border-color: ${props => (props.error ? "red" : "")};
  box-shadow: ${props => (props.error ? "0 0 5px red" : "")};
  :checked {
    background-color: black;
  }
`;

// * Reference: https://medium.com/@colebemis/building-a-checkbox-component-with-react-and-styled-components-8d3aa1d826dd
