import {
  FETCH_SWIMMERS,
  INVITE_SWIMMER,
  DELETE_SWIMMER,
  ACCEPT_SWIMMER
} from "../types/actions";
import { Swimmer } from "../types";

export const fetchSwimmers = (swimmers: Swimmer[]) => ({
  type: FETCH_SWIMMERS,
  swimmers
});

export const inviteSwimmer = (swimmer: any) => ({
  type: INVITE_SWIMMER,
  swimmer
});

export const deleteSwimmer = (swimmer: any) => ({
  type: DELETE_SWIMMER,
  swimmer
});

export const acceptSwimmer = (swimmer: any) => ({
  type: ACCEPT_SWIMMER,
  swimmer
});
