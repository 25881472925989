import { FETCH_COACHES, INVITE_COACH } from "../types/actions";
import { Coach } from "../types";

export const fetchCoaches = (coaches: Coach[]) => ({
  type: FETCH_COACHES,
  coaches
});

export const inviteCoach = (coach: Coach) => ({
  type: INVITE_COACH,
  coach
});
