import * as React from "react";
import { connect } from "react-redux";
import { AppState } from "../../store/configureStore";
import { Team, Organization } from "../../types";
import { Dispatch, bindActionCreators } from "redux";
import { AppActions } from "../../types/actions";
import { addTeam } from "../../actions/team";
import {
  Button,
  Modal,
  ModalTitle,
  FormSegment,
  Input,
  ErrorText,
  TableTitle,
  Spinner
} from "../UIElements";
import styled from "styled-components";
import * as Yup from "yup";
import GroupCard from "./GroupCard";
import {
  Formik,
  FormikProps,
  Form,
  Field,
  FieldProps,
  ErrorMessage
} from "formik";
import { setError } from "../../firebase/error";
import AuthError from "./AuthError";
import { addOrganizationThunk } from "../../api/organization";
import {
  fetchTeamsThunk,
  addTeamThunk,
  removeTeamThunk,
  editTeamThunk,
  fetchTeammatesThunk
} from "../../api/teams";

const schema = Yup.object().shape({
  group: Yup.string()
    .typeError("* Invalid group name")
    .max(100, "* Group name cannot be more than 100 characters")
    .required("* Group name is required")
});

// QUESTION: How do we want to handle responsiveness when expanded?

interface GroupsProps {}
interface GroupsState {
  showModal: boolean;
}

interface MyFormikProps {
  group: any;
}

type Props = GroupsProps & LinkStateProps & LinkDispatchProps;

class Groups extends React.Component<Props, GroupsState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      showModal: false
    };
  }

  toggleModal = () => this.setState(prev => ({ showModal: !prev.showModal }));

  componentDidMount = () => {
    // this.props.fetchAllTeamMembers(1);
  };
  render() {
    const { teams } = this.props;
    console.log("MAIN GROUP PROPS", this.props);

    return this.props.teams ? (
      <Div>
        <Header>
          <AddGroupButton onClick={this.toggleModal}>Add Group</AddGroupButton>
        </Header>
        <Body>
          {teams.map((team: Team) => (
            <GroupCard
              key={team.id}
              team={team}
              name={team.name}
              team_id={team.id}
            />
          ))}
        </Body>
        <Modal
          showModal={this.state.showModal}
          toggleModal={this.toggleModal}
          label="Add Group"
        >
          <Formik
            validateOnChange={true}
            validateOnBlur={false}
            initialValues={{
              group: ""
            }}
            validationSchema={schema}
            onSubmit={async (values, { setFieldError, setStatus }) => {
              const { group } = values;
              console.log("group submit", values);
              setStatus({ error: "", loading: true });
              const { addTeams /*,organization_id*/ } = this.props;
              try {
                this.props.addTeam(group);
              } catch (e) {
                console.log("login error: ", e);
                setStatus({ loading: false });
                return setError(e.code, setFieldError, setStatus);
              }
              console.log("no error");
              setStatus({ loading: false });

              this.toggleModal();
            }}
          >
            {({
              status,
              touched,
              errors,
              setFieldTouched
            }: FormikProps<MyFormikProps>) => {
              return (
                <Form>
                  <ModalGrid>
                    <ModalTitle>Add Group</ModalTitle>
                    <Field
                      name="group"
                      render={({
                        field,
                        form: { errors, touched }
                      }: FieldProps<MyFormikProps>) => {
                        // field.onChange(() => setStatus({ canChange: true }));
                        const error = !!errors.group && !!touched.group;
                        return (
                          <FormSegment>
                            <Input
                              {...field}
                              error={error}
                              inputWidth="407px"
                              // TODO: Need to allow multiple emails separated by commas
                              placeholder="Name the group"
                              onInput={() => setFieldTouched("group", true)}
                            />
                            <ErrorMessage
                              name={field.name}
                              component={ErrorText}
                            />
                          </FormSegment>
                        );
                      }}
                    />
                    <End>
                      <TableTitle clickable onClick={this.toggleModal}>
                        Cancel
                      </TableTitle>
                      <div
                        style={{ gridRow: "3 / span 1", marginLeft: "32px" }}
                      >
                        {status && status.loading ? (
                          <Spinner />
                        ) : (
                          <Button
                            width="152px"
                            disabled={
                              !(
                                Object.keys(touched).length &&
                                !Object.keys(errors).length
                              )
                            }
                            type="submit"
                          >
                            Add
                          </Button>
                        )}
                      </div>
                    </End>
                    <div style={{ gridRow: "4 / span 1" }}>
                      <AuthError status={status} />
                    </div>
                  </ModalGrid>
                </Form>
              );
            }}
          </Formik>
        </Modal>
      </Div>
    ) : (
      <Spinner />
    );
  }
}

const Body = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const ModalGrid = styled.div`
  display: grid;
  grid-template-rows: 60px 70px 40px min-content;
  padding: 32px 46px 24px 46px;
`;

const End = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

const Div = styled.div`
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 24px;
  margin-top: 13px;
`;

const AddGroupButton = styled(Button)`
  align-self: right;
  width: 152px;
`;

interface LinkDispatchProps {
  addTeam: (team: any) => void;
  addOrganization: (organization: any) => void;
  fetchTeam: () => void;
  editTeam: (name: string, team_id: number) => void;
  addTeams: (name: any) => void;
  fetchTeammates: (team_id: number) => void;
  fetchAllTeamMembers: (team_id: number) => void;
}

interface LinkStateProps {
  teams: Team[];
  test: any;
}

const mapDispatchToProps = (
  dispatch: Dispatch<AppActions>
): LinkDispatchProps => ({
  addTeam: bindActionCreators(addTeamThunk, dispatch),
  addOrganization: bindActionCreators(addOrganizationThunk, dispatch),
  fetchTeam: bindActionCreators(fetchTeamsThunk, dispatch),
  editTeam: bindActionCreators(editTeamThunk, dispatch),
  addTeams: bindActionCreators(addTeamThunk, dispatch),
  fetchTeammates: bindActionCreators(fetchTeammatesThunk, dispatch),
  fetchAllTeamMembers: bindActionCreators(fetchTeammatesThunk, dispatch)
});

// isAuthenticated is true if user_token exists in redux
const mapStateToProps = (state: AppState): LinkStateProps => ({
  teams: state.teams,
  test: state.teamMates
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Groups);
